const paginationHeaders = {
  currentPage: "X-Current-Page",
  nextPage: "X-Next-Page",
  pageSize: "X-Page-Size",
  totalEntries: "X-Total-Entries",
  totalPages: "X-Total-Pages",
};

const getPaginationInfo = (meta) => {
  const paginationObj = {};
  const paginationKeys = Object.keys(paginationHeaders);
  paginationKeys?.map((paginationKey) => {
    const headerValue = meta.response.headers.get(
      paginationHeaders[paginationKey]
    );
    paginationObj[paginationKey] = parseInt(headerValue)
      ? parseInt(headerValue)
      : 0;
    return false;
  });
  return paginationObj;
};

export default getPaginationInfo;
