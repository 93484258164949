import useLang from "../../hooks/useLang";
import { FormModalControlled } from "../../components/Modal";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import useCheckoutDialog from "./hooks/useCheckoutDialog";
import Form from "../../components/Form";
import { BundleGroup } from "../../components/Group";
import Input from "../../components/Input";
import Paragraph from "../../components/Paragraph";
import Icon from "../../components/Icon";
import ErrorMessage from "../../components/ErrorMessage";

const CheckOutDialogControlled = ({
  isOpen,
  onSubmit,
  onCancel,
  onClose,
  title,
  submitLabel,
  timeInput,
  today,
  startDate,
  startedToday,
}) => {
  const lang = useLang();

  return (
    <>
      <AccessWrapper action={userActions.CHECK_OUT}>
        <FormModalControlled
          isOpen={isOpen}
          title={title || lang("finish_task")}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onClose={onClose}
          submitLabel={submitLabel || lang("finish")}
        >
          <Form modal>
            <BundleGroup>
              <BundleGroup>
                <Input
                  label={lang("time")}
                  type="time"
                  {...timeInput.attributes}
                />
              </BundleGroup>
              <BundleGroup width={4} />
              <Paragraph>{`(${today})`}</Paragraph>
              {!startedToday ? <Icon exclamation color="red" /> : null}
            </BundleGroup>
            {!startedToday ? (
              <ErrorMessage errMsg={`${lang("started")} ${startDate}`} />
            ) : null}
          </Form>
        </FormModalControlled>
      </AccessWrapper>
    </>
  );
};

const CheckOutDialog = ({ renderOpen, loader }) => {
  const checkoutProps = useCheckoutDialog();
  if (checkoutProps?.isLoading) {
    return <>{loader}</>;
  }

  return (
    <>
      <AccessWrapper action={userActions.CHECK_OUT}>
        {renderOpen(() => {
          checkoutProps.onOpen();
        })}
      </AccessWrapper>
      <CheckOutDialogControlled {...checkoutProps} />
    </>
  );
};

export { CheckOutDialogControlled };
export default CheckOutDialog;
