import { FormModalControlled } from "./Modal";
import Form from "./Form";

const ModifierModal = ({
  title,
  isOpen,
  onClose,
  onCancel,
  onSubmit,
  children,
}) => {
  return (
    <>
      <FormModalControlled
        title={title}
        isOpen={isOpen}
        onCancel={onCancel}
        onSubmit={onSubmit}
        onClose={onClose}
      >
        <Form modal narrow>{children}</Form>
      </FormModalControlled>
    </>
  );
};

export default ModifierModal;
