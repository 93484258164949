import useLang from "../../hooks/useLang";
import ModifierModal from "../../components/ModifierModal";
import { BundleGroup } from "../../components/Group";
import ChipSelector from "../../components/ChipSelector";
import Input from "../../components/Input";

const BillingModifier = ({
  billingDropdown,
  scopeInput,
  hourlyInput,
  billingModifierAttribs,
}) => {
  const lang = useLang();
  return (
    <ModifierModal {...billingModifierAttribs}>
      <BundleGroup>
        <ChipSelector {...billingDropdown} />
      </BundleGroup>
      {scopeInput != null &&
      (billingDropdown?.value?.id === "SCOPE" ||
        billingDropdown?.value?.id === "MIXED") ? (
        <BundleGroup>
          <Input
            label={lang("planned_scope")}
            type="number"
            {...scopeInput.attributes}
          />
        </BundleGroup>
      ) : null}
      {hourlyInput != null &&
      (billingDropdown?.value?.id === "HOURLY" ||
        billingDropdown?.value?.id === "MIXED") ? (
        <BundleGroup>
          <Input
            label={lang("planned_hourly")}
            type="number"
            {...hourlyInput.attributes}
          />
        </BundleGroup>
      ) : null}
    </ModifierModal>
  );
};

export default BillingModifier;
