import useTimelogs from "./useTimelogs";
import paramConfig from "../../../config/paramConfig";

const queryParams = {
  [paramConfig.PAGE_SIZE]: 1,
  [paramConfig.PAGE_NUMBER]: 1,
  verified: false,
};

const useUnverifiedCount = () => {
  const { data, ...rest } = useTimelogs(queryParams);
  return { data: data?.pagination?.totalEntries, ...rest };
};

export default useUnverifiedCount;
