import { useState } from "react";

export const useCopyTable = (tableRef) => {
  const [isWorking, setWorking] = useState(false);

  const copyUsingExecCommand = () => {
    setWorking(true);
    var range = document.createRange();
    range.selectNode(tableRef.current);
    window.getSelection().addRange(range);
    if (document && document.execCommand) {
      document.execCommand("Copy");
    }
    if (
      window &&
      window.getSelection &&
      window.getSelection().removeAllRanges
    ) {
      window.getSelection().removeAllRanges();
    }
    setTimeout(() => {
      setWorking(false);
    }, 200);
  };

  const copyUsingClipboardApi = async () => {
    setWorking(true);
    try {
      const tempDiv = document.createElement("div");
      tempDiv.appendChild(tableRef.current.cloneNode(true));
      const anchorTags = tempDiv.querySelectorAll("a");
      anchorTags.forEach((anchorTag) => {
        const href = anchorTag.getAttribute("href");
        if (href && !href.startsWith("http")) {
          anchorTag.setAttribute("href", window.location.origin + href);
        }
      });
      const tableHTML = tempDiv.innerHTML;
      const htmlBlob = new Blob([tableHTML], { type: "text/html" });
      const htmlClipboardItem = new ClipboardItem({
        [htmlBlob.type]: htmlBlob,
      });

      await navigator.clipboard.write([htmlClipboardItem]);
    } catch (err) {
      console.error("Unable to copy table to clipboard:", err);
    } finally {
      setTimeout(() => {
        setWorking(false);
      }, 200);
    }
  };

  const copy = () => {
    if (navigator.clipboard && navigator.clipboard.write) {
      copyUsingClipboardApi();
    } else {
      copyUsingExecCommand();
    }
  };

  return [copy, isWorking];
};