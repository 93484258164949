import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiAuthenticatedSlice";

const employeesAdapter = createEntityAdapter();
const initialState = employeesAdapter.getInitialState();

export const employeesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query({
      query: () => `/users`,
      transformResponse: (response) => {
        return employeesAdapter.setAll(initialState, response);
      },
      providesTags: [{ type: "Employee" }],
    }),
    getMyProfile: builder.query({
      query: () => `/users/me`,
      providesTags: [{ type: "MyProfile" }],
    }),
    addEmployee: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/users`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Employee" }],
    }),
    patchEmployee: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/users/${params?.id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: [{ type: "Employee" }],
    }),
    disableEmployee: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/users/${params?.id}/disabled`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: [{ type: "Employee" }],
    }),
    addSkillToEmployee: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/users/${params?.id}/skills`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Employee" }],
    }),
    removeSkillFromEmployee: builder.mutation({
      query: ({ params }) => {
        return {
          url: `/users/${params?.id}/skills/${params.skill}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Employee" }],
    }),
    addRoleToEmployee: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/users/${params?.id}/roles`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Employee" }],
    }),
    removeRoleFromEmployee: builder.mutation({
      query: ({ params }) => {
        return {
          url: `/users/${params?.id}/roles/${params.role}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Employee" }],
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useDisableEmployeeMutation,
  useGetMyProfileQuery,
  useAddEmployeeMutation,
  usePatchEmployeeMutation,
  useAddSkillToEmployeeMutation,
  useRemoveSkillFromEmployeeMutation,
  useAddRoleToEmployeeMutation,
  useRemoveRoleFromEmployeeMutation,
} = employeesSlice;
