import { useRef } from "react";
import useOffers from "./useOffers";
import useOrganizations from "../../organizations/hooks/useOrganizations";
import useClients from "../../clients/hooks/useClients";
import useStatusChanger from "./useStatusChanger";
import useLang from "../../../hooks/useLang";
import useModifierModal from "../../../hooks/useModifierModal";
import useInput from "../../../hooks/useInput";
import useOffer from "./useOffer";
import usePatchOffer from "./usePatchOffer";
import useSorting from "../../../hooks/useSorting";
import Link from "../../../components/Link";
import { pathnames } from "../../../config/routes";
import useDropdownFilter from "../../../hooks/useDropdownFilter";
import useDropdownSelector from "../../../hooks/useDropdownSelector";
import { useCopyTable } from "../../../hooks/useCopyTable";

const useOffersList = () => {
  const tableRef = useRef();
  const [copy] = useCopyTable(tableRef);
  const offers = useOffers();
  const organisations = useOrganizations();
  const clients = useClients();
  const statusChangerAttribs = useStatusChanger();
  const lang = useLang();

  const myOrganizationIds = Array.isArray(organisations?.data?.ids)
    ? organisations?.data?.ids?.filter(
        (id) => !organisations?.data?.entities[id].is_subcontractor
      )
    : [];
  let clientOptions = Array.isArray(clients?.data?.ids)
    ? clients?.data?.ids?.map((id) => ({
        id,
        label: clients?.data?.entities[id]?.name,
      }))
    : [];
  let organizationOptions = Array.isArray(myOrganizationIds)
    ? myOrganizationIds?.map((id) => ({
        id,
        label: organisations?.data?.entities[id]?.name,
      }))
    : [];

  const { dropdown: organizationDropdown } = useDropdownFilter(
    "organisation_id",
    organizationOptions
  );
  const { dropdown: clientDropdown } = useDropdownFilter(
    "customer_id",
    clientOptions
  );
  const billingOptions = [
    { id: "SCOPE", label: "SCOPE" },
    { id: "HOURLY", label: "HOURLY" },
    { id: "MIXED", label: "MIXED" },
  ];
  const qtyInput = useInput();
  const qtyModifierAttribs = useModifierModal({
    inputs: [{ input: qtyInput, key: "qty" }],
    query: useOffer,
    mutation: usePatchOffer,
  });
  const amountInput = useInput();
  const amountModifierAttribs = useModifierModal({
    inputs: [{ input: amountInput, key: "total_amount" }],
    query: useOffer,
    mutation: usePatchOffer,
  });
  const scopeInput = useInput();
  const scopeModifierAttribs = useModifierModal({
    inputs: [{ input: scopeInput, key: "planned_scope" }],
    query: useOffer,
    mutation: usePatchOffer,
  });
  const hourlyInput = useInput();
  const hourlyModifierAttribs = useModifierModal({
    inputs: [{ input: hourlyInput, key: "planned_hourly" }],
    query: useOffer,
    mutation: usePatchOffer,
  });
  const billingDropdown = useDropdownSelector({
    options: billingOptions,
    defaultValue: { id: null },
  });
  const billingModifierAttribs = useModifierModal({
    inputs: [
      { input: billingDropdown, key: "billing" },
      { input: scopeInput, key: "planned_scope" },
      { input: hourlyInput, key: "planned_hourly" },
    ],
    query: useOffer,
    mutation: usePatchOffer,
  });

  const createdAttribs = useSorting("date_created");
  const modifiedAttribs = useSorting("date_modified");
  const plannedScopeAttribs = useSorting("planned_scope");
  const plannedHoursAttribs = useSorting("planned_hourly");
  const plannedTotalAttribs = useSorting("planned_total");
  const billingAttribs = useSorting("billing");
  const amountAttribs = useSorting("total_amount");
  const nameAttribs = useSorting("name");
  const qtyAttribs = useSorting("qty");

  const offerRows = offers?.data?.ids?.map((entityId) => ({
    ...offers?.data?.entities[entityId],
    id: entityId,
    organisation: organisations?.data
      ? organisations?.data?.entities[
          offers?.data?.entities[entityId].organisation_id
        ]?.name
      : organisations?.isLoading
      ? "..."
      : "",
    customer: clients?.data
      ? clients?.data?.entities[offers?.data?.entities[entityId].customer_id]
          ?.name
      : clients?.isLoading
      ? "..."
      : "",
  }));

  const handleStatusChange = (data) => {
    statusChangerAttribs.onOpen(data);
  };

  const tableConfig = [
    {
      id: "status",
      width: 24,
      header: { label: "Status" },
      entityKey: "status",
      onClick: handleStatusChange,
      onClickCondition: (value) => value === "ACTIVE",
    },
    {
      id: "date_created",
      width: 24,
      header: createdAttribs,
      entityKey: "date_created",
      format: (value) => (value != null ? value.split(" ")[0] : "-"),
      sort: true,
    },
    {
      id: "date_modified",
      width: 24,
      header: modifiedAttribs,
      entityKey: "date_modified",
      format: (value) => (value != null ? value.split(" ")[0] : "-"),
      sort: true,
    },
    {
      id: "project_name",
      width: 64,
      header: nameAttribs,
      entityKey: "name",
      sort: true,
      component: (value, id, data) => {
        if (data?.status === "ACCEPTED")
          return (
            <Link to={`${pathnames.PROJECTS}/${data.project_id}`}>{value}</Link>
          );
        return value;
      },
    },
    {
      id: "organisation",
      width: 48,
      header: { label: lang("organisation") },
      entityKey: "organisation",
    },
    {
      id: "customer",
      width: 48,
      header: { label: lang("customer") },
      entityKey: "customer",
    },
    {
      id: "planned_scope",
      width: 24,
      header: plannedScopeAttribs,
      entityKey: "planned_scope",
      sort: true,
      onClick: scopeModifierAttribs.onOpen,
      onClickCondition: (value, id, data) => {
        return data?.planned_scope != null && data?.status === "ACTIVE";
      },
      showTotal: true,
    },
    {
      id: "planned_hourly",
      width: 24,
      header: plannedHoursAttribs,
      entityKey: "planned_hourly",
      sort: true,
      onClick: hourlyModifierAttribs.onOpen,
      onClickCondition: (value, id, data) => {
        return data?.planned_hourly != null && data?.status === "ACTIVE";
      },
      showTotal: true,
    },
    {
      id: "planned_total",
      width: 24,
      header: plannedTotalAttribs,
      entityKey: "planned_total",
      sort: true,
      showTotal: true,
    },
    {
      id: "billing",
      width: 20,
      header: billingAttribs,
      entityKey: "billing",
      sort: true,
      onClick: billingModifierAttribs.onOpen,
      onClickCondition: (value, id, data) => {
        return data?.status === "ACTIVE";
      },
    },
    {
      id: "qty",
      width: 16,
      header: qtyAttribs,
      entityKey: "qty",
      onClick: qtyModifierAttribs.onOpen,
      onClickCondition: (value, id, data) => {
        return data?.status === "ACTIVE";
      },
      sort: true,
    },
    {
      id: "uom",
      width: 16,
      header: { label: lang("unit_of_measurement") },
      entityKey: "uom",
    },
    {
      id: "amount",
      width: 20,
      header: amountAttribs,
      entityKey: "total_amount",
      onClick: amountModifierAttribs.onOpen,
      onClickCondition: (value, id) => {
        return offers?.data?.entities[id]?.status === "ACTIVE";
      },
      sort: true,
      showTotal: true,
    },
  ];

  const tabs = [
    { param: "status", to: null, label: lang("active"), key: "ACTIVE" },
    {
      param: "status",
      to: "ACCEPTED",
      label: lang("accepted"),
      key: "ACCEPTED",
    },
    {
      param: "status",
      to: "DECLINED",
      label: lang("declined"),
      key: "DECLINED",
    },
    { param: "status", to: "all", label: lang("all"), key: "ALL" },
  ];

  return {
    tableAttribs: {
      config: tableConfig,
      data: offerRows || [],
      ref: tableRef,
    },
    statusChangerAttribs,
    totalPages: offers?.data?.pagination?.totalPages,
    qtyModifierAttribs,
    qtyInput,
    amountModifierAttribs,
    amountInput,
    scopeInput,
    scopeModifierAttribs,
    hourlyInput,
    hourlyModifierAttribs,
    billingDropdown,
    billingModifierAttribs,
    tabs,
    recordCount: offers?.data?.pagination,
    organizationDropdown,
    clientDropdown,
    copy,
  };
};

export default useOffersList;
