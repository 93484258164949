const lang = {
  EN: {
    abbreviation: "Abbreviation",
    accepted: "Accepted",
    active: "Active",
    actual: "Actual",
    actual_hours: "Actual Hours",
    actual_scope: "Actual Scope",
    accept_offer: "Offer Accepted",
    accept_decline_offer: "Change Offer Status",
    add: "Add",
    add_activity: "Add Activity",
    add_client: "Add Client",
    add_employee: "Add Employee",
    add_invoice: "Add Invoice",
    add_offer: "Add Offer",
    add_project: "Add Project",
    all: "All",
    amount: "Amount",
    amount_eur: "Amount, EUR",
    billing: "Billing",
    by_skill: "By Skill",
    check_in: "Check-in",
    check_out: "Check-out",
    change_status: "Change Status",
    change_project_status: "Change Project Status",
    client: "Client",
    clients: "Clients",
    collapse: "Collapse",
    company: "Company",
    completion: "Completion",
    copy: "Copy",
    customer: "Customer",
    date: "Date",
    date_start: "Start Date",
    date_finish: "Finish Date",
    date_created: "Created",
    date_modified: "Modified",
    declined: "Declined",
    decline_offer: "Offer Declined",
    director: "Director",
    dur: "Dur.",
    duration: "Duration",
    edit_client: "Edit Client",
    edit_employee: "Edit Employee",
    edit_offer: "Edit Offer",
    edit_activity: "Edit Activity",
    efficiency: "Efficiency",
    employee: "Employee",
    employees: "Employees",
    ena: "Ena.",
    estimated: "Estimated",
    estimated_hours: "Estimated Hours",
    estimated_scope: "Estimated Scope",
    residual_scope: "Scope Remaining",
    finish: "Finish",
    finish_actual: "Actual Finish",
    finish_task: "Finish Task",
    finished: "Finished",
    first_name: "First Name",
    fitter: "Fitter",
    from: "From",
    full_no: "Project No.",
    hours: "Hours",
    hourly: "Hourly",
    hourly_progress: "Hourly Progress",
    "hourly.approved": "Hourly. Estimated",
    "hourly.planned": "Hourly. Estimated",
    "hourly.actual": "Hourly. Actual",
    "hourly.residual": "Hourly. Remaining",
    "hourly.progress": "Hourly. Progress",
    "hourly.last_revision": "Hourly. Last Revision",
    inactive: "Inactive",
    invoiced: "Invoiced",
    invoice_num: "Invoice No.",
    invoiced_amount: "Invoiced Amount",
    item: "Item",
    job: "Job",
    job_description: "Job Description",
    job_type: "Job Type",
    last_name: "Last Name",
    last_revision: "Last Revision",
    loading: "Loading",
    logout: "Logout",
    modified: "Modified",
    modify_employee_status: "Modify Employee Status",
    modify_project_completion: "Modify Project Completion",
    my_activity: "My Activity",
    my_organisations: "My Organisations",
    name: "Name",
    new_activity: "New Activity",
    new_client: "New Client",
    new_employee: "New Employee",
    new_offer: "New Offer",
    new_project: "New Project",
    offer: "Offer",
    offers: "Offers",
    organisation: "Organisation",
    organization: "Organisation",
    organisations: "Organisations",
    organizations: "Organisations",
    new_organization: "New Organisation",
    add_organization: "Add Organisation",
    page_size: "Page Size",
    painter: "Painter",
    planned: "Planned",
    pending: "Pending",
    planned_hrs: "Planned Hrs.",
    planned_hours: "Planned Hours",
    planned_hourly: "Hourly. Planned",
    planned_scope: "Scope. Planned ",
    planned_total: "Total. Planned",
    planned_warranty: "Warranty. Planned",
    primary_email: "Primary Email",
    profile: "Profile",
    progress: "Progress",
    project_details_placeholder: "Project details...",
    project_number: "Project No.",
    project: "Project",
    projects: "Projects",
    project_no: "Project No.",
    project_start_date: "Project Start Date",
    project_finish_date: "Project Finish Date",
    residual: "Remaining",
    residual_amount: "Unpaid Amount",
    remaining_amount: "Unpaid Amount",
    residual_hours: "Hourly Remaining",
    revised: "Revised",
    qty: "Qty.",
    quantity: "Quantity",
    role: "Role",
    roles: "Roles",
    scope: "Scope",
    scope_by_skill: "Scope by Skill",
    scope_progress: "Scope Progress",
    set: "Set",
    set_page_size: "Set Page Size",
    skill: "Skill",
    skills: "Skills",
    "scope.approved": "Scope. Planned",
    "scope.planned": "Scope. Estimated",
    "scope.actual": "Scope. Actual",
    "scope.residual": "Scope. Remaining",
    "scope.efficiency": "Scope. Efficiency",
    "scope.progress": "Scope. Progress",
    "scope.last_revision": "Scope. Last Revision",
    status: "Status",
    start: "Start",
    start_actual: "Actual Start",
    start_task: "Start Task",
    started: "Started",
    stop: "Stop",
    sub: "Sub.",
    submit: "Submit",
    subcontractor: "Subcontractor",
    subcontractors: "Subcontractors",
    time: "Time",
    timelogs: "Time Logs",
    timesheets: "Timesheets",
    to: "To",
    total_amount: "Total Amount",
    unit: "Unit",
    unit_of_measurement: "Unit of Measurement",
    uom: "UoM.",
    verified: "Verified",
    vrf: "Vrf.",
    unpaid: "Unpaid",
    unverified: "Unverified",
    "warranty.approved": "Warranty. Estimated",
    "warranty.planned": "Warranty. Estimated",
    "warranty.actual": "Warranty. Actual",
    "warranty.residual": "Warranty. Remaining",
    "warranty.progress": "Warranty. Progress",
    "warranty.last_revision": "Warranty. Last Revision",
    warranty: "Warranty",
    welder: "Welder",
    year: "Year",
    month: "Month",
    month_1: "Jan.",
    month_2: "Feb.",
    month_3: "Mar.",
    month_4: "Apr.",
    month_5: "May",
    month_6: "June",
    month_7: "July",
    month_8: "Aug.",
    month_9: "Sept.",
    month_10: "Oct.",
    month_11: "Nov.",
    month_12: "Dec.",
  },
};

const useLang = () => {
  const onTranslate = (textToTranslate) => {
    if (typeof textToTranslate !== "string") return "";
    const result = lang.EN[textToTranslate.toLocaleLowerCase()];
    if (result == null) return textToTranslate;
    return result;
  };
  return onTranslate;
};

export default useLang;
