import { useEffect } from "react";
import classNames from "classnames";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import Aside from "./Aside";
// import Breadcrumbs from "./Breadcrumbs";
import { useState } from "react";
import useScreenSize from "../hooks/useScreenSize";
import ProfileButton from "../features/navigation/ProfileButton";
import { BundleGroup } from "./Group";
import useRefresher from "../hooks/useRefresher";
import styles from "./layout.module.css";

const Layout = () => {
  useRefresher();
  const [sideNavExpanded, setSideNavExpanded] = useState(true);
  const { isMobile, isTablet, isLaptop, isDesktop, isBigScreen } =
    useScreenSize();

  useEffect(() => {
    if (isTablet || isMobile) {
      setSideNavExpanded(false);
    }
  }, [isTablet, isMobile]);

  useEffect(() => {
    if (isLaptop || isDesktop || isBigScreen) {
      setSideNavExpanded(true);
    }
  }, [isLaptop, isDesktop, isBigScreen]);

  return (
    <>
      <Aside
        asideAttributes={{
          expanded: sideNavExpanded,
          onToggle: () => {
            setSideNavExpanded((state) => !state);
          },
        }}
      />
      <main
        className={classNames(styles.appContainer, {
          [styles.sideNavExpanded]: sideNavExpanded,
          [styles.sideNavCollapsed]: !sideNavExpanded,
        })}
      >
        <div className={styles.app}>
          <ErrorBoundary fallback="Error in content">
            {/* <Breadcrumbs /> */}
            <Outlet />
          </ErrorBoundary>
        </div>
        <div
          style={{
            position: "absolute",
            top: "var(--spacing-2)",
            right: "var(--spacing-3)",
          }}
        >
          <ProfileButton right />
        </div>
      </main>
    </>
  );
};

const CenteredLayout = ({ children }) => {
  return (
    <>
      <main
        className={classNames(styles.app, styles.fullScreen, styles.centered)}
      >
        <ErrorBoundary fallback="Error in content">{children}</ErrorBoundary>
      </main>
    </>
  );
};

const HomeLayout = ({ children }) => {
  return (
    <>
      <BundleGroup alignTop>
        <main className={classNames(styles.appContainer, styles.homeLayout)}>
          <div className={classNames(styles.app, styles.homeLayout)}>
            <ErrorBoundary fallback="Error in content">
              {children}
            </ErrorBoundary>
          </div>
        </main>
        <div style={{ position: "fixed", top: "0", right: "0" }}>
          <ProfileButton right />
        </div>
      </BundleGroup>
    </>
  );
};

export { CenteredLayout, HomeLayout };
export default Layout;
