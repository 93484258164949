import { useState } from "react";
import useMyActivity from "./useMyActivity";
import useCheckInFinish from "./useCheckInFinish";
import useInput from "../../../hooks/useInput";
import getTimeString from "../../../utils/getTimeString";

const useCheckoutDialog = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [handleFinish, { isLoading }] = useCheckInFinish();
  const { data: myCurrentActivity } = useMyActivity();
  const timeInput = useInput("");
  const currentDate = new Date();
  const fullYear = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const today = `${fullYear}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;

  const resetInputs = () => {
    timeInput.setValue(getTimeString());
  };

  const onOpen = () => {
    resetInputs();
    setIsOpen(true);
    props?.onOpen();
  };
  const onSubmit = () => {
    const startTime = myCurrentActivity?.start?.split(" ")[0];
    handleFinish(
      { id: myCurrentActivity.id },
      {
        finish:
          startTime === timeInput?.value
            ? getTimeString(null, true)
            : timeInput?.value,
      }
    );
  };
  const onCancel = () => {
    setIsOpen(false);
    props?.onClose();
  };
  const onClose = () => {
    setIsOpen(false);
    props?.onClose();
    resetInputs();
  };

  const startedToday = today === myCurrentActivity?.date;

  return {
    isOpen,
    onOpen,
    onSubmit,
    onCancel,
    onClose,
    timeInput,
    isLoading,
    startDate: myCurrentActivity?.date,
    today,
    startedToday,
  };
};

export default useCheckoutDialog;
