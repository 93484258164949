import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiAuthenticatedSlice";
import getPaginationInfo from "../../utils/getPaginationInfo";

const projectsAdapter = createEntityAdapter();
const initialState = projectsAdapter.getInitialState();

export const projectsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: (arg) => `/projects${arg?.query ? arg?.query : ""}`,
      transformResponse: (response, meta) => {
        const pagination = getPaginationInfo(meta);
        const data = projectsAdapter.setAll(initialState, response || []);
        return { ...data, pagination };
      },
      providesTags: (result, error, arg) => [
        { type: "Project", id: arg?.query },
      ],
    }),
    getProject: builder.query({
      query: (arg) => `/projects/${arg?.id}`,
      providesTags: (result, error, arg) => [{ type: "Project", id: arg?.id }],
    }),
    patchProject: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/projects/${params?.id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Project" },
        { type: "Project", id: arg?.params?.id },
      ],
    }),
    postProjectStatus: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/projects/${params?.id}/status`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: [{ type: "Project" }],
    }),
    patchProgress: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/projects/${params?.id}/progress`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: [{ type: "Project" }],
    }),
    addInvoice: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/projects/${params?.id}/invoices`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Project" }],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  usePatchProjectMutation,
  usePostProjectStatusMutation,
  usePatchProgressMutation,
  useAddInvoiceMutation,
} = projectsSlice;
