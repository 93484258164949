import useLang from "../../hooks/useLang";
import { FormModalControlled } from "../../components/Modal";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import { BundleGroup } from "../../components/Group";
import Form from "../../components/Form";
import ChipSelector from "../../components/ChipSelector";

const ProjectStatusChangerControlled = ({
  isOpen,
  onSubmit,
  onCancel,
  onClose,
  title,
  submitLabel,
  statusSelector,
}) => {
  const lang = useLang();
  return (
    <>
      <AccessWrapper action={userActions.PATCH_PROJECT}>
        <FormModalControlled
          isOpen={isOpen}
          title={title || lang("change_project_status")}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onClose={onClose}
          submitLabel={submitLabel}
        >
          <Form modal>
            <BundleGroup>
              <ChipSelector {...statusSelector} />
            </BundleGroup>
          </Form>
        </FormModalControlled>
      </AccessWrapper>
    </>
  );
};

export { ProjectStatusChangerControlled };
