import { useState, useEffect, useRef } from "react";
import useTimelog from "./useTimelog";
import useInput from "../../../hooks/useInput";
import useCreateActivity from "./useCreateActivity";
import usePatchActivity from "./usePatchActivity";
import useSelector from "../../../hooks/useDropdownSelector";
import getTimeString from "../../../utils/getTimeString";
import useProjects from "../../projects/hooks/useProjects";
import useEmployees from "../../employees/hooks/useEmployees";
import activeStatuses from "../../../config/activeStatuses";

const useActivityCreator = (props) => {
  const [createActivity] = useCreateActivity();
  const [patchActivity] = usePatchActivity();
  const finishSelector = useSelector({
    defaultValue: { id: "DURATION", label: "Duration" },
    options: [
      { id: "DURATION", label: "Duration" },
      { id: "TIME", label: "Time" },
    ],
  });
  const [isOpen, setIsOpen] = useState(false);
  const dataRef = useRef();
  const [activityId, _setActivityId] = useState();
  const setActivityId = (data) => {
    dataRef.current = undefined;
    _setActivityId((state) => (state !== data ? data : state));
  };
  const { data } = useTimelog(activityId);
  const { data: employees, status: employeesRequestStatus } = useEmployees();
  const { data: projects, status: projectsRequestStatus } = useProjects();
  const startTimeInput = useInput("");
  const startDateInput = useInput("");
  const endTimeInput = useInput("");
  const endDateInput = useInput("");
  const durationInput = useInput("");

  const activeEmployeeIds = employees?.ids?.filter((employeeId) => {
    return (
      !employees?.entities[employeeId]?.disabled &&
      Array.isArray(employees?.entities[employeeId]?.skills) &&
      employees?.entities[employeeId]?.skills.length > 0
    );
  });
  const employeeDropdown = useSelector({
    defaultValue: { id: null, label: "-" },
    options: activeEmployeeIds?.map((employeeId) => ({
      id: employeeId,
      label: `${employees?.entities[employeeId]?.given_name} ${employees?.entities[employeeId]?.family_name}`,
    })),
  });
  const activeProjectIds = projects?.ids?.filter((projectId) => {
    return activeStatuses.indexOf(projects?.entities[projectId].status) > -1;
  });
  const projectsDropdown = useSelector({
    defaultValue: { id: null, label: "-" },
    options: activeProjectIds?.map((projectId) => ({
      id: projectId,
      label: `${projects?.entities[projectId]?.full_no} ${projects?.entities[projectId]?.name}`,
    })),
  });
  const skillsDropdown = useSelector({
    defaultValue: { id: null, label: "-" },
  });

  const resetInputs = () => {
    startTimeInput.setValue(
      getTimeString(
        data?.start ? `${data?.date} ${data?.start.split(" ")[0]}` : null
      )
    );
    startDateInput.setValue(data?.date);
    endTimeInput.setValue(
      getTimeString(
        data?.finish ? `${data?.date} ${data?.finish.split(" ")[0]}` : null
      )
    );
    endDateInput.setValue(data?.date);
    durationInput.setValue(
      data?.duration != null ? Math.round(data?.duration * 1000) / 1000 : 0
    );
  };

  useEffect(() => {
    if (dataRef.current == null && data != null) {
      dataRef.current = data;
      resetInputs();
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (employeesRequestStatus === "fulfilled") {
      employeeDropdown.reset(
        activeEmployeeIds?.map((employeeId) => ({
          id: employeeId,
          label: `${employees?.entities[employeeId]?.given_name} ${employees?.entities[employeeId]?.family_name}`,
        }))
      );
    }
    // eslint-disable-next-line
  }, [employeesRequestStatus]);

  useEffect(() => {
    if (projectsRequestStatus === "fulfilled") {
      projectsDropdown.reset(
        activeProjectIds?.map((projectId) => ({
          id: projectId,
          label: `${projects?.entities[projectId]?.full_no} ${projects?.entities[projectId]?.name}`,
        }))
      );
    }
    // eslint-disable-next-line
  }, [projectsRequestStatus]);

  useEffect(() => {
    if (employees?.entities[employeeDropdown?.value?.id]) {
      skillsDropdown.reset(
        employees?.entities[employeeDropdown?.value?.id]?.skills?.map(
          (skill) => ({
            id: skill.id,
            label: skill.name,
          })
        )
      );
    }
    // eslint-disable-next-line
  }, [employeeDropdown?.value?.id]);

  const onOpen = (data) => {
    setActivityId(data);
    setIsOpen(true);
    if (typeof props?.onOpen === "function") props?.onOpen();
  };
  const onSubmit = () => {
    let body = {};
    if (startTimeInput?.value)
      body.start = {
        date: startDateInput?.value,
        time: startTimeInput?.value,
      };
    if (finishSelector?.value?.id === "TIME") {
      if (endTimeInput?.value)
        body.finish = {
          date: endDateInput?.value,
          time: endTimeInput?.value,
        };
    } else {
      if (durationInput?.value) body.duration = durationInput?.value;
    }
    if (projectsDropdown?.value?.id) {
      body.project_id = projectsDropdown?.value?.id;
    }
    if (employeeDropdown?.value?.id) {
      body.user_id = employeeDropdown?.value?.id;
    }
    if (skillsDropdown?.value?.id) {
      body.skill_id = skillsDropdown?.value?.id;
    }
    if (props?.patching) {
      patchActivity({ id: activityId }, body);
    } else {
      createActivity(null, body);
    }
  };

  const onCancel = () => {
    setIsOpen(false);
    resetInputs();
    if (typeof props?.onClose === "function") props?.onClose();
  };
  const onClose = () => {
    setIsOpen(false);
    if (typeof props?.onClose === "function") props?.onClose();
    resetInputs();
  };

  let enabled =
    (props?.patching ||
      (!props?.patching &&
        projectsDropdown?.value?.id != null &&
        employeeDropdown?.value?.id != null &&
        skillsDropdown?.value?.id != null)) &&
    startDateInput?.value != null &&
    startTimeInput?.value != null &&
    ((finishSelector?.value?.id === "DURATION" &&
      durationInput?.value &&
      durationInput?.value > 0) ||
      (finishSelector?.value?.id === "TIME" &&
        endDateInput?.value != null &&
        endTimeInput?.value != null));

  return {
    isOpen,
    onOpen,
    onSubmit,
    onCancel,
    onClose,
    startDateInput,
    startTimeInput,
    endDateInput,
    endTimeInput,
    durationInput,
    finishSelector,
    employeeDropdown,
    skillsDropdown,
    projectsDropdown,
    isCreatingNew: !props?.patching,
    enabled,
  };
};

export default useActivityCreator;
