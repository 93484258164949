import useLang from "../../hooks/useLang";
import { FormModalControlled } from "../../components/Modal";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import Button from "../../components/Button";
import { BundleGroup, SplitGroup } from "../../components/Group";
import Form from "../../components/Form";
import Input from "../../components/Input";
// import TextArea from "../../components/TextArea";
import DropdownSelector from "../../components/DropdownSelector";
import Label from "../../components/Label";

const OfferCreator = ({
  isOpen,
  onSubmit,
  onCancel,
  onClose,
  clientCreatorOpen,
  nameInput,
  organizationDropdown,
  detailsInput,
  dateStartInput,
  dateFinishInput,
  clientDropdown,
  billingDropdown,
  quantityInput,
  unitDropdown,
  submitEnabled,
  scopeInput,
  showScopeInput,
  hoursInput,
  showHoursInput,
  amountInput,
}) => {
  const lang = useLang();
  return (
    <>
      <FormModalControlled
        isOpen={isOpen}
        title={lang("new_offer")}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onClose={onClose}
        submitLabel={lang("add_offer")}
        disabled={!submitEnabled}
      >
        <Form modal>
          <Input label={lang("name")} type="text" {...nameInput.attributes} />
          <DropdownSelector
            label={lang("organisation")}
            {...organizationDropdown}
          />
          {/*
          <TextArea
            placeholder={lang("offer_details_placeholder")}
            {...detailsInput.attributes}
          />
          <Input
            label={lang("date_start")}
            type="date"
            {...dateStartInput.attributes}
          />
          <Input
            label={lang("date_finish")}
            type="date"
            {...dateFinishInput.attributes}
          />
  */}
          <BundleGroup>
            <DropdownSelector label={lang("client")} {...clientDropdown} />
            <SplitGroup />
            <AccessWrapper action={userActions.CREATE_CLIENT}>
              <Button emphasized onClick={clientCreatorOpen}>
                {lang("add_client")}
              </Button>
            </AccessWrapper>
          </BundleGroup>
          <DropdownSelector label={lang("billing")} {...billingDropdown} />
          {showScopeInput ? (
            <Input
              label={lang("scope")}
              type="number"
              {...scopeInput.attributes}
            />
          ) : null}
          {showHoursInput ? (
            <Input
              label={lang("hours")}
              type="number"
              {...hoursInput.attributes}
            />
          ) : null}
          <BundleGroup>
            <BundleGroup width={48}>
              <Input
                label={lang("quantity")}
                type="number"
                {...quantityInput.attributes}
              />
            </BundleGroup>
            <BundleGroup>
              <BundleGroup width={4} />
              <BundleGroup width={36}>
                <Label>{lang("unit_of_measurement")}</Label>
              </BundleGroup>
            </BundleGroup>
            <BundleGroup width={18}>
              <DropdownSelector {...unitDropdown} />
            </BundleGroup>
          </BundleGroup>
          <BundleGroup>
            <Input
              label={lang("amount_eur")}
              type="number"
              {...amountInput.attributes}
            />
          </BundleGroup>
        </Form>
      </FormModalControlled>
    </>
  );
};

export default OfferCreator;
