import useLang from "../../hooks/useLang";
import { PageHeader } from "../../components/Header";
import useEmployeeList from "./hooks/useEmployeeList";
import { PageSubheaderGroup, SplitGroup } from "../../components/Group";
import Button from "../../components/Button";
import EmployeeCreator from "./EmployeeCreator";
import DynamicTable from "../../components/DynamicTable";
import StatusModifier from "./StatusModifier";

const EmployeeList = () => {
  const lang = useLang();
  const { tableAttribs, statusDialogAttribs } = useEmployeeList();

  return (
    <>
      <PageHeader>
        <h1>{lang("employees")}</h1>
      </PageHeader>
      <PageSubheaderGroup>
        <SplitGroup />
        <EmployeeCreator
          loader={
            <Button important loader>
              {lang("add_employee")}
            </Button>
          }
          renderOpen={(onClick) => (
            <Button important onClick={onClick}>
              {lang("add_employee")}
            </Button>
          )}
        />
      </PageSubheaderGroup>
      <DynamicTable {...tableAttribs} />
      <StatusModifier {...statusDialogAttribs} />
    </>
  );
};

export default EmployeeList;
