// import useLang from "../../../hooks/useLang";
import { useRef } from "react";
import useProjects from "./useProjects";
import useOrganizations from "../../organizations/hooks/useOrganizations";
import useClients from "../../clients/hooks/useClients";
import { useCopyTable } from "../../../hooks/useCopyTable";
//import useFiltering from "../../../hooks/useFiltering";
import useSortParam from "../../../hooks/useSortParam";
import useFilterParam from "../../../hooks/useFilterParam";
import useCompletionModifier from "./useCompletionModifier";
import useLang from "../../../hooks/useLang";
import useProjectStatusChanger from "./useProjectStatusChanger";
import useModifierModal from "../../../hooks/useModifierModal";
import useInput from "../../../hooks/useInput";
import usePatchProject from "./usePatchProject";
import useProject from "./useProject";
import useDropdownSelector from "../../../hooks/useDropdownSelector";
import useProjectTableConfig from "./useProjectTableConfig";
import useDropdownFilter from "../../../hooks/useDropdownFilter";

const useProjectList = () => {
  const lang = useLang();
  const { sortParam } = useSortParam();
  const { filterParam } = useFilterParam("status");
  let paramArray = [];
  if (sortParam) paramArray.push(sortParam);
  if (filterParam) paramArray.push(filterParam);

  const tableRef = useRef();
  const projects = useProjects(
    paramArray.length > 0 ? paramArray.join("&") : null
  );
  const organisations = useOrganizations();
  const clients = useClients();
  const [copy] = useCopyTable(tableRef);

  let clientOptions = Array.isArray(clients?.data?.ids)
    ? clients?.data?.ids?.map((id) => ({
        id,
        label: clients?.data?.entities[id]?.name,
      }))
    : [];
  const myOrganizationIds = Array.isArray(organisations?.data?.ids)
    ? organisations?.data?.ids?.filter(
        (id) => !organisations?.data?.entities[id].is_subcontractor
      )
    : [];
  let organizationOptions = Array.isArray(myOrganizationIds)
    ? myOrganizationIds?.map((id) => ({
        id,
        label: organisations?.data?.entities[id]?.name,
      }))
    : [];

  const { dropdown: organizationDropdown } = useDropdownFilter(
    "organisation_id",
    organizationOptions
  );
  const { dropdown: clientDropdown } = useDropdownFilter(
    "customer_id",
    clientOptions
  );
  const billingOptions = [
    { id: "SCOPE", label: "SCOPE" },
    { id: "HOURLY", label: "HOURLY" },
    { id: "MIXED", label: "MIXED" },
  ];
  const { dropdown: billingDropdownFilter } = useDropdownFilter(
    "billing",
    billingOptions
  );

  const completionDialogAttribs = useCompletionModifier();
  const statusChangerAttribs = useProjectStatusChanger();
  const scopeInput = useInput();
  const scopeModifierAttribs = useModifierModal({
    inputs: [{ input: scopeInput, key: "approved_scope" }],
    query: useProject,
    mutation: usePatchProject,
  });
  const amountInput = useInput();
  const amountModifierAttribs = useModifierModal({
    inputs: [{ input: amountInput, key: "total_amount" }],
    query: useProject,
    mutation: usePatchProject,
  });
  const billingDropdown = useDropdownSelector({
    options: billingOptions,
    defaultValue: { id: null },
  });
  const hourlyInput = useInput();
  const hourlyModifierAttribs = useModifierModal({
    inputs: [{ input: hourlyInput, key: "approved_hourly" }],
    query: useProject,
    mutation: usePatchProject,
  });
  const warrantyInput = useInput();
  const warrantyModifierAttribs = useModifierModal({
    inputs: [{ input: warrantyInput, key: "approved_warranty" }],
    query: useProject,
    mutation: usePatchProject,
  });
  const billingModifierAttribs = useModifierModal({
    inputs: [
      { input: billingDropdown, key: "billing" },
      { input: scopeInput, key: "approved_scope" },
      { input: hourlyInput, key: "approved_hourly" },
    ],
    query: useProject,
    mutation: usePatchProject,
  });

  const projectRows = projects?.data?.ids?.map((entityId) => ({
    ...projects?.data?.entities[entityId],
    id: entityId,
    organisation: organisations?.data
      ? organisations?.data?.entities[
          projects?.data?.entities[entityId].organisation_id
        ]?.name
      : organisations?.isLoading
      ? "..."
      : "",
    customer: clients?.data
      ? clients?.data?.entities[projects?.data?.entities[entityId].customer_id]
          ?.name
      : clients?.isLoading
      ? "..."
      : "",
  }));

  const handleStatusChange = (id) => {
    statusChangerAttribs.onOpen(id);
  };

  const tableConfig = useProjectTableConfig({
    skipFields: ["qty", "uom"],
    handleStatusClick: handleStatusChange,
    handleScopeClick: scopeModifierAttribs.onOpen,
    handleHourlyClick: hourlyModifierAttribs.onOpen,
    handleWarrantyClick: warrantyModifierAttribs.onOpen,
    handleProgressClick: completionDialogAttribs.onOpen,
    handleWarrantyProgressClick: completionDialogAttribs.onOpen,
    handleHourlyProgressClick: completionDialogAttribs.onOpen,
    handleBillingClick: billingModifierAttribs.onOpen,
    handleAmountClick: amountModifierAttribs.onOpen,
  });

  const tabs = [
    { param: "status", to: null, label: lang("active"), key: "ACTIVE" },
    { param: "status", to: "PENDING", label: lang("pending"), key: "PENDING" },
    {
      param: "status",
      to: "FINISHED,WARRANTY",
      label: lang("finished"),
      key: "FINISHED",
    },
    {
      param: "status",
      to: "all",
      label: lang("all"),
      key: "ALL",
    },
  ];

  return {
    copy,
    tableAttribs: {
      ref: tableRef,
      config: tableConfig,
      data: projectRows || [],
    },
    completionDialogAttribs,
    statusChangerAttribs,
    totalPages: projects?.data?.pagination?.totalPages,
    scopeInput,
    scopeModifierAttribs,
    amountInput,
    amountModifierAttribs,
    hourlyInput,
    hourlyModifierAttribs,
    warrantyInput,
    warrantyModifierAttribs,
    billingDropdown,
    billingModifierAttribs,
    billingDropdownFilter,
    tabs,
    recordCount: projects?.data?.pagination,
    organizationDropdown,
    clientDropdown,
  };
};

export default useProjectList;
