import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiAuthenticatedSlice";
import getPaginationInfo from "../../utils/getPaginationInfo";

const timelogsAdapter = createEntityAdapter();
const initialState = timelogsAdapter.getInitialState();

export const timelogsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTimelogs: builder.query({
      query: (arg) => `/activities${arg?.query ? arg?.query : ""}`,
      transformResponse: (response, meta) => {
        const pagination = getPaginationInfo(meta);
        const data = timelogsAdapter.setAll(initialState, response);
        return { ...data, pagination };
      },
      providesTags: (result, error, arg) => [
        { type: "Activity" },
        { type: "Activity", id: arg?.query },
      ],
    }),
    createActivity: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/activities`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Activity" }],
    }),
    verifyActivity: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/activities/${params?.id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: [{ type: "Activity" }],
    }),
    patchActivity: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/activities/${params?.id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: [{ type: "Activity" }],
    }),
  }),
});

export const {
  useGetTimelogsQuery,
  useCreateActivityMutation,
  useVerifyActivityMutation,
  usePatchActivityMutation,
} = timelogsSlice;
