import { useGetEmployeesQuery } from "../employeesSlice";
import usePolicyChecker from "../../auth/usePolicyChecker";
import { userActions } from "../../../config/accessConfig";

const useEmployees = () => {
  const grantAccess = usePolicyChecker({
    action: userActions.GET_EMPLOYEES,
  });
  const employees = useGetEmployeesQuery(null, { skip: !grantAccess });

  let employeeData = { ids: employees?.data?.ids, entities: {} };
  if (employees?.data?.ids) {
    employees.data.ids.map((employeeId) => {
      const skillNames = employees.data.entities[employeeId].skills.map(
        (skill) => skill.name
      );
      employeeData.entities[employeeId] = {
        ...employees.data.entities[employeeId],
        skill_names: skillNames.join(", "),
      };
      return false;
    });
  }

  return {
    ...employees,
    data: employeeData,
    refetch: grantAccess ? employees.refetch : () => {},
  };
};

export default useEmployees;
