import { useState, useRef, useEffect } from "react";
import useInput from "../../../hooks/useInput";
import useClient from "./useClient";
import useClients from "./useClients";
import useClientPatch from "./useClientPatch";

const useClientCreator = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dataRef = useRef();
  const [clientId, _setClientId] = useState();
  const setClientId = (data) => {
    dataRef.current = undefined;
    _setClientId((state) => (state !== data ? data : state));
  };
  const { data } = useClient(clientId);
  const { refetch: refetchClients } = useClients();
  const [handleClientPatch] = useClientPatch();
  const nameInput = useInput("");
  const abbreviationInput = useInput("");

  const resetName = () => {
    const name = data?.name || "";
    nameInput.setValue((state) => (name !== state ? name : state));
  };

  const resetAbbr = () => {
    const abbr = data?.abbr || "";
    abbreviationInput.setValue((state) => (abbr !== state ? abbr : state));
  };

  const resetInputs = () => {
    resetName();
    resetAbbr();
  };

  useEffect(() => {
    if (dataRef.current == null && data != null) {
      dataRef.current = data;
      resetInputs();
    }
    // eslint-disable-next-line
  }, [data]);

  const onOpen = (data) => {
    setClientId(data);
    setIsOpen(true);
    props?.onOpen();
  };
  const onSubmit = () => {
    handleClientPatch(
      { id: clientId },
      { name: nameInput.value, abbreviation: abbreviationInput.value },
      () => {
        refetchClients();
      }
    );
  };
  const onCancel = () => {
    setIsOpen(false);
    resetInputs();
    props?.onClose();
  };
  const onClose = () => {
    setIsOpen(false);
    props?.onClose();
    resetInputs();
  };

  return {
    isOpen,
    onOpen,
    onSubmit,
    onCancel,
    onClose,
    nameInput,
    abbreviationInput,
  };
};

export default useClientCreator;
