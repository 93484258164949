import useActivityCreator from "./hooks/useActivityCreator";
import useLang from "../../hooks/useLang";
import { userActions } from "../../config/accessConfig";
import AccessWrapper from "../auth/AccessWrapper";
import { FormModalControlled } from "../../components/Modal";
import Form from "../../components/Form";
import Input from "../../components/Input";
import Label from "../../components/Label";
import { BundleGroup } from "../../components/Group";
import ChipSelector from "../../components/ChipSelector";
import DropdownSelector from "../../components/DropdownSelector";

const ActivityCreatorControlled = ({
  isOpen,
  onSubmit,
  onCancel,
  onClose,
  title,
  submitLabel,
  startDateInput,
  startTimeInput,
  endDateInput,
  endTimeInput,
  finishSelector,
  durationInput,
  projectsDropdown,
  skillsDropdown,
  employeeDropdown,
  isCreatingNew,
  enabled,
}) => {
  const lang = useLang();
  return (
    <>
      <AccessWrapper action={userActions.CREATE_ACTIVITY}>
        <FormModalControlled
          isOpen={isOpen}
          title={title || lang("new_activity")}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onClose={onClose}
          submitLabel={submitLabel || lang("add_activity")}
          disabled={!enabled}
        >
          <Form modal>
            {isCreatingNew ? (
              <>
                <DropdownSelector
                  label={lang("project")}
                  {...projectsDropdown}
                />
                <DropdownSelector
                  label={lang("employee")}
                  {...employeeDropdown}
                />
                <DropdownSelector label={lang("skill")} {...skillsDropdown} />
              </>
            ) : null}
            <BundleGroup>
              <BundleGroup width={28}>
                <Label>{lang("start")}</Label>
              </BundleGroup>
              <BundleGroup>
                <Input type="date" {...startDateInput.attributes} />
              </BundleGroup>
              <BundleGroup>
                <Input type="time" {...startTimeInput.attributes} />
              </BundleGroup>
            </BundleGroup>
            <BundleGroup>
              <BundleGroup width={28}>
                <Label>{lang("finish")}</Label>
              </BundleGroup>
              <BundleGroup>
                <ChipSelector {...finishSelector} />
              </BundleGroup>
            </BundleGroup>
            <BundleGroup>
              <BundleGroup width={28} />
              {finishSelector?.value?.id === "TIME" ? (
                <>
                  <BundleGroup>
                    <Input type="date" {...endDateInput.attributes} />
                  </BundleGroup>
                  <BundleGroup>
                    <Input type="time" {...endTimeInput.attributes} />
                  </BundleGroup>
                </>
              ) : (
                <BundleGroup>
                  <Input type="number" {...durationInput.attributes} />
                </BundleGroup>
              )}
            </BundleGroup>
          </Form>
        </FormModalControlled>
      </AccessWrapper>
    </>
  );
};

const ActivityCreator = ({ renderOpen }) => {
  const activityCreatorProps = useActivityCreator();
  return (
    <>
      <AccessWrapper action={userActions.CREATE_ACTIVITY}>
        {renderOpen(() => {
          activityCreatorProps.onOpen();
        })}
      </AccessWrapper>
      <ActivityCreatorControlled {...activityCreatorProps} />
    </>
  );
};

export { ActivityCreatorControlled };
export default ActivityCreator;
