import { BundleGroup } from "./Group";
import Paragraph, { SecondaryParagraph } from "./Paragraph";
import localization from "../config/localization";
import useLang from "../hooks/useLang";
import classNames from "classnames";
import styles from "./dynamicTable.module.css";
import Button from "./Button";
import usePolicyChecker from "../features/auth/usePolicyChecker";
import { userActions } from "../config/accessConfig";

const DynamicList = ({ config, data, ...rest }) => {
  const canEdit = usePolicyChecker({ action: userActions.EDIT });
  const lang = useLang();
  const groupEntities = {};
  config?.map((cell) => {
    if (cell.group == null) groupEntities[cell.id] = cell;
    else {
      if (groupEntities[cell.group] == null) groupEntities[cell.group] = [];
      groupEntities[cell.group].push(cell);
    }
    return false;
  });

  const getDataFromSubkeys = (row, entityKey) => {
    const subkeyArray =
      typeof entityKey === "string" ? entityKey?.split(".") : [entityKey];
    let i;
    let data = Array.isArray(row) ? row[0] : row;
    for (i = 0; i < subkeyArray.length; i++) {
      data = data ? data[subkeyArray[i]] : null;
    }
    return data;
  };

  const isDate = (str) => {
    if (str == null) return false;
    if (typeof str !== "string") return false;
    const [date] = str.split(" ");
    const dateObj = new Date(date);
    return !isNaN(dateObj.getDate());
  };

  const getFormattedDataFromSubkeys = (row, entityKey) => {
    const entityConfig = config?.find(
      (configItem) => configItem?.entityKey === entityKey
    );
    const rawValue = getDataFromSubkeys(row, entityKey);
    let roundedValue = rawValue;
    if (typeof rawValue === "number") {
      if (entityConfig?.showPercentage) {
        roundedValue = Math.round(rawValue * 1000) / 10 + "%";
      } else roundedValue = Math.round(rawValue * 100) / 100;
      roundedValue = String(roundedValue).replace(
        ".",
        localization.DECIMAL_SEPARATOR || ","
      );
    } else if (isDate(rawValue)) {
      const [date] = rawValue.split(" ");
      roundedValue = date;
    }
    if (typeof entityConfig?.format === "function")
      return entityConfig?.format(rawValue);
    return roundedValue;
  };

  const getCellData = (row, entityKey) => {
    const data = Array.isArray(entityKey)
      ? entityKey.map((entityKeyItem) => row[entityKeyItem]).join(" ")
      : Array.isArray(getFormattedDataFromSubkeys(row, entityKey))
      ? getFormattedDataFromSubkeys(row, entityKey).join(", ")
      : getFormattedDataFromSubkeys(row, entityKey);
    return data;
  };

  const getCellObj = (data, cell) => {
    let highlight;
    if (cell?.entityKey == null) return null;
    if (typeof cell.highlight === "function") {
      highlight = cell.highlight(
        getDataFromSubkeys(data, cell.entityKey),
        null,
        Array.isArray(data) ? data[0] : data
      );
    }

    return (
      <>
        <BundleGroup width={28}>
          <SecondaryParagraph>
            {cell?.altHeader || cell?.header?.label}
          </SecondaryParagraph>
        </BundleGroup>
        <BundleGroup width={cell.width}>
          <div
            className={classNames({ [styles[highlight]]: highlight != null })}
            style={{ minWidth: "var(--width-12)", minHeight: "var(--width-4)" }}
          >
            <Paragraph>{getCellData(data, cell.entityKey)}</Paragraph>
          </div>
        </BundleGroup>
        {!canEdit ||
        (typeof cell.onClickCondition === "function" &&
          !cell.onClickCondition(
            getDataFromSubkeys(data ? data[0] : data, cell.entityKey),
            rest?.id,
            data ? data[0] : data
          )) ||
        typeof cell?.onClick !== "function" ? null : (
          <Button
            inline
            emphasized
            icon={"pencil"}
            onClick={
              typeof cell?.onClick === "function"
                ? () => {
                    cell.onClick(rest?.id);
                  }
                : () => {}
            }
          />
        )}
      </>
    );
  };

  return (
    <div>
      {Object.keys(groupEntities)?.map((groupKey) => {
        if (
          Array.isArray(groupEntities[groupKey]) &&
          groupEntities[groupKey].length === 1 &&
          groupEntities[groupKey][0]?.id == null &&
          rest?.[groupKey] == null
        ) {
          return null;
        }
        return (
          <BundleGroup key={groupKey}>
            {Array.isArray(groupEntities[groupKey]) ? (
              <>
                {rest?.[groupKey] != null ? (
                  rest?.[groupKey]
                ) : (
                  <div>
                    {groupEntities[groupKey][0].noGroupLabel ? null : (
                      <BundleGroup width={28}>
                        <SecondaryParagraph>
                          {lang(groupKey)}
                        </SecondaryParagraph>
                      </BundleGroup>
                    )}
                    <div
                      style={{
                        display: groupEntities[groupKey][0].noGroupLabel
                          ? "flex"
                          : null,
                      }}
                    >
                      {groupEntities[groupKey].map((cell) => (
                        <BundleGroup key={cell.id}>
                          {cell.noGroupLabel ? null : <BundleGroup width={4} />}
                          {getCellObj(data, cell)}
                          <BundleGroup width={8} />
                        </BundleGroup>
                      ))}
                    </div>
                  </div>
                )}
              </>
            ) : (
              getCellObj(data, groupEntities[groupKey])
            )}
          </BundleGroup>
        );
      })}
    </div>
  );
};

export default DynamicList;
