const getTimeString = (time, withSeconds) => {
  const date = time == null ? new Date() : new Date(time);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const secondsString = `${seconds < 10 ? "0" : ""}${seconds}`;
  return `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}${withSeconds ? ":" + secondsString : ""}`;
};

export default getTimeString;
