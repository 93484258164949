import { useState, useEffect } from "react";
import useLang from "../../../hooks/useLang";
import useInput from "../../../hooks/useInput";
import useDropdownSelector from "../../../hooks/useDropdownSelector";
import useMultiSelector from "../../../hooks/useMultiSelector";
import roles from "../../../config/roles";
import useSkills from "../../skills/hooks/useSkills";
import useOrganizations from "../../organizations/hooks/useOrganizations";
import useAddEmployee from "./useAddEmployee";
// import useEmployeePatch from "./useEmployeePatch";
import useAddSkillToEmployee from "./useAddSkillToEmployee";
import useRemoveSkillFromEmployee from "./useRemoveSkillFromEmployee";
import useAddRoleToEmployee from "./useAddRoleToEmployee";
import useRemoveRoleFromEmployee from "./useRemoveRoleFromEmployee";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../auth/authSlice";

const useEmployeeCreator = (props) => {
  const lang = useLang();
  const { employeeId } = useParams();
  const me = useSelector(selectCurrentUser);
  const skills = useSkills();
  const { data: organizations, status: organizationsStatus } =
    useOrganizations();
  const [isOpen, setIsOpen] = useState(false);
  const firstNameInput = useInput(props?.formData?.given_name || "");
  const lastNameInput = useInput(props?.formData?.family_name || "");
  const emailInput = useInput(props?.formData?.primary_email || "");
  const availableRoles =
    me === employeeId ? roles?.filter((role) => role !== "ADMIN") : roles;
  const rolesSelector = useMultiSelector({
    defaultValue: Array.isArray(props?.formData?.roles)
      ? props?.formData?.roles.map((role) => ({ id: role, label: role }))
      : [],
    options: availableRoles.map((role) => ({ id: role, label: role })),
  });

  const getSkillsWithTranslations = (skills) => {
    return skills?.data?.ids?.map((skillId) => {
      const skill = skills?.data?.entities[skillId] || {};
      return {
        ...skill,
        label: lang(skill.name),
      };
    });
  };
  const skillDropdown = useMultiSelector({
    defaultValue: Array.isArray(props?.formData?.skills)
      ? props?.formData?.skills
      : [],
    options: getSkillsWithTranslations(skills),
  });
  const organizationDropdown = useDropdownSelector({
    defaultValue: { id: null, label: "-" },
    options: organizations?.ids.map((organizationId) => ({
      id: organizationId,
      label: organizations?.entities[organizationId]?.name,
    })),
  });

  const [handleAddEmployee] = useAddEmployee();
  // const [handlePatchEmployee] = useEmployeePatch();
  const [handleAddSkill] = useAddSkillToEmployee();
  const [handleRemoveSkill] = useRemoveSkillFromEmployee();
  const [handleAddRole] = useAddRoleToEmployee();
  const [handleRemoveRole] = useRemoveRoleFromEmployee();

  useEffect(() => {
    if (skills?.status === "fulfilled") {
      skillDropdown.reset(
        getSkillsWithTranslations(skills, props?.formData?.skills)
      );
    }
    // eslint-disable-next-line
  }, [skills?.status]);

  useEffect(() => {
    if (organizationsStatus === "fulfilled") {
      organizationDropdown.reset(
        organizations?.ids.map((organizationId) => ({
          id: organizationId,
          label: organizations?.entities[organizationId]?.name,
        }))
      );
    }
    // eslint-disable-next-line
  }, [organizationsStatus]);

  const resetInputs = () => {
    firstNameInput.reset();
    lastNameInput.reset();
    emailInput.reset();
    rolesSelector.reset(
      availableRoles.map((role) => ({ id: role, label: role }))
    );
    if (skills?.status && skills?.status === "fulfilled") {
      skillDropdown.reset(
        getSkillsWithTranslations(skills, props?.formData?.skills)
      );
    }
    if (organizationsStatus === "fulfilled") {
      organizationDropdown.reset(
        organizations?.ids.map((organizationId) => ({
          id: organizationId,
          label: organizations?.entities[organizationId]?.name,
        }))
      );
    }
  };

  const onOpen = () => {
    setIsOpen(true);
    firstNameInput.setValue(props?.formData?.given_name || "");
    lastNameInput.setValue(props?.formData?.family_name || "");
    emailInput.setValue(props?.formData?.primary_email || "");
    rolesSelector.setValue(
      Array.isArray(props?.formData?.roles)
        ? props?.formData?.roles.map((role) => ({ id: role, label: role }))
        : []
    );
    if (skills?.status && skills?.status === "fulfilled") {
      skillDropdown.reset(
        getSkillsWithTranslations(skills, props?.formData?.skills)
      );
    }
    if (skills?.status && skills?.status === "fulfilled") {
      organizationDropdown.reset(
        organizations?.ids.map((organizationId) => ({
          id: organizationId,
          label: organizations?.entities[organizationId]?.name,
        })),
        props?.formData?.organisation?.id
      );
    }
    if (typeof props?.onOpen === "function") props?.onOpen();
  };

  const handleAdd = () => {
    const body = {
      given_name: firstNameInput.value,
      family_name: lastNameInput.value,
      primary_email: emailInput.value,
      roles: rolesSelector?.value?.map((role) => role.label),
      skills: skillDropdown?.value?.map((skill) => skill.id),
      organisation_id: organizationDropdown?.value?.id,
    };
    handleAddEmployee(null, body);
  };

  const handleEdit = async () => {
    const newRoles = rolesSelector?.value?.filter(
      (newRole) => props?.formData?.roles?.indexOf(newRole.id) < 0
    );
    const rolesToRemove = props?.formData?.roles?.filter((oldRole) => {
      if (rolesSelector?.value?.length < 1) return true;
      return (
        rolesSelector?.value?.findIndex((newRole) => newRole.id === oldRole) < 0
      );
    });
    rolesToRemove?.map((role, index) => {
      setTimeout(() => {
        handleRemoveRole({ id: employeeId, role });
      }, 200 * (index + 1));
      return false;
    });
    const removeRolesLength = Array.isArray(rolesToRemove)
      ? rolesToRemove.length
      : 0;
    newRoles?.map((role, index) => {
      setTimeout(() => {
        handleAddRole({ id: employeeId, role }, { role: role.id });
      }, 200 * (removeRolesLength + index + 1));
      return false;
    });
    const newRolesLength = Array.isArray(newRoles) ? newRoles.length : 0;
    const newSkills = skillDropdown?.value?.filter(
      (newSkill) =>
        props?.formData?.skills?.findIndex((oldSkill) => {
          return oldSkill.id === newSkill.id;
        }) < 0
    );
    const skillsToRemove = props?.formData?.skills?.filter((oldSkill) => {
      if (skillDropdown?.value?.length < 1) return true;
      return (
        skillDropdown?.value?.findIndex(
          (newSkill) => newSkill.id === oldSkill.id
        ) < 0
      );
    });
    skillsToRemove?.map((skill, index) => {
      setTimeout(() => {
        handleRemoveSkill({ id: employeeId, skill: skill.id });
      }, 200 * (removeRolesLength + newRolesLength + index + 1));
      return false;
    });
    const removeSkillsLength = Array.isArray(skillsToRemove)
      ? skillsToRemove.length
      : 0;
    newSkills?.map((skill, index) => {
      setTimeout(() => {
        handleAddSkill({ id: employeeId }, { skill_id: skill.id });
      }, 200 * (removeRolesLength + newRolesLength + removeSkillsLength + index + 1));
      return false;
    });
    /*
    const body = {
      given_name: firstNameInput.value,
      family_name: lastNameInput.value,
      primary_email: emailInput.value,
      organisation_id: organizationDropdown?.value?.id,
    };
    handlePatchEmployee({ id: employeeId }, body);
    */
  };

  const onSubmit = () => {
    if (props?.formData != null) {
      handleEdit();
    } else {
      handleAdd();
    }
  };
  const onCancel = () => {
    setIsOpen(false);
    if (typeof props?.onClose === "function") props?.onClose();
    resetInputs();
  };
  const onClose = () => {
    setIsOpen(false);
    if (typeof props?.onClose === "function")
      props?.onClose({
        firstName: firstNameInput.value,
        lastName: lastNameInput.value,
      });
    resetInputs();
  };

  const formEnabled =
    firstNameInput?.value &&
    lastNameInput?.value &&
    emailInput?.value &&
    emailInput?.isValid;

  return {
    isOpen,
    onOpen,
    onSubmit,
    onCancel,
    onClose,
    firstNameInput,
    lastNameInput,
    emailInput,
    organizationDropdown,
    skillDropdown,
    rolesSelector,
    formEnabled,
    showOrganizationDropdown: props?.formData == null,
    isEditing: props?.formData != null,
  };
};

export default useEmployeeCreator;
