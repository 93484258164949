import classNames from "classnames";
import styles from "./tabs.module.css";
import { NavLink } from "react-router-dom";
import useSearchParams from "../hooks/useSearchParams";
import { CountChip } from "./Chip";

const Tabs = ({ children, selected, onSelectTab }) => {
  const createId = (child) => {
    let result;
    if (typeof child === "string") {
      result = child;
    } else {
      if (child.props?.children) {
        if (Array.isArray(child.props?.children)) {
          result = createId(child.props?.children[0]);
        } else {
          result = String(child.props?.children);
        }
      }
    }
    return result.slice(-10);
  };

  return (
    <div className={styles.tabs}>
      {children?.map((tabContent, index) => (
        <div
          key={`${index}-${createId(tabContent)}`}
          className={classNames(styles.tab, {
            [styles.selected]: index === selected,
          })}
          onClick={() => {
            if (typeof onSelectTab === "function") onSelectTab(index);
          }}
        >
          {tabContent}
        </div>
      ))}
    </div>
  );
};

const LinkTabs = ({ tabs }) => {
  return (
    <div className={styles.tabs}>
      {tabs?.map((tab) => (
        <NavLink
          key={tab.key}
          to={tab.to}
          className={({ isActive }) =>
            classNames(styles.tab, { [styles.selected]: isActive })
          }
        >
          {tab.label}
        </NavLink>
      ))}
    </div>
  );
};

const ParamLinkTabs = ({ tabs, children }) => {
  const { getParam, getLinkWithParamOnly } = useSearchParams();
  const selected = getParam(tabs[0].param);
  return (
    <div className={styles.tabs}>
      {tabs?.map((tab) => (
        <NavLink
          key={tab.key}
          to={
            tab.to != null ? `?${getLinkWithParamOnly(tab.param, tab.to)}` : ""
          }
          className={classNames(styles.tab, {
            [styles.selected]: selected === tab.to,
          })}
        >
          {tab.label}
          {tab.count ? <CountChip unseen>{tab.count}</CountChip> : null}
        </NavLink>
      ))}
      {children}
    </div>
  );
};

export { LinkTabs, ParamLinkTabs };
export default Tabs;
