import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiAuthenticatedSlice";

const skillsAdapter = createEntityAdapter();
const initialState = skillsAdapter.getInitialState();

export const skillsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSkills: builder.query({
      query: () => `/skills`,
      transformResponse: (response) => {
        return skillsAdapter.setAll(initialState, response);
      },
      providesTags: [{ type: "skill" }],
    }),
  }),
});

export const { useGetSkillsQuery } = skillsSlice;
