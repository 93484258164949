import useClients from "./useClients";

const useClient = (clientId) => {
  const clients = useClients();

  let dataFromClients;
  if (clientId && clients?.data?.entities[clientId]) {
    dataFromClients = clients?.data?.entities[clientId];
  }

  return { ...clients, data: dataFromClients };
};

export default useClient;
