import { useState, useRef, useEffect } from "react";
import useEmployee from "./useEmployee";
import useEmployees from "./useEmployees";
import useInput from "../../../hooks/useInput";
import useEmployeePatch from "./useEmployeePatch";

const useStatusModifier = () => {
  const [isOpen, setIsOpen] = useState();
  const [employeeId, _setEmployeeId] = useState();
  const setEmployeeId = (data) => {
    dataRef.current = undefined;
    _setEmployeeId((state) => (state !== data ? data : state));
  };
  const [handleEmployeePatch] = useEmployeePatch();

  const { data, refetch: refetchEmployee } = useEmployee(employeeId);
  const { refetch: refetchEmployees } = useEmployees();
  const dataRef = useRef();
  const statusInput = useInput();

  const resetStatus = () => {
    const status = data?.status === "ACTIVE";
    statusInput.setValue((state) => (status !== state ? status : state));
  };

  useEffect(() => {
    if (dataRef.current == null && data != null) {
      dataRef.current = data;
      resetStatus();
    }
    // eslint-disable-next-line
  }, [data]);

  const resetInputs = () => {
    setEmployeeId();
    resetStatus();
  };

  const onOpen = (employeeId) => {
    setEmployeeId(employeeId);
    setIsOpen(true);
  };
  const onSubmit = () => {
    handleEmployeePatch(
      { id: employeeId },
      { status: statusInput.value === true ? "ACTIVE" : "INACTIVE" },
      () => {
        refetchEmployee();
        refetchEmployees();
      }
    );
  };
  const onCancel = () => {
    setIsOpen(false);
    resetInputs();
  };
  const onClose = () => {
    setIsOpen(false);
    resetInputs();
  };

  return {
    isOpen,
    onOpen,
    onSubmit,
    onCancel,
    onClose,
    statusInput,
  };
};

export default useStatusModifier;

/*
const useCompletionModifier = () => {
  const [isOpen, setIsOpen] = useState();
  const [projectId, setProjectId] = useState();
  const { data } = useProject(projectId);
  const dataRef = useRef();
  const completionInput = useInput();

  const resetCompletion = () => {
    const completion = parseInt(data?.completion) || 0;
    completionInput.setValue((state) =>
      completion !== state ? completion : state
    );
  };

  useEffect(() => {
    if (dataRef.current == null && data != null) {
      dataRef.current = data;
      resetCompletion();
    }
    // eslint-disable-next-line
  }, [data]);

  const resetInputs = () => {
    setProjectId();
    resetCompletion();
  };

  const onOpen = (projectId) => {
    setProjectId(projectId);
    setIsOpen(true);
  };
  const onSubmit = () => {};
  const onCancel = () => {
    setIsOpen(false);
    resetInputs();
  };
  const onClose = () => {
    setIsOpen(false);
    resetInputs();
  };

  return {
    isOpen,
    onOpen,
    onSubmit,
    onCancel,
    onClose,
    projectId,
    completionInput,
  };
*/
