import { useRef, useEffect } from "react";
import useFilterParam from "./useFilterParam";
import useInput from "./useInput";

const useInputFilter = (paramName) => {
  const paramsRead = useRef(false);
  const filter = useFilterParam(paramName);
  const input = useInput();

  useEffect(() => {
    if (paramsRead.current && input?.value !== filter?.value) {
      filter.setFilterValue(input?.value);
    }
    // eslint-disable-next-line
  }, [input?.value]);

  useEffect(() => {
    paramsRead.current = true;
    if (filter?.value == null) {
      input.reset();
    } else if (input?.value !== filter?.value) {
      input.setValue(filter?.value);
    }
    // eslint-disable-next-line
  }, [filter?.value]);

  return { input };
};

export default useInputFilter;
