import { useGetOffersQuery } from "../offersSlice";
import usePolicyChecker from "../../auth/usePolicyChecker";
import { userActions } from "../../../config/accessConfig";
import paramConfig from "../../../config/paramConfig";
import { pathnames } from "../../../config/routes";
import useFetchQuery from "../../../hooks/useFetchQuery";

const queryParams = {
  [paramConfig.PAGE_SIZE]: paramConfig.DEFAULT_PAGE_SIZE,
  [paramConfig.PAGE_NUMBER]: paramConfig.DEFAULT_PAGE_NUMBER,
  status: "ACTIVE",
  sort_by: "desc(date_created)",
  organisation_id: null,
  customer_id: null,
};

const useOffers = () => {
  const grantAccess = usePolicyChecker({
    action: userActions.GET_OFFERS,
  });
  const paramString = useFetchQuery(queryParams, pathnames.OFFERS);
  const offers = useGetOffersQuery(
    { query: paramString ? `?${paramString}` : "" },
    { skip: !grantAccess }
  );

  return { ...offers, refetch: grantAccess ? offers.refetch : () => {} };
};

export default useOffers;
