import useLang from "../../hooks/useLang";
import useOrganizationList from "./hooks/useOrganizationList";
import { PageHeader } from "../../components/Header";
import {
  PageSubheaderGroup,
  SplitGroup,
  BundleGroup,
} from "../../components/Group";
import DynamicTable from "../../components/DynamicTable";
import OrganizationCreator from "./OrganizationCreator";
import Button from "../../components/Button";

const OrganizationList = () => {
  const lang = useLang();
  const { tableAttribs, subcontractorTableAttribs } = useOrganizationList();
  return (
    <>
      <PageHeader>
        <h1>{lang("organizations")}</h1>
      </PageHeader>
      <PageSubheaderGroup>
        <SplitGroup />
        <OrganizationCreator
          loader={
            <Button important loader>
              {lang("add_organization")}
            </Button>
          }
          renderOpen={(onClick) => (
            <Button important onClick={onClick}>
              {lang("add_organization")}
            </Button>
          )}
        />
      </PageSubheaderGroup>
      <BundleGroup style={{ alignItems: "flex-start" }}>
        <BundleGroup>
          <DynamicTable {...tableAttribs} />
        </BundleGroup>
        <BundleGroup width={8} />
        <BundleGroup>
          <DynamicTable {...subcontractorTableAttribs} />
        </BundleGroup>
      </BundleGroup>
    </>
  );
};

export default OrganizationList;
