const ALLOW_ADMIN = "allowAdmin";
const ALLOW_ALL = "allowAll";
const ALLOW_MANAGEMENT = "allowManagement";
const ALLOW_ACCOUNTING = "allowAccounting";
const ALLOW_SUPERVISORS = "allowSupervisors";
const ALLOW_SUPERVISORS_ONLY = "allowSupervisorsOnly";
const ALLOW_WORKERS_ONLY = "allowWorkersOnly";

const userActions = {
  ADD: ALLOW_SUPERVISORS,
  EDIT: ALLOW_SUPERVISORS,
  GET_OFFERS: ALLOW_ACCOUNTING,
  CREATE_OFFER: ALLOW_ADMIN,
  PATCH_OFFER: ALLOW_ADMIN,
  SHOW_PROJECTS: ALLOW_ACCOUNTING,
  GET_PROJECTS: ALLOW_ALL,
  CREATE_PROJECT: ALLOW_ADMIN,
  PATCH_PROJECT: ALLOW_ADMIN,
  CREATE_INVOICE: ALLOW_ADMIN,
  SHOW_EMPLOYEES: ALLOW_ACCOUNTING,
  GET_EMPLOYEES: ALLOW_MANAGEMENT,
  GET_ORGANIZATIONS: ALLOW_ACCOUNTING,
  CREATE_ORGANIZATION: ALLOW_ADMIN,
  GET_CLIENTS: ALLOW_ACCOUNTING,
  CREATE_CLIENT: ALLOW_ADMIN,
  GET_TIMESHEETS: ALLOW_ACCOUNTING,
  SHOW_TIMELOGS: ALLOW_MANAGEMENT,
  GET_TIMELOGS: ALLOW_MANAGEMENT,
  GET_MY_ACTIVITIES: ALLOW_WORKERS_ONLY,
  CHECK_IN: ALLOW_WORKERS_ONLY,
  CHECK_OUT: ALLOW_WORKERS_ONLY,
  CREATE_ACTIVITY: ALLOW_SUPERVISORS_ONLY,
  PATCH_ACTIVITY: ALLOW_SUPERVISORS_ONLY,
};

const localPolicies = {
  allowAdmin: ["ADMIN"],
  allowAll: ["WORKER", "SUPERVISOR", "ACCOUNTANT", "ADMIN"],
  allowManagement: ["SUPERVISOR", "ACCOUNTANT", "ADMIN"],
  allowAccounting: ["ACCOUNTANT", "ADMIN"],
  allowSupervisors: ["SUPERVISOR", "ADMIN"],
  allowSupervisorsOnly: ["SUPERVISOR"],
  allowWorkersOnly: ["WORKER"],
};

export { userActions, localPolicies };
