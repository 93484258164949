import { useGetTimesheetsQuery } from "../timesheetsSlice";
import usePolicyChecker from "../../auth/usePolicyChecker";
import { userActions } from "../../../config/accessConfig";

const useTimesheets = (year) => {
  const grantAccess = usePolicyChecker({
    action: userActions.GET_TIMESHEETS,
  });
  const skip = year == null || !grantAccess;
  const timesheets = useGetTimesheetsQuery({ year }, { skip });
  return { ...timesheets, refetch: !skip ? timesheets.refetch : () => {} };
};

export default useTimesheets;
