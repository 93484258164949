import { useParams } from "react-router-dom";
import useProject from "./useProject";
import useOrganizations from "../../organizations/hooks/useOrganizations";
import useClients from "../../clients/hooks/useClients";
import useProjectStatusChanger from "./useProjectStatusChanger";
import useCompletionModifier from "./useCompletionModifier";
import useInput from "../../../hooks/useInput";
import useModifierModal from "../../../hooks/useModifierModal";
import usePatchProject from "./usePatchProject";
import useProjectTableConfig from "./useProjectTableConfig";
import useSimpleDate from "../../../hooks/useSimpleDate";
import useDropdownSelector from "../../../hooks/useDropdownSelector";
import useSkills from "../../skills/hooks/useSkills";

const useProjectPage = () => {
  const { getSimpleDate } = useSimpleDate();
  const { projectId } = useParams();
  const organizations = useOrganizations();
  const clients = useClients();
  const { data } = useProject(projectId);
  const completionDialogAttribs = useCompletionModifier();
  const projectStatusChangerAttribs = useProjectStatusChanger();
  const skills = useSkills();

  const scopeInput = useInput();
  const scopeModifierAttribs = useModifierModal({
    inputs: [{ input: scopeInput, key: "approved_scope" }],
    query: useProject,
    mutation: usePatchProject,
  });

  const onEditCompletion = () => {
    completionDialogAttribs.onOpen(projectId, "scope");
  };

  const onEditHourlyCompletion = () => {
    completionDialogAttribs.onOpen(projectId, "hours");
  };

  const onEditStatus = () => {
    projectStatusChangerAttribs.onOpen(projectId);
  };
  const amountInput = useInput();
  const amountModifierAttribs = useModifierModal({
    inputs: [{ input: amountInput, key: "total_amount" }],
    query: useProject,
    mutation: usePatchProject,
  });
  const billingDropdown = useDropdownSelector({
    options: [
      { id: "SCOPE", label: "SCOPE" },
      { id: "HOURLY", label: "HOURLY" },
      { id: "MIXED", label: "MIXED" },
    ],
    defaultValue: { id: null },
  });
  const hourlyInput = useInput();
  const hourlyModifierAttribs = useModifierModal({
    inputs: [{ input: hourlyInput, key: "approved_hourly" }],
    query: useProject,
    mutation: usePatchProject,
  });
  const warrantyInput = useInput();
  const warrantyModifierAttribs = useModifierModal({
    inputs: [{ input: warrantyInput, key: "approved_warranty" }],
    query: useProject,
    mutation: usePatchProject,
  });
  const billingModifierAttribs = useModifierModal({
    inputs: [
      { input: billingDropdown, key: "billing" },
      { input: scopeInput, key: "approved_scope" },
      { input: hourlyInput, key: "approved_hourly" },
    ],
    query: useProject,
    mutation: usePatchProject,
  });

  const getSkipGroups = (data) => {
    let skipGroups = ["invoices"];
    if (data?.status !== "WARRANTY") skipGroups.push("warranty");
    if (data?.billing === "SCOPE") skipGroups.push("hourly");
    if (data?.billing === "HOURLY") skipGroups.push("scope");
    return skipGroups;
  };

  const listConfig = useProjectTableConfig({
    handleStatusClick: onEditStatus,
    handleScopeClick: scopeModifierAttribs.onOpen,
    handleProgressClick: onEditCompletion,
    handleWarrantyClick: warrantyModifierAttribs.onOpen,
    handleWarrantyProgressClick: completionDialogAttribs.onOpen,
    handleHourlyClick: hourlyModifierAttribs.onOpen,
    handleHourlyProgressClick: onEditHourlyCompletion,
    handleAmountClick: amountModifierAttribs.onOpen,
    handleBillingClick: billingModifierAttribs.onOpen,
    hideSorting: true,
    skipFields: [
      "customer",
      "organisation",
      "full_no",
      "name",
      "qty",
      "uom",
      "total_amount",
      "remaining_amount",
      "invoiced_amount",
    ],
    skipGroups: getSkipGroups(data),
  });

  const amountListConfig = useProjectTableConfig({
    handleAmountClick: amountModifierAttribs.onOpen,
    hideSorting: true,
    only: ["total_amount", "remaining_amount", "invoiced_amount", "invoices"],
  });

  let fullData;
  if (data != null) {
    fullData = {
      ...data,
      residual_amount:
        data?.total_amount && data?.invoiced_amount
          ? data.total_amount - data.invoiced_amount
          : null,
      customer: clients?.data
        ? clients?.data?.entities[data.customer_id]?.name
        : "...",
      customer_full: clients?.data
        ? clients?.data?.entities[data.customer_id]
        : null,
      organisation: organizations?.data
        ? organizations?.data?.entities[data.organisation_id]?.name
        : "...",
      organisation_full: clients?.data
        ? organizations?.data?.entities[data.organisation_id]
        : null,
    };
  }

  return {
    projectId,
    modified:
      fullData?.date_modified != null
        ? getSimpleDate(fullData?.date_modified)
        : null,
    project: fullData,
    completionDialogAttribs,
    projectStatusChangerAttribs,
    onEditStatus,
    onEditCompletion,
    onEditHourlyCompletion,
    scopeInput,
    scopeModifierAttribs,
    amountInput,
    amountModifierAttribs,
    warrantyInput,
    warrantyModifierAttribs,
    billingDropdown,
    hourlyInput,
    hourlyModifierAttribs,
    billingModifierAttribs,
    listAttribs: {
      config: listConfig,
      data: fullData ? [fullData] : undefined,
    },
    amountListAttribs: {
      config: amountListConfig,
      data: fullData ? [fullData] : undefined,
    },
    skills: skills?.data?.entities || {},
  };
};

export default useProjectPage;