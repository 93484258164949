import useLang from "../../hooks/useLang";
import { PageHeader } from "../../components/Header";
import useClientList from "./hooks/useClientList";
import { PageSubheaderGroup, SplitGroup } from "../../components/Group";
import Button from "../../components/Button";
import ClientCreator, { ClientCreatorControlled } from "./ClientCreator";
import DynamicTable from "../../components/DynamicTable";

const ClientList = () => {
  const lang = useLang();
  const { tableAttribs, clientCreatorAttribs } = useClientList();

  return (
    <>
      <PageHeader>
        <h1>{lang("clients")}</h1>
      </PageHeader>
      <PageSubheaderGroup>
        <SplitGroup />
        <ClientCreator
          loader={
            <Button important loader>
              {lang("add_client")}
            </Button>
          }
          renderOpen={(onClick) => (
            <Button important onClick={onClick}>
              {lang("add_client")}
            </Button>
          )}
        />
      </PageSubheaderGroup>
      <DynamicTable {...tableAttribs} />
      <ClientCreatorControlled {...clientCreatorAttribs} />
    </>
  );
};

export default ClientList;
