import { PageHeader } from "../../components/Header";
import Form from "../../components/Form";
import Button from "../../components/Button";
import DropdownSelector from "../../components/DropdownSelector";
import CheckOutDialog from "./ChekOutDialog";
import { BundleGroup } from "../../components/Group";
import Paragraph, { SecondaryParagraph } from "../../components/Paragraph";
import Loader from "../../components/Loader";
import useLang from "../../hooks/useLang";
import useCheckIn from "./hooks/useCheckIn";
import { PageSubheaderGroup } from "../../components/Group";

const CheckIn = () => {
  const lang = useLang();
  const {
    projectDropdown,
    skillDropdown,
    checkinStartEnabled,
    allowStart,
    onStart,
    allowFinish,
    isLoading,
    myCurrentActivity,
    myProfile,
    jobDescription,
  } = useCheckIn();

  return (
    <>
      <PageHeader>
        <h1>
          {allowStart
            ? lang("start_task")
            : allowFinish
            ? lang("finish_task")
            : lang("my_activity")}
        </h1>
      </PageHeader>
      <PageSubheaderGroup>
        <h2>{`${myProfile?.given_name} ${myProfile?.family_name}`}</h2>
      </PageSubheaderGroup>
      {allowFinish ? (
        <>
          <BundleGroup>
            <BundleGroup width={28}>
              <SecondaryParagraph>{lang("project")}</SecondaryParagraph>
            </BundleGroup>
            <BundleGroup>
              <Paragraph>{`${myCurrentActivity?.project?.full_no} / ${myCurrentActivity?.project?.name}`}</Paragraph>
            </BundleGroup>
          </BundleGroup>
          <BundleGroup>
            <BundleGroup width={28}>
              <SecondaryParagraph>{lang("job")}</SecondaryParagraph>
            </BundleGroup>
            <BundleGroup>
              <Paragraph>{jobDescription}</Paragraph>
            </BundleGroup>
          </BundleGroup>
          <BundleGroup>
            <BundleGroup width={28}>
              <SecondaryParagraph>{lang("started")}</SecondaryParagraph>
            </BundleGroup>
            <BundleGroup>
              <Paragraph>{`${myCurrentActivity?.start} (${myCurrentActivity?.date})`}</Paragraph>
            </BundleGroup>
          </BundleGroup>
          {myCurrentActivity?.duration ? (
            <BundleGroup>
              <BundleGroup width={28}>
                <SecondaryParagraph>{lang("duration")}</SecondaryParagraph>
              </BundleGroup>
              <BundleGroup>
                <Paragraph>{myCurrentActivity?.duration}</Paragraph>
              </BundleGroup>
            </BundleGroup>
          ) : null}
          <div style={{ height: "var(--spacing-8)" }} />
        </>
      ) : null}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {allowStart ? (
            <>
              <Form>
                <BundleGroup width={96}>
                  <DropdownSelector
                    label={lang("project")}
                    {...projectDropdown}
                  />
                </BundleGroup>
                <BundleGroup width={96}>
                  <DropdownSelector label={lang("skill")} {...skillDropdown} />
                </BundleGroup>
              </Form>
              <div style={{ height: "var(--spacing-8)" }} />
              {checkinStartEnabled ? (
                <Button important onClick={onStart}>
                  {lang("start")}
                </Button>
              ) : null}
            </>
          ) : null}
          {allowFinish ? (
            <>
              <CheckOutDialog
                loader={
                  <Button important loader>
                    {lang("finish")}
                  </Button>
                }
                renderOpen={(onClick) => (
                  <Button important onClick={onClick}>
                    {lang("finish")}
                  </Button>
                )}
              />
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default CheckIn;
