import { useGetTimelogsQuery } from "../timelogsSlice";
import usePolicyChecker from "../../auth/usePolicyChecker";
import { userActions } from "../../../config/accessConfig";
import paramConfig from "../../../config/paramConfig";
import { pathnames } from "../../../config/routes";
import useFetchQuery from "../../../hooks/useFetchQuery";

const queryParams = {
  [paramConfig.PAGE_SIZE]: paramConfig.DEFAULT_PAGE_SIZE,
  [paramConfig.PAGE_NUMBER]: paramConfig.DEFAULT_PAGE_NUMBER,
  sort_by: "desc(date)",
  verified: null,
  from: null,
  to: null,
  user_id: null,
};

const useTimelogs = (props) => {
  const grantAccess = usePolicyChecker({
    action: userActions.GET_TIMELOGS,
  });
  const paramString = useFetchQuery(queryParams, pathnames.TIMELOGS, props);
  const timelogs = useGetTimelogsQuery(
    { query: paramString ? `?${paramString}` : "" },
    { skip: !grantAccess }
  );

  return { ...timelogs, refetch: grantAccess ? timelogs.refetch : () => {} };
};

export default useTimelogs;
