import { useGetProjectQuery } from "../projectsSlice";
import { userActions } from "../../../config/accessConfig";
import usePolicyChecker from "../../auth/usePolicyChecker";

const useProject = (projectId) => {
  const grantAccess = usePolicyChecker({
    action: userActions.GET_ORGANIZATIONS,
  });

  const { data: projectData, ...rest } = useGetProjectQuery(
    { id: projectId },
    { skip: projectId == null || !grantAccess }
  );

  let project = { ...rest };
  if (projectData != null) {
    project.data = {
      ...projectData,
      approved_scope: projectData?.scope?.approved,
      approved_hourly: projectData?.hourly?.approved,
      approved_warranty: projectData?.warranty?.approved,
    };
  }

  return {
    ...project,
    refetch: grantAccess ? project.refetch : () => {},
  };
};

export default useProject;
