import { BundleGroup, SplitGroup } from "./Group";
import usePaging from "../hooks/usePaging";
import Button from "./Button";
import Label from "./Label";
import Input from "./Input";
import useLang from "../hooks/useLang";

const Paging = ({ totalPages, withPageSize, children }) => {
  const {
    pageNumbers,
    firstPages,
    activePages,
    lastPages,
    firstSpace,
    lastSpace,
    activePage,
    onClick,
    pageSizeInput,
    onPageSizeKeyDown,
    onPageSizeClick,
  } = usePaging(totalPages, withPageSize);
  const lang = useLang();

  if (totalPages == null && !withPageSize)
    return <div style={{ height: "var(--spacing-4)" }} />;
  if (totalPages <= 1 && !withPageSize)
    return <div style={{ height: "var(--spacing-4)" }} />;

  return (
    <>
      <div style={{ height: "var(--spacing-4)" }} />

      <BundleGroup>
        {totalPages != null && totalPages > 1 ? (
          <BundleGroup>
            <Button
              key={"FIRST"}
              onClick={() => {
                onClick(1);
              }}
              icon={"collapseLeft"}
            />
            <Button
              key={"PREVIOUS"}
              onClick={() => {
                onClick(Math.max(parseInt(activePage) - 1, 1));
              }}
              icon={"chevronLeft"}
            />
            {firstPages?.map((pageNumber) => {
              return (
                <Button
                  inline
                  important={pageNumber === parseInt(activePage)}
                  key={pageNumber}
                  onClick={() => {
                    onClick(pageNumber);
                  }}
                >
                  {pageNumber}
                </Button>
              );
            })}
            {firstSpace ? "..." : null}
            {activePages?.map((pageNumber) => {
              return (
                <Button
                  inline
                  important={pageNumber === parseInt(activePage)}
                  key={pageNumber}
                  onClick={() => {
                    onClick(pageNumber);
                  }}
                >
                  {pageNumber}
                </Button>
              );
            })}
            {lastSpace ? "..." : null}
            {lastPages?.map((pageNumber) => {
              return (
                <Button
                  inline
                  important={pageNumber === parseInt(activePage)}
                  key={pageNumber}
                  onClick={() => {
                    onClick(pageNumber);
                  }}
                >
                  {pageNumber}
                </Button>
              );
            })}
            <Button
              key={"Next"}
              onClick={() => {
                onClick(Math.min(parseInt(activePage) + 1, pageNumbers.length));
              }}
              icon={"chevronRight"}
            />
            <Button
              key={"LAST"}
              onClick={() => {
                onClick(pageNumbers.length);
              }}
              icon={"collapseRight"}
            />
            <BundleGroup width={4} />
          </BundleGroup>
        ) : null}
        {withPageSize ? (
          <>
            <Label>{lang("page_size")}</Label>
            <BundleGroup width={32}>
              <Input
                type="number"
                {...pageSizeInput.attributes}
                onKeyDown={onPageSizeKeyDown}
              />
            </BundleGroup>
            <Button inline emphasized onClick={onPageSizeClick}>
              {lang("set")}
            </Button>
            <BundleGroup width={4} />
          </>
        ) : null}
        <SplitGroup />
        {children}
      </BundleGroup>
      <div style={{ height: "var(--spacing-4)" }} />
    </>
  );
};

export default Paging;
