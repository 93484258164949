import useEmployees from "./useEmployees";

const useEmployee = (employeeId) => {
  const employees = useEmployees();

  let dataFromEmployees;
  if (employeeId && employees?.data?.entities[employeeId]) {
    dataFromEmployees = employees?.data?.entities[employeeId];
  }

  return { ...employees, data: dataFromEmployees };
};

export default useEmployee;
