import Popup from "./Popup";
import Icon from "./Icon";
import classNames from "classnames";
import Label from "./Label";
import styles from "./dropdownSelector.module.css";

const DropdownSelector = ({
  options,
  value,
  defaultValue,
  onChange,
  label,
  noEmpty,
}) => {
  const safeDefault = {};
  safeDefault.id = defaultValue?.id || null;
  safeDefault.label = defaultValue?.label || "-";
  const selectedValue = value || safeDefault;
  const selectorOptions = !Array.isArray(options)
    ? [safeDefault]
    : noEmpty
    ? options
    : [safeDefault, ...options];
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
      }}
    >
      {label ? <Label className={styles.dropdownLabel}>{label}</Label> : null}
      <Popup
        dropdown
        expandBottomRight
        renderOpen={(onClick) => (
          <div
            onClick={onClick}
            className={styles.dropdownSelector}
            style={{
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <p
              style={{
                whiteSpace: "noWrap",
                overflow: "hidden",
                width: "100%",
                textOverflow: "ellipsis",
              }}
            >
              {selectedValue?.label || "-"}
            </p>
            <Icon chevronDown />
          </div>
        )}
      >
        {selectorOptions.map((option) => (
          <div
            key={option.id}
            onClick={() => {
              onChange(option);
            }}
            className={classNames(styles.selectorOption, {
              [styles.selected]: option.id === selectedValue?.id,
            })}
          >
            {option.label}
          </div>
        ))}
      </Popup>
    </div>
  );
};

export default DropdownSelector;
