import useOfferCreatorMultimodal from "./hooks/useOfferCreatorMultimodal";
import OfferCreator from "./OfferCreator";
import { ClientCreatorControlled } from "../clients/ClientCreator";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const OfferCreatorMultimodal = ({ renderOpen }) => {
  const { offerCreatorProps, clientCreatorProps } = useOfferCreatorMultimodal();

  return (
    <>
      <AccessWrapper action={userActions.CREATE_OFFER}>
        {renderOpen(() => {
          offerCreatorProps.onOpen();
        })}
      </AccessWrapper>
      <OfferCreator
        {...offerCreatorProps}
        clientCreatorOpen={clientCreatorProps.onOpen}
      />
      <ClientCreatorControlled {...clientCreatorProps} />
    </>
  );
};

export default OfferCreatorMultimodal;
