import { localPolicies } from "../../config/accessConfig";
import useMyProfile from "./useMyProfile";

const usePolicyChecker = ({ action }) => {
  const authorizedRoles = action ? localPolicies[action] : [];
  const { roles } = useMyProfile();
  let authorized = false;
  authorizedRoles?.map((authorizedRole) => {
    if (Array.isArray(roles)) {
      if (roles.indexOf(authorizedRole) > -1) authorized = true;
    }
    return false;
  });

  return authorized;
};

export default usePolicyChecker;
