import { createSlice } from "@reduxjs/toolkit";
import companies from "../../config/companies";
import skills from "../../config/skills";

const employeeMockSlice = createSlice({
  name: "employeeMock",
  initialState: [
    {
      firstName: "Jonas",
      lastName: "Urbšys",
      skill: skills[0],
      skillLabel: skills[0].label,
      company: companies[0],
      companyLabel: companies[0].label,
      status: "ACTIVE",
    },
    {
      firstName: "Andrius",
      lastName: "Gaubas",
      skill: skills[1],
      skillLabel: skills[1].label,
      company: companies[0],
      companyLabel: companies[0].label,
      status: "INACTIVE",
    },
    {
      firstName: "Jurgis",
      lastName: "Andriulis",
      skill: skills[2],
      skillLabel: skills[2].label,
      company: companies[1],
      companyLabel: companies[1].label,
      status: "ACTIVE",
    },
  ],
  reducers: {
    setIds: (state, action) => {
      const ids = action.payload;
      ids?.map((id, idx) => {
        state[idx].id = id;
        return false;
      });
    },
    patchEmployee: (state, action) => {
      const { params, body } = action.payload;
      const idx = state.findIndex((entity) => entity.id === params.id);
      if (idx > -1) state[idx] = { ...state[idx], ...body };
    },
  },
});

export const { setIds, patchEmployee } = employeeMockSlice.actions;
export default employeeMockSlice.reducer;
export const selectEmployees = (state) => state.employeeMock;
