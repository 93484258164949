import { forwardRef } from "react";
import classNames from "classnames";
import SortButton from "./SortButton";
import usePolicyChecker from "../features/auth/usePolicyChecker";
import styles from "./dynamicTable.module.css";
import { userActions } from "../config/accessConfig";
import getFloatString from "../utils/getFloatString";
import getPercentString from "../utils/getPercentString";

const DynamicTable = forwardRef(
  ({ config, data, onRowClick, onRowClickCondition, ...rest }, tableRef) => {
    const canEdit = usePolicyChecker({ action: userActions.EDIT });
    const getDataFromSubkeys = (row, entityKey) => {
      const subkeyArray =
        typeof entityKey === "string" ? entityKey?.split(".") : [entityKey];
      let i;
      let data = row;
      for (i = 0; i < subkeyArray.length; i++) {
        data = data ? data[subkeyArray[i]] : null;
      }
      return data;
    };

    const isDate = (str) => {
      if (str == null) return false;
      if (typeof str !== "string") return false;
      const [date] = str.split(" ");
      const dateObj = new Date(date);
      return !isNaN(dateObj.getDate());
    };

    const getFormattedDataFromSubkeys = (row, entityKey) => {
      const entityConfig = config?.find(
        (configItem) => configItem?.entityKey === entityKey
      );
      const rawValue = getDataFromSubkeys(row, entityKey);
      let roundedValue = rawValue;
      if (typeof rawValue === "number") {
        if (entityConfig?.showPercentage) {
          roundedValue = getPercentString(rawValue);
        } else roundedValue = getFloatString(rawValue);
      } else if (isDate(rawValue)) {
        const [date] = rawValue.split(" ");
        roundedValue = date;
      }
      if (typeof entityConfig?.format === "function")
        return entityConfig?.format(rawValue);
      return roundedValue;
    };

    const colsWithTotals = config?.filter((col) => col.showTotal);
    let totals = {};
    colsWithTotals?.map((col) => {
      data?.map((row) => {
        if (getDataFromSubkeys(row, col.entityKey)) {
          if (totals[col.entityKey] == null) totals[col.entityKey] = 0;
          totals[col.entityKey] =
            totals[col.entityKey] +
            parseFloat(getDataFromSubkeys(row, col.entityKey));
        }
        return false;
      });
      return false;
    });

    const getCellData = (row, entityKey) => {
      return Array.isArray(entityKey)
        ? entityKey.map((entityKeyItem) => row[entityKeyItem]).join(" ")
        : Array.isArray(getFormattedDataFromSubkeys(row, entityKey))
        ? getFormattedDataFromSubkeys(row, entityKey).join(", ")
        : getFormattedDataFromSubkeys(row, entityKey);
    };

    return (
      <div style={{ width: "100%", overflow: "auto" }}>
        <table ref={tableRef} className={styles.dynamicTable}>
          <tbody>
            <tr>
              {config?.map((cell) => {
                if (cell?.id == null) return null;
                return (
                  <th
                    className={classNames(styles.cell, styles.header)}
                    style={{
                      width: `var(--width-${cell.width})`,
                      minWidth: `var(--width-${cell.width})`,
                      maxWidth: `var(--width-${cell.width})`,
                      display: cell?.hidden ? "none" : null,
                    }}
                    key={cell.id}
                  >
                    {cell.sort ? (
                      <SortButton {...cell.header} />
                    ) : (
                      cell?.header?.label
                    )}
                  </th>
                );
              })}
            </tr>
            {Object.keys(totals).length > 0 ? (
              <tr>
                {config?.map((cell) => {
                  if (cell?.id == null) return null;
                  return (
                    <th
                      className={classNames(styles.cell, styles.header)}
                      style={{
                        width: `var(--width-${cell.width})`,
                        minWidth: `var(--width-${cell.width})`,
                        maxWidth: `var(--width-${cell.width})`,
                      }}
                      key={cell.id}
                    >
                      {totals[cell.entityKey] ? totals[cell.entityKey] : null}
                    </th>
                  );
                })}
              </tr>
            ) : null}
            {data?.map((row) => (
              <tr
                key={row.id}
                className={classNames(styles.row, {
                  [styles.hoverable]:
                    canEdit &&
                    onRowClick != null &&
                    ((typeof onRowClickCondition === "function" &&
                      onRowClickCondition(row.id, row)) ||
                      onRowClickCondition == null),
                })}
                onClick={() => {
                  if (!canEdit) return false;
                  if (typeof onRowClick === "function") onRowClick(row.id, row);
                }}
              >
                {config?.map((cell) => {
                  let highlight;
                  if (cell?.entityKey == null) return null;
                  if (typeof cell.highlight === "function") {
                    highlight = cell.highlight(
                      getDataFromSubkeys(row, cell.entityKey),
                      row.id,
                      row
                    );
                  }
                  return (
                    <td
                      key={`${row.id}-${cell.id}`}
                      className={classNames(styles.cell, {
                        [styles.hoverable]:
                          canEdit &&
                          (cell.onClickCondition == null ||
                            (typeof cell.onClickCondition === "function" &&
                              cell.onClickCondition(
                                getDataFromSubkeys(row, cell.entityKey),
                                row.id,
                                row
                              ))) &&
                          cell?.onClick != null,
                        [styles.inactive]:
                          typeof cell.onClickCondition === "function" &&
                          !cell.onClickCondition(
                            getDataFromSubkeys(row, cell.entityKey),
                            row.id,
                            row
                          ),
                        [styles[highlight]]: highlight != null,
                      })}
                      style={{
                        width: `var(--width-${cell.width})`,
                        minWidth: `var(--width-${cell.width})`,
                        maxWidth: `var(--width-${cell.width})`,
                        display: cell?.hidden ? "none" : null,
                      }}
                      onClick={(event) => {
                        if (!canEdit) return false;
                        if (
                          cell.onClickCondition == null ||
                          (typeof cell.onClickCondition === "function" &&
                            cell.onClickCondition(
                              getDataFromSubkeys(row, cell.entityKey),
                              row.id,
                              row
                            ))
                        ) {
                          if (typeof cell.onClick === "function") {
                            event.preventDefault();
                            event.stopPropagation();
                            cell.onClick(row.id, row);
                          }
                        }
                      }}
                    >
                      {typeof cell.component === "function" ? (
                        <>
                          {cell.component(
                            getDataFromSubkeys(row, cell.entityKey),
                            row.id,
                            row
                          )}
                        </>
                      ) : (
                        <>{getCellData(row, cell.entityKey)}</>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
);

export default DynamicTable;
