import { useState, useEffect, useRef } from "react";
import useEmployees from "./useEmployees";
import useLang from "../../../hooks/useLang";
import { useNavigate } from "react-router-dom";
import useStatusModifier from "./useStatusModifier";
import Icon from "../../../components/Icon";
import Loader from "../../../components/Loader";
import useDisableEmployee from "./useDisableEmployee";

const useEmployeeList = () => {
  const navigate = useNavigate();
  const tableRef = useRef();
  const lang = useLang();
  const employees = useEmployees();
  const [handleDisableEmployee] = useDisableEmployee();
  const [loadingIds, setLoadingIds] = useState([]);

  const enabledIds = employees?.data?.ids
    ? employees?.data?.ids?.filter(
        (entityId) => !employees?.data?.entities[entityId].disabled
      )
    : [];
  const disabledIds = employees?.data?.ids
    ? employees?.data?.ids?.filter(
        (entityId) => employees?.data?.entities[entityId].disabled
      )
    : [];
  const employeeIds = [...enabledIds, ...disabledIds];

  const dataRows = employeeIds?.map((entityId) => ({
    ...employees?.data?.entities[entityId],
    name: `${employees?.data?.entities[entityId].given_name} ${employees?.data?.entities[entityId].family_name}`,
    id: entityId,
  }));

  const statusDialogAttribs = useStatusModifier();

  const handleDisablement = (employeeId) => {
    setLoadingIds((state) => [...state, employeeId]);
    handleDisableEmployee(
      { id: employeeId },
      { disabled: !employees?.data?.entities[employeeId]?.disabled }
    );
  };

  useEffect(() => {
    if (employees?.status === "fulfilled") {
      setLoadingIds([]);
    }
  }, [employees?.status]);

  const tableConfig = [
    {
      id: "DISABLED",
      width: 10,
      header: { label: lang("ena") },
      entityKey: "disabled",
      onClick: handleDisablement,
      component: (value, id) => {
        if (loadingIds.indexOf(id) > -1) {
          return <Loader xs />;
        }
        return value ? <Icon box /> : <Icon boxChecked color="green" />;
      },
    },
    {
      id: "NAME",
      width: 44,
      header: { label: lang("name") },
      entityKey: "name",
    },
    {
      id: "ORGANIZATION_NAME",
      width: 44,
      header: { label: lang("organization") },
      entityKey: "organisation.name",
    },
    {
      id: "SKILL",
      width: 44,
      header: { label: lang("skills") },
      entityKey: "skill_names",
    },
    {
      id: "ROLE",
      width: 44,
      header: { label: lang("roles") },
      entityKey: "roles",
    },
  ];

  const handleRowClick = (data) => {
    navigate(data);
  };

  return {
    tableAttribs: {
      ref: tableRef,
      config: tableConfig,
      data: dataRows || [],
      onRowClick: handleRowClick,
    },
    statusDialogAttribs,
  };
};

export default useEmployeeList;
