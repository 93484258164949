import { useState, useRef, useEffect } from "react";
import useOffer from "./useOffer";
import useOffers from "./useOffers";
import useOfferAccept from "./useOfferAccept";
import useOfferDecline from "./useOfferDecline";
import useInput from "../../../hooks/useInput";

const ACCEPT = "ACCEPT";
const DECLINE = "DECLINE";

const useStatusChanger = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState();
  const dataRef = useRef();
  const [offerId, _setOfferId] = useState();
  const setOfferId = (data) => {
    dataRef.current = undefined;
    _setOfferId((state) => (state !== data ? data : state));
  };
  const { data } = useOffer(offerId);
  const { refetch: refetchOffers } = useOffers();
  const [handleAcceptSubmit] = useOfferAccept();
  const [handleDeclineSubmit] = useOfferDecline();
  const startInput = useInput();
  const finishInput = useInput();

  const handleAcceptClick = () => {
    setStatus((state) => (state !== ACCEPT ? ACCEPT : state));
  };
  const handleDeclineClick = () => {
    setStatus((state) => (state !== DECLINE ? DECLINE : state));
  };
  const acceptOffer = {
    onClick: handleAcceptClick,
    active: status === ACCEPT,
  };
  const declineOffer = {
    onClick: handleDeclineClick,
    active: status === DECLINE,
  };

  const resetInputs = () => {
    setStatus(undefined);
    startInput.reset();
    finishInput.reset();
  };

  useEffect(() => {
    if (dataRef.current == null && data != null) {
      dataRef.current = data;
      resetInputs();
    }
    // eslint-disable-next-line
  }, [data]);

  const onOpen = (data) => {
    setOfferId(data);
    setIsOpen(true);
    props?.onOpen();
  };
  const onSubmit = () => {
    if (status === ACCEPT) {
      let body = {};
      if (startInput?.value) {
        body.start = startInput?.value;
      }
      if (finishInput?.value) {
        body.finish = finishInput?.value;
      }
      handleAcceptSubmit({ id: offerId }, body, () => {
        refetchOffers();
      });
    } else if (status === DECLINE) {
      handleDeclineSubmit({ id: offerId }, {}, () => {
        refetchOffers();
      });
    }
  };
  const onCancel = () => {
    setIsOpen(false);
    resetInputs();
    props?.onClose();
  };
  const onClose = () => {
    setIsOpen(false);
    props?.onClose();
    resetInputs();
  };

  return {
    isOpen,
    onOpen,
    onSubmit,
    onCancel,
    onClose,
    acceptOffer,
    declineOffer,
    enabled: status != null,
    showDateInputs: acceptOffer?.active === true,
    startInput,
    finishInput,
  };
};

export default useStatusChanger;
