import { TertiaryParagraph } from "./Paragraph";

const RecordCounter = ({ recordCount }) => {
  if (recordCount == null) return null;

  const recordsOnPage =
    recordCount != null
      ? Math.min(
          recordCount?.totalEntries -
            recordCount?.pageSize * (recordCount?.currentPage - 1),
          recordCount?.pageSize
        )
      : "...";
  const recordsTotal = recordCount != null ? recordCount?.totalEntries : "...";

  if (recordsTotal === recordsOnPage) {
    return <TertiaryParagraph>{`Total: ${recordsTotal}`}</TertiaryParagraph>;
  }

  return (
    <TertiaryParagraph>{`Showing: ${recordsOnPage} of ${recordsTotal}`}</TertiaryParagraph>
  );
};

export default RecordCounter;
