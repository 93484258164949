import { useGetProjectsQuery } from "../projectsSlice";
import usePolicyChecker from "../../auth/usePolicyChecker";
import { userActions } from "../../../config/accessConfig";
import paramConfig from "../../../config/paramConfig";
import { pathnames } from "../../../config/routes";

import useFetchQuery from "../../../hooks/useFetchQuery";

const queryParams = {
  [paramConfig.PAGE_SIZE]: paramConfig.DEFAULT_PAGE_SIZE,
  [paramConfig.PAGE_NUMBER]: paramConfig.DEFAULT_PAGE_NUMBER,
  status: ["ACTIVE", "WARRANTY"],
  sort_by: "desc(date_created)",
  customer_id: null,
  organisation_id: null,
  billing: null,
};

const useProjects = () => {
  const grantAccess = usePolicyChecker({
    action: userActions.GET_PROJECTS,
  });
  const paramString = useFetchQuery(queryParams, pathnames.PROJECTS);
  const projects = useGetProjectsQuery(
    { query: paramString ? `?${paramString}` : "" },
    { skip: !grantAccess }
  );

  return { ...projects, refetch: grantAccess ? projects.refetch : () => {} };
};

export default useProjects;
