import { useState, useEffect, useRef } from "react";
import useInput from "../../../hooks/useInput";
import useProject from "./useProject";
import useProgressPatch from "./useProgressPatch";

const useCompletionModifier = () => {
  const [isOpen, setIsOpen] = useState();
  const [projectId, _setProjectId] = useState();
  const [type, setType] = useState();
  const { data, status: projectStatus } = useProject(projectId);
  const dataRef = useRef();
  const setProjectId = (data) => {
    dataRef.current = undefined;
    _setProjectId((state) => (state !== data ? data : state));
  };
  const completionInput = useInput();
  const [handleProjectPatch] = useProgressPatch();

  const resetCompletion = () => {
    let completion;
    if (type == null || type === "scope") {
      completion =
        Math.round(parseFloat(data?.scope?.progress) * 10000) / 100 || 0;
    } else if (type === "hours") {
      completion =
        Math.round(parseFloat(data?.hourly?.progress) * 10000) / 100 || 0;
    } else if (type === "warranty") {
      completion =
        Math.round(parseFloat(data?.warranty?.progress) * 10000) / 100 || 0;
    }
    if (projectId != null && projectId === data?.id) {
      completionInput.setValue((state) =>
        completion !== state ? completion : state
      );
    } else {
      completionInput.reset();
    }
  };

  useEffect(() => {
    if (projectStatus === "fulfilled") {
      resetCompletion();
    }
    // eslint-disable-next-line
  }, [projectStatus]);

  useEffect(() => {
    resetCompletion();
    // eslint-disable-next-line
  }, [projectId]);

  const onOpen = (projectId, type) => {
    setType(type);
    setProjectId(projectId);
    setIsOpen(true);
  };
  const onSubmit = () => {
    let body = {};
    if (type == null || type === "scope") {
      body.scope = parseFloat(completionInput?.value) / 100;
    } else if (type === "hours") {
      body.hourly = parseFloat(completionInput?.value) / 100;
    } else if (type === "warranty") {
      body.warranty = parseFloat(completionInput?.value) / 100;
    }
    handleProjectPatch({ id: projectId }, body);
  };
  const onCancel = () => {
    setIsOpen(false);
    setProjectId();
  };
  const onClose = () => {
    setIsOpen(false);
    setProjectId();
  };

  return {
    isOpen,
    onOpen,
    onSubmit,
    onCancel,
    onClose,
    projectId,
    completionInput,
  };
};

export default useCompletionModifier;
