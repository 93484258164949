import useSorting from "../../../hooks/useSorting";
import useLang from "../../../hooks/useLang";
import Link from "../../../components/Link";
import { pathnames } from "../../../config/routes";

const useProjectTableConfig = ({
  skipFields,
  skipGroups,
  only,
  hideSorting,
  handleStatusClick,
  handleScopeClick,
  handleProgressClick,
  handleHourlyClick,
  handleHourlyProgressClick,
  handleWarrantyClick,
  handleWarrantyProgressClick,
  handleBillingClick,
  handleAmountClick,
}) => {
  const lang = useLang();
  const nameParams = useSorting("name");
  const fullNoParams = useSorting("full_no");
  const startParams = useSorting("start");
  const finishParams = useSorting("finish");
  const startActualParams = useSorting("start_actual");
  const finishActualParams = useSorting("finish_actual");
  const statusParams = useSorting("status");
  const totalAmountParams = useSorting("total_amount");
  const invoicedAmountParams = useSorting("invoiced_amount");
  const residualAmount = useSorting("remaining_amount");
  const plannedScope = useSorting("scope.approved");
  const estimatedScope = useSorting("scope.planned");
  const actualScope = useSorting("scope.actual");
  const residualScope = useSorting("scope.residual");
  const efficientcy = useSorting("scope.efficiency");
  const progress = useSorting("scope.progress");
  const plannedHours = useSorting("hourly.approved");
  const actualHours = useSorting("hourly.actual");
  const residualHours = useSorting("hourly.residual");
  const hourlyProgress = useSorting("hourly.progress");
  const plannedWarranty = useSorting("warranty.approved");
  const actualWarranty = useSorting("warranty.actual");
  const residualWarranty = useSorting("warranty.residual");
  const ProgressWarranty = useSorting("warranty.progress");
  const billing = useSorting("billing");

  const today = new Date();
  const datePassed = (dateString, compareDate = today) => {
    if (dateString == null) return false;
    const date = new Date(dateString);
    if (date.getFullYear() < compareDate.getFullYear()) {
      return true;
    } else if (date.getFullYear() === compareDate.getFullYear()) {
      if (date.getMonth() < compareDate.getMonth()) {
        return true;
      } else if (date.getMonth() === compareDate.getMonth()) {
        return date.getDate() < compareDate.getDate();
      }
    }
    return false;
  };
  const daysPassed = (dateString, numOfDays) => {
    const compareDate = new Date(
      `${today.getFullYear()}-${today.getMonth() + 1}-${
        today.getDate() - numOfDays
      }`
    );
    return datePassed(dateString, compareDate);
  };

  const originalConfig = [
    {
      id: "full_no",
      width: 20,
      header: fullNoParams,
      entityKey: "full_no",
      sort: !hideSorting && true,
      component: (value, id, data) => {
        return <Link to={`${pathnames.PROJECTS}/${id}`}>{value}</Link>;
      },
    },
    {
      id: "project_name",
      width: 40,
      header: nameParams,
      entityKey: "name",
      sort: !hideSorting && true,
    },
    {
      id: "customer",
      width: 36,
      header: { label: lang("customer") },
      entityKey: "customer",
    },
    {
      id: "organisation",
      width: 36,
      header: { label: lang("organisation") },
      entityKey: "organisation",
    },
    {
      id: "start",
      group: "start",
      width: 24,
      header: startParams,
      entityKey: "start",
      noGroupLabel: true,
      sort: !hideSorting && true,
    },
    {
      id: "finish",
      group: "finish",
      width: 24,
      header: finishParams,
      entityKey: "finish",
      noGroupLabel: true,
      sort: !hideSorting && true,
      highlight: (value, id, row) => {
        return datePassed(value) ? "red" : null;
      },
    },
    {
      id: "start_actual",
      group: "start",
      noGroupLabel: true,
      width: 24,
      header: startActualParams,
      entityKey: "start_actual",
      sort: !hideSorting && true,
    },
    {
      id: "finish_actual",
      group: "finish",
      noGroupLabel: true,
      width: 24,
      header: finishActualParams,
      entityKey: "finish_actual",
      sort: !hideSorting && true,
    },
    {
      group: "status_separator",
    },
    {
      id: "status",
      width: 20,
      header: statusParams,
      entityKey: "status",
      onClick: handleStatusClick,
      sort: !hideSorting && true,
    },
    {
      group: "scope_separator",
    },
    {
      id: "scope.approved",
      group: "scope",
      width: 18,
      header: plannedScope,
      altHeader: lang("planned"),
      entityKey: "scope.approved",
      onClick: handleScopeClick,
      onClickCondition: (value, id, row) => {
        return (
          row?.status !== "FINISHED" &&
          row?.status !== "WARRANTY" &&
          value != null &&
          value !== "" &&
          value !== "-"
        );
      },
      sort: !hideSorting && true,
    },
    {
      id: "scope.planned",
      group: "scope",
      width: 18,
      header: estimatedScope,
      altHeader: lang("estimated"),
      entityKey: "scope.planned",

      sort: !hideSorting && true,
    },
    {
      id: "scope.actual",
      group: "scope",
      width: 18,
      header: actualScope,
      altHeader: lang("actual"),
      entityKey: "scope.actual",
      sort: !hideSorting && true,
    },
    {
      id: "scope.residual",
      group: "scope",
      width: 18,
      header: residualScope,
      entityKey: "scope.residual",
      altHeader: lang("residual"),
      sort: !hideSorting && true,
    },
    {
      id: "scope_efficiency",
      group: "scope",
      width: 18,
      header: efficientcy,
      altHeader: lang("efficiency"),
      entityKey: "scope.efficiency",
      showPercentage: true,
      highlight: (value) =>
        value == null
          ? null
          : value < 0.6
          ? "red"
          : value < 0.8
          ? "yellow"
          : null,
      sort: !hideSorting && true,
    },
    {
      id: "scope_progress",
      group: "scope",
      width: 18,
      header: progress,
      entityKey: "scope.progress",
      altHeader: lang("progress"),
      showPercentage: true,
      onClick: (value) => handleProgressClick(value, "scope"),
      onClickCondition: (value, id, row) => {
        return row?.status === "ACTIVE" && row?.scope != null;
      },
      highlight: (value, id, row) => {
        if (value > 1) return "red";
        if (row?.status !== "ACTIVE" && row?.status !== "WARRANTY") return null;
        if (row?.scope == null) return null;
        return daysPassed(row?.scope?.last_revision, 7)
          ? "red"
          : daysPassed(row?.scope?.last_revision, 3)
          ? "yellow"
          : null;
      },
      sort: !hideSorting && true,
    },
    {
      id: "scope.last_revision",
      hidden: true,
      group: "scope",
      header: { label: lang("scope.last_revision") },
      altHeader: lang("last_revision"),
      entityKey: "scope.last_revision",
    },
    {
      group: "scope_by_skill",
    },
    {
      id: "hourly.approved",
      group: "hourly",
      width: 18,
      header: plannedHours,
      altHeader: lang("estimated"),
      entityKey: "hourly.approved",
      onClick: handleHourlyClick,
      onClickCondition: (value, id, row) => {
        return (
          row?.status !== "FINISHED" &&
          row?.status !== "WARRANTY" &&
          value != null &&
          value !== "" &&
          value !== "-"
        );
      },
      sort: !hideSorting && true,
    },
    {
      id: "hourly.actual",
      group: "hourly",
      width: 18,
      header: actualHours,
      altHeader: lang("actual"),
      entityKey: "hourly.actual",
      sort: !hideSorting && true,
    },
    {
      id: "hourly.residual",
      group: "hourly",
      width: 18,
      header: residualHours,
      altHeader: lang("residual"),
      entityKey: "hourly.residual",
      sort: !hideSorting && true,
    },
    {
      id: "hourly.progress",
      group: "hourly",
      width: 18,
      header: hourlyProgress,
      altHeader: lang("progress"),
      entityKey: "hourly.progress",
      onClick: (value) => handleHourlyProgressClick(value, "hours"),
      onClickCondition: (value, id, row) => {
        return row?.status === "ACTIVE" && row?.hourly != null;
      },
      showPercentage: true,
      sort: !hideSorting && true,
      highlight: (value, id, row) => {
        if (value > 1) return "red";
        if (row?.status !== "ACTIVE" && row?.status !== "WARRANTY") return null;
        if (row?.hourly == null) return null;
        return daysPassed(row?.hourly?.last_revision, 7)
          ? "red"
          : daysPassed(row?.hourly?.last_revision, 3)
          ? "yellow"
          : null;
      },
    },
    {
      id: "hourly.last_revision",
      hidden: true,
      group: "hourly",
      header: { label: lang("hourly.last_revision") },
      altHeader: lang("last_revision"),
      entityKey: "hourly.last_revision",
    },
    {
      group: "hourly_by_skill",
    },
    {
      id: "warranty.approved",
      group: "warranty",
      width: 18,
      header: plannedWarranty,
      altHeader: lang("estimated"),
      entityKey: "warranty.approved",
      onClick: handleWarrantyClick,
      onClickCondition: (value, id, row) => {
        return (
          row?.status !== "FINISHED" &&
          value != null &&
          value !== "" &&
          value !== "-"
        );
      },
      sort: !hideSorting && true,
    },
    {
      id: "warranty.actual",
      group: "warranty",
      width: 18,
      header: actualWarranty,
      altHeader: lang("actual"),
      entityKey: "warranty.actual",
      sort: !hideSorting && true,
    },
    {
      id: "warranty.residual",
      group: "warranty",
      width: 18,
      header: residualWarranty,
      altHeader: lang("residual"),
      entityKey: "warranty.residual",
      sort: !hideSorting && true,
    },
    {
      id: "warranty.progress",
      group: "warranty",
      width: 18,
      header: ProgressWarranty,
      altHeader: lang("progress"),
      entityKey: "warranty.progress",
      onClick: (value) => handleWarrantyProgressClick(value, "warranty"),
      onClickCondition: (value, id, row) => {
        return (
          row?.status !== "PENDING" &&
          row?.status !== "FINISHED" &&
          row?.warranty != null
        );
      },
      showPercentage: true,
      sort: !hideSorting && true,
      highlight: (value, id, row) => {
        if (value > 1) return "red";
        if (row?.status !== "ACTIVE" && row?.status !== "WARRANTY") return null;
        if (row?.warranty == null) return null;
        return daysPassed(row?.warranty?.last_revision, 7)
          ? "red"
          : daysPassed(row?.warranty?.last_revision, 3)
          ? "yellow"
          : null;
      },
    },
    {
      id: "warranty.last_revision",
      hidden: true,
      group: "warranty",
      header: { label: lang("warranty.last_revision") },
      altHeader: lang("last_revision"),
      entityKey: "warranty.last_revision",
    },
    {
      group: "warranty_by_skill",
    },
    {
      group: "billing_separator",
    },
    {
      id: "billing",
      width: 20,
      header: billing,
      entityKey: "billing",
      onClick: handleBillingClick,
      onClickCondition: (value, id, row) => {
        return row?.status !== "FINISHED" && row?.status !== "WARRANTY";
      },
      sort: !hideSorting && true,
    },
    {
      id: "qty",
      width: 18,
      header: { label: lang("qty") },
      entityKey: "qty",
    },
    {
      id: "uom",
      width: 18,
      header: { label: lang("unit_of_measurement") },
      entityKey: "uom",
    },
    {
      id: "total_amount",
      width: 22,
      header: totalAmountParams,
      entityKey: "total_amount",
      sort: !hideSorting && true,
      onClick: handleAmountClick,
      onClickCondition: (value, id, row) => {
        return row?.status !== "FINISHED";
      },
    },
    {
      id: "invoiced_amount",
      width: 22,
      header: invoicedAmountParams,
      altHeader: lang("invoiced"),
      entityKey: "invoiced_amount",
      sort: !hideSorting && true,
      highlight: (value, id, row) => {
        if (row?.status !== "FINISHED") return null;
        if (row.total_amount != null) {
          if (value == null) return "red";
          else if (value !== row.total_amount) return "red";
        }
        return null;
      },
    },
    {
      group: "invoices",
    },
    {
      id: "remaining_amount",
      altHeader: lang("unpaid"),
      width: 22,
      header: residualAmount,
      entityKey: "remaining_amount",
      sort: !hideSorting && true,
    },
  ];

  let result = [];

  if (only != null) {
    result = originalConfig.filter((item) => {
      if (
        only &&
        Array.isArray(only) &&
        only.indexOf(item.entityKey) < 0 &&
        only.indexOf(item.group) < 0
      )
        return false;
      return true;
    });
  } else if (skipFields != null || skipGroups != null) {
    result = originalConfig.filter((item) => {
      if (
        skipFields &&
        Array.isArray(skipFields) &&
        skipFields.indexOf(item.entityKey) > -1
      )
        return false;
      if (
        skipGroups &&
        Array.isArray(skipGroups) &&
        skipGroups.indexOf(item.group) > -1
      )
        return false;
      return true;
    });
  } else {
    result = originalConfig;
  }
  return result;
};

export default useProjectTableConfig;
