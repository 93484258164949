import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiAuthenticatedSlice";

const organizationsAdapter = createEntityAdapter();
const initialState = organizationsAdapter.getInitialState();

export const organizationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query({
      query: () => `/organisations`,
      transformResponse: (response) => {
        return organizationsAdapter.setAll(initialState, response);
      },
      providesTags: [{ type: "Organization" }],
    }),
    addOrganization: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/organisations`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Organization" }],
    }),
    patchOrganization: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/organisations/${params?.id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: [{ type: "Organization" }],
    }),
  }),
});

export const { useGetOrganizationsQuery, useAddOrganizationMutation, usePatchOrganizationMutation } = organizationsSlice;
