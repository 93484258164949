import { apiSlice } from "../api/apiAuthenticatedSlice";

export const checkInSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMyCurrentActivity: builder.query({
      query: () => `/activities/active/my`,
      providesTags: [{ type: "MyActivity" }],
    }),
    start: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/activities/start`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "MyActivity" }, { type: "Activity" }],
    }),
    finish: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/activities/${params.id}/finish`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "MyActivity" }, { type: "Activity" }],
    }),
  }),
});

export const {
  useGetMyCurrentActivityQuery,
  useStartMutation,
  useFinishMutation,
} = checkInSlice;
