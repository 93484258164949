import { useState } from "react";
// import useLang from "../../../hooks/useLang";
import useAddInvoice from "./useAddInvoice";
import useInput from "../../../hooks/useInput";
import { useParams } from "react-router-dom";

const useInvoiceCreator = () => {
  // const lang = useLang();
  const { projectId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [handleAddInvoice] = useAddInvoice();
  const invoiceInput = useInput("");
  const amountInput = useInput("");

  const resetInputs = () => {
    invoiceInput.reset();
    amountInput.reset();
  };

  const onOpen = () => {
    resetInputs();
    setIsOpen(true);
  };
  const onSubmit = () => {
    handleAddInvoice(
      { id: projectId },
      { invoice_no: invoiceInput?.value, amount: amountInput?.value }
    );
  };
  const onCancel = () => {
    setIsOpen(false);
    resetInputs();
  };
  const onClose = () => {
    setIsOpen(false);
    resetInputs();
  };

  return {
    isOpen,
    onOpen,
    onSubmit,
    onCancel,
    onClose,
    invoiceInput,
    amountInput,
    enabled: invoiceInput?.value && amountInput?.value,
  };
};

export default useInvoiceCreator;
