import { useEffect, useRef } from "react";
import useTimesheets from "./useTimesheets";
import useEmployees from "../../employees/hooks/useEmployees";
import useOrganizations from "../../organizations/hooks/useOrganizations";
import useDropdownSelector from "../../../hooks/useDropdownSelector";
import useLang from "../../../hooks/useLang";
import { useCopyTable } from "../../../hooks/useCopyTable";

const firstYear = 2023;

const useTimesheetsPage = () => {
  const lang = useLang();
  const yearRef = useRef();
  const monthRef = useRef();
  const [copyYear] = useCopyTable(yearRef);
  const [copyMonth] = useCopyTable(monthRef);

  const getYearOptions = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let yearOptions = [];
    let year;
    for (year = currentYear; year >= firstYear; year--) {
      yearOptions.push({ id: year, label: year });
    }
    return yearOptions;
  };
  const getMonthOptions = (selectedYear) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    let monthOptions = [];
    if (selectedYear == null) return monthOptions;
    let month;
    for (
      month = selectedYear === currentYear ? currentMonth : 11;
      month >= 0;
      month--
    ) {
      monthOptions.push({ id: month, label: lang(`month_${month + 1}`) });
    }
    return monthOptions;
  };

  const employees = useEmployees();
  const organizations = useOrganizations();
  const yearSelector = useDropdownSelector({
    defaultValue: getYearOptions()[0],
    options: getYearOptions(),
  });
  const monthSelector = useDropdownSelector({
    defaultValue: { id: null, label: "-" },
    options: getMonthOptions(yearSelector?.value?.id),
  });

  const timesheets = useTimesheets(yearSelector?.value?.id);
  let timesheetsData;
  if (Array.isArray(timesheets?.data?.worker_data) && employees?.data != null) {
    const worker_data = timesheets?.data?.worker_data.map((workerData) => {
      if (employees?.data?.entities[workerData?.user_id]) {
        let hours = {};
        workerData?.hours?.map((hourData, idx) => {
          hours[idx] =
            hourData != null ? Math.round(parseFloat(hourData) * 10) / 10 : "";
          return false;
        });
        return {
          ...workerData,
          user: employees?.data?.entities[workerData?.user_id],
          organisation:
            organizations?.data?.entities[workerData?.organisation_id],
          ...hours,
        };
      }
      return workerData;
    });
    timesheetsData = { ...timesheets?.data, worker_data };
  }

  const monthlyRows = timesheetsData?.worker_data?.map((workerData) => ({
    ...workerData,
    id: workerData.user_id,
    name: `${workerData?.user?.given_name} ${workerData?.user?.family_name}`,
  }));

  let noOfDays = 0;
  const dailyRows = timesheetsData?.months[
    monthSelector?.value?.id
  ]?.worker_data.map((workerData) => {
    let hours = {};
    noOfDays = 0;
    workerData?.hours?.map((hourData, idx) => {
      noOfDays++;
      hours[idx] =
        hourData != null ? Math.round(parseFloat(hourData) * 10) / 10 : "";
      return false;
    });
    return {
      ...workerData,
      ...hours,
      name: `${employees?.data?.entities[workerData?.user_id]?.given_name} ${
        employees?.data?.entities[workerData?.user_id]?.family_name
      }`,
      organisation: organizations?.data?.entities[workerData?.organisation_id],
    };
  });

  let monthlyTableConfig = [
    {
      id: "name",
      width: 48,
      header: { label: lang("name") },
      entityKey: "name",
    },
    {
      id: "organisation",
      width: 36,
      header: { label: lang("organisation") },
      entityKey: "organisation.name",
    },
  ];
  for (let i = 0; i < 12; i++) {
    monthlyTableConfig.push({
      id: `month_${i}`,
      width: 12,
      header: { label: lang(`month_${i + 1}`) },
      entityKey: i,
    });
  }

  let dailyTableConfig = [
    {
      id: "name",
      width: 48,
      header: { label: lang("name") },
      entityKey: "name",
    },
    {
      id: "organisation",
      width: 36,
      header: { label: lang("organisation") },
      entityKey: "organisation.name",
    },
  ];
  for (let i = 0; i < noOfDays; i++) {
    dailyTableConfig.push({
      id: `${monthSelector?.value?.id}_day_${i}`,
      width: 10,
      header: { label: i + 1 },
      entityKey: i,
    });
  }

  const showMonthTable =
    yearSelector?.value?.id != null &&
    monthSelector?.value?.id == null &&
    Array.isArray(monthlyRows) &&
    monthlyRows.length > 0;

  const showDailyTable =
    yearSelector?.value?.id != null &&
    monthSelector?.value?.id != null &&
    Array.isArray(dailyRows) &&
    dailyRows.length > 0;

  useEffect(() => {
    if (yearSelector?.value?.id !== null) {
      monthSelector.reset(getMonthOptions(yearSelector?.value?.id));
    }
    // eslint-disable-next-line
  }, [yearSelector?.value?.id]);

  return {
    copyYear,
    copyMonth,
    yearSelector,
    monthSelector,
    showMonthTable,
    showDailyTable,
    isLoading: employees?.isLoading || timesheets?.isLoading,
    monthlyTableAttribs: showMonthTable
      ? {
          config: monthlyTableConfig,
          data: monthlyRows || [],
          ref: yearRef,
        }
      : {},
    dailyTableAttribs: showDailyTable
      ? {
          config: dailyTableConfig,
          data: dailyRows || [],
          ref: monthRef,
        }
      : {},
  };
};

export default useTimesheetsPage;
