import localization from "../config/localization";

const getFloatString = (rawValue) => {
  let roundedValue = rawValue;
  if (typeof rawValue === "number") {
    roundedValue = Math.round(rawValue * 100) / 100;
    roundedValue = String(roundedValue).replace(
      ".",
      localization.DECIMAL_SEPARATOR || ","
    );
  }
  return roundedValue;
};

export default getFloatString;
