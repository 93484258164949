import useLang from "../../hooks/useLang";
import { PageHeader } from "../../components/Header";
import useProjectPage from "./hooks/useProjectPage";
import { BundleGroup } from "../../components/Group";
import Paragraph, { SecondaryParagraph } from "../../components/Paragraph";
import Button from "../../components/Button";
import CompletionModifier from "./CompletionModifier";
import { ProjectStatusChangerControlled } from "./ProjectStatusChanger";
import InvoiceCreator from "./InvoiceCreator";
import ModifierModal from "../../components/ModifierModal";
import Input from "../../components/Input";
import BillingModifier from "./BillingModifier";
import DynamicList from "../../components/DynamicList";
import getFloatString from "../../utils/getFloatString";

const ProjectPage = () => {
  const lang = useLang();
  const {
    modified,
    project,
    completionDialogAttribs,
    projectStatusChangerAttribs,
    scopeInput,
    scopeModifierAttribs,
    listAttribs,
    amountListAttribs,
    amountInput,
    amountModifierAttribs,
    warrantyInput,
    warrantyModifierAttribs,
    billingDropdown,
    hourlyInput,
    hourlyModifierAttribs,
    billingModifierAttribs,
    skills,
    projectId,
  } = useProjectPage();

  const scopeBySkill = (scope, label) => {
    if (scope == null) return null;
    const data = scope?.by_skill;
    if (!Array.isArray(data))
      return <div style={{ height: "var(--spacing-8)" }} />;
    if (data.length < 1) return <div style={{ height: "var(--spacing-8)" }} />;
    return (
      <div>
        <BundleGroup>
          <BundleGroup width={4} />
          <BundleGroup width={28}>
            <SecondaryParagraph>{label}</SecondaryParagraph>
          </BundleGroup>
        </BundleGroup>
        {data.map((item) => {
          return (
            <BundleGroup key={item.skill_id}>
              <BundleGroup width={8} />
              <BundleGroup width={28}>
                <SecondaryParagraph>
                  {skills[item.skill_id]?.name}
                </SecondaryParagraph>
              </BundleGroup>
              <BundleGroup>
                <Paragraph>{getFloatString(item.total)}</Paragraph>
              </BundleGroup>
            </BundleGroup>
          );
        })}
        <div style={{ height: "var(--spacing-8)" }} />
      </div>
    );
  };

  return (
    <>
      <PageHeader>
        <BundleGroup style={{ alignItems: "flex-end" }}>
          <h1>
            {project
              ? `${project?.full_no ? project?.full_no + " / " : ""}${
                  project.name
                }`
              : "..."}
          </h1>
          {modified ? (
            <>
              <BundleGroup width={8} />
              <SecondaryParagraph>{`${lang(
                "modified"
              )}: ${modified}`}</SecondaryParagraph>
            </>
          ) : null}
        </BundleGroup>
      </PageHeader>
      <BundleGroup>
        <BundleGroup width={28}>
          <SecondaryParagraph>{lang("customer")}</SecondaryParagraph>
        </BundleGroup>
        <BundleGroup>
          <Paragraph>
            {project?.customer_full
              ? `${project?.customer_full?.name} (${project?.customer_full?.abbr})`
              : "..."}
          </Paragraph>
        </BundleGroup>
      </BundleGroup>
      <BundleGroup>
        <BundleGroup width={28}>
          <SecondaryParagraph>{lang("organisation")}</SecondaryParagraph>
        </BundleGroup>
        <BundleGroup>
          <Paragraph>
            {project?.organisation_full
              ? project?.organisation_full?.name
              : "..."}
          </Paragraph>
        </BundleGroup>
      </BundleGroup>
      <BundleGroup>
        <BundleGroup width={28}>
          <SecondaryParagraph>{lang("quantity")}</SecondaryParagraph>
        </BundleGroup>
        <BundleGroup>
          <Paragraph>
            {project?.qty ? `${project?.qty} ${project?.uom}` : "..."}
          </Paragraph>
        </BundleGroup>
      </BundleGroup>
      <div
        style={{
          height: "var(--spacing-8)",
        }}
      />
      {/* <DynamicTable {...listAttribs} /> */}
      <BundleGroup style={{ alignItems: "flex-start" }}>
        <BundleGroup>
          <DynamicList
            {...listAttribs}
            id={projectId}
            scope_by_skill={scopeBySkill(project?.scope, lang("by_skill"))}
            hourly_by_skill={scopeBySkill(project?.hourly, lang("by_skill"))}
            warranty_by_skill={scopeBySkill(
              project?.warranty,
              lang("by_skill")
            )}
            status_separator={<div style={{ height: "var(--spacing-8)" }} />}
            scope_separator={<div style={{ height: "var(--spacing-8)" }} />}
          />
        </BundleGroup>
        <BundleGroup
          style={{
            border: "1px solid var(--clr-neutral-200)",
            padding: "var(--width-8)",
          }}
        >
          <div>
            <DynamicList {...amountListAttribs} id={projectId} />
            <>
              {" "}
              {Array.isArray(project?.invoices) &&
              project?.invoices.length > 0 ? (
                <div style={{ height: "var(--spacing-8)" }} />
              ) : null}
              {project?.invoices?.map((invoice) => {
                return (
                  <BundleGroup key={invoice?.invoice_no}>
                    <BundleGroup width={28}>
                      <SecondaryParagraph>
                        {lang("invoice_num")}
                      </SecondaryParagraph>
                    </BundleGroup>
                    <BundleGroup width={32}>
                      <Paragraph>{invoice?.invoice_no}</Paragraph>
                    </BundleGroup>
                    <BundleGroup width={28}>
                      <SecondaryParagraph>{lang("amount")}</SecondaryParagraph>
                    </BundleGroup>
                    <BundleGroup width={32}>
                      <Paragraph>{invoice?.amount}</Paragraph>
                    </BundleGroup>
                  </BundleGroup>
                );
              })}
              <div style={{ height: "var(--spacing-8)" }} />
              <InvoiceCreator
                renderOpen={(onClick) => (
                  <Button emphasized onClick={onClick}>
                    {lang("add_invoice")}
                  </Button>
                )}
              />
            </>
          </div>
        </BundleGroup>
      </BundleGroup>
      <CompletionModifier {...completionDialogAttribs} />
      <ProjectStatusChangerControlled {...projectStatusChangerAttribs} />
      <ModifierModal {...scopeModifierAttribs}>
        <BundleGroup>
          <Input
            label={lang("planned_scope")}
            type="number"
            {...scopeInput.attributes}
          />
        </BundleGroup>
      </ModifierModal>
      <ModifierModal {...hourlyModifierAttribs}>
        <BundleGroup>
          <Input
            label={lang("planned_hourly")}
            type="number"
            {...hourlyInput.attributes}
          />
        </BundleGroup>
      </ModifierModal>
      <ModifierModal {...warrantyModifierAttribs}>
        <BundleGroup>
          <Input
            label={lang("planned_warranty")}
            labelWidth={32}
            type="number"
            {...warrantyInput.attributes}
          />
        </BundleGroup>
      </ModifierModal>
      <ModifierModal {...amountModifierAttribs}>
        <BundleGroup>
          <Input
            label={lang("total_amount")}
            type="number"
            {...amountInput.attributes}
          />
        </BundleGroup>
      </ModifierModal>
      <BillingModifier
        {...{
          billingModifierAttribs,
          billingDropdown,
          scopeInput,
          hourlyInput,
        }}
      />
    </>
  );
};

export default ProjectPage;
