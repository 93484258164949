import useOrganizations from "./useOrganizations";
import useLang from "../../../hooks/useLang";

const useOrganizationList = () => {
  const organizations = useOrganizations();
  const lang = useLang();

  const myOrganizations =
    organizations?.data?.ids?.filter(
      (id) => !organizations?.data?.entities[id].is_subcontractor
    ) || [];

  const subcontractors =
    organizations?.data?.ids?.filter(
      (id) => organizations?.data?.entities[id].is_subcontractor
    ) || [];

  const dataRows = myOrganizations?.map((entityId) => ({
    ...organizations?.data?.entities[entityId],
    name: organizations?.data?.entities[entityId].name,
    id: entityId,
  }));

  const dataRowsSubcontractors = subcontractors?.map((entityId) => ({
    ...organizations?.data?.entities[entityId],
    name: organizations?.data?.entities[entityId].name,
    id: entityId,
  }));

  const tableConfig = [
    {
      id: "NAME",
      width: 52,
      header: { label: lang("my_organisations") },
      entityKey: "name",
    },
  ];

  const subcontractorTableConfig = [
    {
      id: "NAME",
      width: 52,
      header: { label: lang("subcontractors") },
      entityKey: "name",
    },
  ];

  return {
    tableAttribs: {
      config: tableConfig,
      data: dataRows || [],
    },
    subcontractorTableAttribs: {
      config: subcontractorTableConfig,
      data: dataRowsSubcontractors || [],
    },
  };
};

export default useOrganizationList;
