import useModifierModal from "../../../hooks/useModifierModal";
import useOrganization from "./useOrganization";
import useAddOrganization from "./useAddOrganization";
import useInput from "../../../hooks/useInput";

const useOrganizationCreator = () => {
  const nameInput = useInput();
  const subcontractorInput = useInput(false);

  const organizationModifierAttribs = useModifierModal({
    inputs: [
      { input: nameInput, key: "name" },
      { input: subcontractorInput, key: "is_subcontractor" },
    ],
    query: useOrganization,
    postMutation: useAddOrganization,
  });

  return {
    ...organizationModifierAttribs,
    nameInput,
    subcontractorInput,
  };
};

export default useOrganizationCreator;
