import useOfferCreator from "./useOfferCreator";
import useClientCreator from "../../clients/hooks/useClientCreator";

const useOfferCreatorMultimodal = () => {
  const offerCreatorProps = useOfferCreator();

  const handleClientCreatorClose = (data) => {
    offerCreatorProps.onOpen();
  };
  const clientCreatorProps = useClientCreator({
    onOpen: () => {
      offerCreatorProps.onClose(true);
    },
    onClose: handleClientCreatorClose,
  });

  return { offerCreatorProps, clientCreatorProps };
};

export default useOfferCreatorMultimodal;
