import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiAuthenticatedSlice";
import getPaginationInfo from "../../utils/getPaginationInfo";

const offersAdapter = createEntityAdapter();
const initialState = offersAdapter.getInitialState();

export const offersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOffers: builder.query({
      query: (arg) => `/offers${arg?.query ? arg?.query : ""}`,
      transformResponse: (response, meta) => {
        const pagination = getPaginationInfo(meta);
        const data = offersAdapter.setAll(initialState, response || []);
        return { ...data, pagination };
      },
      providesTags: (result, error, arg) => [
        { type: "Offer" },
        { type: "Offer", id: arg?.query },
      ],
    }),
    addOffer: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/offers`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Offer" }],
    }),
    acceptOffer: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/offers/${params?.id}/accepted`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Offer" }],
    }),
    declineOffer: builder.mutation({
      query: ({ params }) => {
        return {
          url: `/offers/${params?.id}/declined`,
          method: "POST",
          body: {},
        };
      },
      invalidatesTags: [{ type: "Offer" }],
    }),
    patchOffer: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/offers/${params?.id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: [{ type: "Offer" }],
    }),
  }),
});

export const {
  useGetOffersQuery,
  useAddOfferMutation,
  useAcceptOfferMutation,
  useDeclineOfferMutation,
  usePatchOfferMutation,
} = offersSlice;
