import { useGetClientsQuery } from "../clientsSlice";
import usePolicyChecker from "../../auth/usePolicyChecker";
import { userActions } from "../../../config/accessConfig";

const useClients = () => {
  const grantAccess = usePolicyChecker({
    action: userActions.GET_CLIENTS,
  });
  const clients = useGetClientsQuery(null, { skip: !grantAccess });
  return { ...clients, refetch: grantAccess ? clients.refetch : () => {} };
};

export default useClients;
