import { Routes, Route, Navigate } from "react-router-dom";
import AlertHandler from "./components/alerts/AlertHandler";
import Layout from "./components/Layout";
import Login from "./features/auth/Login";
import PersistLogin from "./components/PersistLogin";
// import InvitationHandler from "./components/InvitationHandler";
// import AuthorizedRoute from "./components/AutorizedRoute";
import Cognito from "./features/auth/Cognito";
// import { userActions } from "./config/accessConfig";
// import { PageNotFound } from "./features/auth/NotFound";
import NotFoundHandler from "./components/NotFountHandler";
import Offers from "./features/offers/Offers";
import ProjectList from "./features/projects/ProjectList";
import ProjectPage from "./features/projects/ProjectPage";
import EmployeeList from "./features/employees/EmployeeList";
import OrganizationList from "./features/organizations/OrganizationList";
import EmployeePage from "./features/employees/EmployeePage";
import ClientList from "./features/clients/ClientList";
import TimesheetsPage from "./features/timesheets/TimesheetsPage";
import TimeLogsPage from "./features/timelogs/TimeLogsPage";
import CheckIn from "./features/checkIn/CheckIn";
import { pathnames } from "./config/routes";
import usePolicyChecker from "./features/auth/usePolicyChecker";
import { userActions } from "./config/accessConfig";
import AccessWrapper from "./features/auth/AccessWrapper";

const App = () => {
  const canAccessProjects = usePolicyChecker({
    action: userActions.SHOW_PROJECTS,
  });
  const canAccessCheckin = usePolicyChecker({
    action: userActions.GET_MY_ACTIVITIES,
  });
  const canAccessTimelogs = usePolicyChecker({
    action: userActions.SHOW_TIMELOGS,
  });
  return (
    <>
      <Routes>
        <Route element={<AlertHandler />}>
          <Route path="cognito" element={<Cognito />} />
          <Route path="login" element={<Login />} />
          <Route element={<PersistLogin />}>
            <Route element={<Layout />}>
              <Route
                path={pathnames.OFFERS}
                element={
                  <AccessWrapper action={userActions.GET_OFFERS}>
                    <Offers />
                  </AccessWrapper>
                }
              />
              <Route path={pathnames.PROJECTS}>
                <Route
                  index
                  element={
                    <AccessWrapper action={userActions.SHOW_PROJECTS}>
                      <ProjectList />
                    </AccessWrapper>
                  }
                />
                <Route
                  path=":projectId"
                  element={
                    <AccessWrapper action={userActions.SHOW_PROJECTS}>
                      <ProjectPage />
                    </AccessWrapper>
                  }
                />
              </Route>
              <Route path={pathnames.EMPLOYEES}>
                <Route
                  index
                  element={
                    <AccessWrapper action={userActions.SHOW_EMPLOYEES}>
                      <EmployeeList />
                    </AccessWrapper>
                  }
                />
                <Route
                  path=":employeeId"
                  element={
                    <AccessWrapper action={userActions.SHOW_EMPLOYEES}>
                      <EmployeePage />
                    </AccessWrapper>
                  }
                />
              </Route>
              <Route
                path={pathnames.ORGANIZATIONS}
                element={
                  <AccessWrapper action={userActions.GET_ORGANIZATIONS}>
                    <OrganizationList />
                  </AccessWrapper>
                }
              />
              <Route
                path={pathnames.CLIENTS}
                element={
                  <AccessWrapper action={userActions.GET_CLIENTS}>
                    <ClientList />
                  </AccessWrapper>
                }
              />
              <Route
                path={pathnames.TIMESHEETS}
                element={
                  <AccessWrapper action={userActions.GET_TIMESHEETS}>
                    <TimesheetsPage />
                  </AccessWrapper>
                }
              />
              <Route
                path={pathnames.TIMELOGS}
                element={
                  <AccessWrapper action={userActions.SHOW_TIMELOGS}>
                    <TimeLogsPage />
                  </AccessWrapper>
                }
              />
              <Route
                path={pathnames.MY_ACTIVITY}
                element={
                  <AccessWrapper action={userActions.GET_MY_ACTIVITIES}>
                    <CheckIn />
                  </AccessWrapper>
                }
              />
              <Route element={<NotFoundHandler />}></Route>
              {canAccessProjects ? (
                <>
                  <Route
                    index
                    element={<Navigate to={pathnames.PROJECTS} replace />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to={pathnames.PROJECTS} replace />}
                  />
                </>
              ) : canAccessCheckin ? (
                <>
                  <Route
                    index
                    element={<Navigate to={pathnames.MY_ACTIVITY} replace />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to={pathnames.MY_ACTIVITY} replace />}
                  />
                </>
              ) : canAccessTimelogs ? (
                <>
                  <Route
                    index
                    element={<Navigate to={pathnames.TIMELOGS} replace />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to={pathnames.TIMELOGS} replace />}
                  />
                </>
              ) : (
                <Route index />
              )}
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default App;
