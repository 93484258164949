import { useEffect } from "react";
import useProjects from "../../projects/hooks/useProjects";
import useSelector from "../../../hooks/useDropdownSelector";
import useCheckInStart from "./useCheckInStart";
import useLang from "../../../hooks/useLang";
import useMyActivity from "./useMyActivity";
import useMyProfile from "../../auth/useMyProfile";
import activeStatuses from "../../../config/activeStatuses";

const useCheckIn = () => {
  const lang = useLang();
  const { data: myCurrentActivity, isLoading: activityLoading } =
    useMyActivity();
  const { data: myProfile } = useMyProfile();
  const projects = useProjects();

  const getProjectOptions = (projects) => {
    const activeProjectIds = projects?.data?.ids?.filter(
      (projectId) =>
        activeStatuses.indexOf(projects?.data?.entities[projectId].status) > -1
    );
    return activeProjectIds?.map((projectId) => {
      return {
        id: projectId,
        label: `${projects?.data?.entities[projectId].full_no} ${projects?.data?.entities[projectId].name}`,
      };
    });
  };
  const getSkillOptions = (skills) => {
    if (skills == null) return [];
    return skills?.map((skill) => {
      return {
        ...skill,
        label: lang(skill.description),
      };
    });
  };

  const projectDropdown = useSelector({
    defaultValue: "-",
    options: getProjectOptions(projects),
  });
  const skillDropdown = useSelector({
    defaultValue: { id: null, label: "-" },
    options: getSkillOptions(myProfile?.skills),
  });

  const [handleStart, { isLoading: startLoading }] = useCheckInStart();

  useEffect(() => {
    if (projects?.status === "fulfilled") {
      projectDropdown.reset(getProjectOptions(projects));
    }
    // eslint-disable-next-line
  }, [projects?.status]);

  useEffect(() => {
    if (myProfile?.status === "fulfilled") {
      skillDropdown.reset(getSkillOptions(myProfile?.skills));
    }
    // eslint-disable-next-line
  }, [myProfile?.status]);

  const onStart = async () => {
    let body = {
      project_id: projectDropdown?.value?.id,
      skill_id: skillDropdown?.value?.id,
    };
    handleStart(null, body, () => {});
  };

  let jobDescription = "...";
  if (Array.isArray(myProfile?.skills) && myCurrentActivity?.job_type) {
    const job = myProfile?.skills.find(
      (skill) => skill.name === myCurrentActivity?.job_type
    );
    if (job) jobDescription = job.description;
  }

  return {
    onStart,
    checkinStartEnabled:
      projectDropdown?.value?.id != null && skillDropdown?.value?.id != null,
    projectDropdown,
    skillDropdown,
    allowStart: activityLoading === false && myCurrentActivity?.id == null,
    allowFinish: activityLoading === false && myCurrentActivity?.id != null,
    isLoading: activityLoading || projects?.isLoading || startLoading,
    myCurrentActivity,
    myProfile,
    jobDescription,
  };
};

export default useCheckIn;
