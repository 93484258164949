export const pathnames = {
  OFFERS: "/offers",
  PROJECTS: "/projects",
  EMPLOYEES: "/employees",
  ORGANIZATIONS: "/organisations",
  CLIENTS: "/clients",
  TIMESHEETS: "/timesheets",
  TIMELOGS: "/timelogs",
  MY_ACTIVITY: "/myActivity",
};
