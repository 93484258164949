import { useGetOrganizationsQuery } from "../organizationsSlice";
import usePolicyChecker from "../../auth/usePolicyChecker";
import { userActions } from "../../../config/accessConfig";

const useOrganizations = () => {
  const grantAccess = usePolicyChecker({
    action: userActions.GET_ORGANIZATIONS,
  });
  const organizations = useGetOrganizationsQuery(null, { skip: !grantAccess });
  return {
    ...organizations,
    refetch: grantAccess ? organizations.refetch : () => {},
  };
};

export default useOrganizations;
