import useOrganizations from "./useOrganizations";

const useOrganization = (organizationId) => {
  const organizations = useOrganizations();

  let dataFromOrganizations;
  if (organizationId && organizations?.data?.entities[organizationId]) {
    dataFromOrganizations = organizations?.data?.entities[organizationId];
  }

  return { ...organizations, data: dataFromOrganizations };
};

export default useOrganization;
