import useSearchParams from "./useSearchParams";

const useFilterParam = (key) => {
  const { getParam, getParams, addParams, removeParam } = useSearchParams();
  const value = getParam(key);
  const values = getParams(key);

  const setFilterValue = (newValue) => {
    if (!newValue || values.indexOf(newValue) > -1) {
      removeParam(key);
    } else {
      addParams({ [key]: newValue });
    }
  };

  const getFilterParam = () => {
    const value = getParam(key);
    if (value != null) return `${key}=${value}`;
    return null;
  };

  return { value, values, setFilterValue, filterParam: getFilterParam() };
};

export default useFilterParam;
