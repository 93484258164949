import ErrorBoundary from "../../components/ErrorBoundary";
import HomeLink from "./HomeLink";
import MenuList from "./MenuList";
import MenuListItem from "./MenuListItem";
import Button from "../../components/Button";
import useLang from "../../hooks/useLang";
import { SecondaryParagraph } from "../../components/Paragraph";
import useScreenSize from "../../hooks/useScreenSize";
import { SplitGroup, BundleGroup } from "../../components/Group";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import { pathnames } from "../../config/routes";

const SideNav = ({ expanded, onToggle }) => {
  const lang = useLang();
  const { isMobile } = useScreenSize();
  return (
    <>
      <nav>
        {expanded ? (
          <SplitGroup>
            <ErrorBoundary fallback="Error in home link">
              <HomeLink expanded={expanded} />
            </ErrorBoundary>
          </SplitGroup>
        ) : (
          <BundleGroup vertical>
            <ErrorBoundary fallback="Error in home link">
              <HomeLink />
            </ErrorBoundary>
          </BundleGroup>
        )}
        <hr />
        <ErrorBoundary fallback="Error in menu list">
          <MenuList>
            <AccessWrapper action={userActions.GET_OFFERS}>
              <MenuListItem
                icon={"offerMail"}
                linkTo={pathnames.OFFERS}
                label={lang("offers")}
                expanded={expanded}
              />
            </AccessWrapper>
            <AccessWrapper action={userActions.SHOW_PROJECTS}>
              <MenuListItem
                icon={"folder"}
                linkTo={pathnames.PROJECTS}
                label={lang("projects")}
                expanded={expanded}
              />
            </AccessWrapper>
            <AccessWrapper action={userActions.SHOW_EMPLOYEES}>
              <MenuListItem
                icon={"members"}
                linkTo={pathnames.EMPLOYEES}
                label={lang("employees")}
                expanded={expanded}
              />
            </AccessWrapper>
            <AccessWrapper action={userActions.GET_ORGANIZATIONS}>
              <MenuListItem
                icon={"organization"}
                linkTo={pathnames.ORGANIZATIONS}
                label={lang("organisations")}
                expanded={expanded}
              />
            </AccessWrapper>
            <AccessWrapper action={userActions.GET_CLIENTS}>
              <MenuListItem
                icon={"briefcase"}
                linkTo={pathnames.CLIENTS}
                label={lang("clients")}
                expanded={expanded}
              />
            </AccessWrapper>
            <AccessWrapper action={userActions.GET_TIMESHEETS}>
              <MenuListItem
                icon={"timesheet"}
                linkTo={pathnames.TIMESHEETS}
                label={lang("timesheets")}
                expanded={expanded}
              />
            </AccessWrapper>
            <AccessWrapper action={userActions.SHOW_TIMELOGS}>
              <MenuListItem
                icon={"clock"}
                linkTo={pathnames.TIMELOGS}
                label={lang("timelogs")}
                expanded={expanded}
              />
            </AccessWrapper>
            <AccessWrapper action={userActions.GET_MY_ACTIVITIES}>
              <MenuListItem
                icon={"checkIn"}
                linkTo={pathnames.MY_ACTIVITY}
                label={lang("my_activity")}
                expanded={expanded}
              />
            </AccessWrapper>
          </MenuList>
        </ErrorBoundary>
      </nav>
      <div
        style={{
          marginTop: "var(--spacing-6)",
          marginBottom: "var(--spacing-6)",
          marginLeft: "var(--spacing-3)",
        }}
      >
        {!isMobile ? (
          <>
            <div style={{ height: "var(--spacing-2)" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "var(--spacing-2)",
              }}
            >
              <Button
                icon={`${expanded ? "collapseLeft" : "collapseRight"}`}
                onClick={onToggle}
              />
              {expanded ? (
                <SecondaryParagraph>{lang("collapse")}</SecondaryParagraph>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default SideNav;
