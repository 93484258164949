import { useState, useEffect } from "react";
import useInput from "../../../hooks/useInput";
import useClients from "../../clients/hooks/useClients";
import useDropdownSelector from "../../../hooks/useDropdownSelector";
import useLang from "../../../hooks/useLang";
import useAddOffer from "./useAddOffer";
import useOrganizations from "../../organizations/hooks/useOrganizations";
import billing from "../../../config/billing";

const useOfferCreator = () => {
  const lang = useLang();
  const { data: clients, status: clientStatus } = useClients();
  const [handleAddOffer] = useAddOffer();

  const nameInput = useInput("");
  const { data: organizations, status: organizationsStatus } =
    useOrganizations();
  const myOrganizationIds = organizations?.ids?.filter(
    (organizationId) =>
      !organizations?.entities[organizationId]?.is_subcontractor
  );
  const organizationOptions = myOrganizationIds?.map((organizationId) => {
    return {
      id: organizationId,
      label: organizations?.entities[organizationId].name,
    };
  });
  const organizationDropdown = useDropdownSelector({
    defaultValue: { id: null, label: "-" },
    options: organizationOptions,
  });
  const billingDropdown = useDropdownSelector({
    defaultValue: { id: null, label: "-" },
    options: billing,
  });
  const scopeInput = useInput();
  const hoursInput = useInput();

  const detailsInput = useInput("");
  const dateStartInput = useInput();
  const dateFinishInput = useInput();
  const quantityInput = useInput();
  const clientDropdown = useDropdownSelector({
    defaultValue: { id: null, label: "-" },
    options: clients?.ids.map((clientId) => ({
      id: clientId,
      label: clients?.entities[clientId]?.name,
    })),
  });
  const unitDropdown = useDropdownSelector({
    options: [
      { id: "units", label: lang("units") },
      { id: "kits", label: lang("kits") },
    ],
  });
  const amountInput = useInput();

  const [isOpen, setIsOpen] = useState(false);

  const resetInputs = () => {
    nameInput.reset();
    detailsInput.reset();
    dateStartInput.reset();
    dateFinishInput.reset();
    if (clientStatus === "fulfilled") {
      clientDropdown.reset(
        clients?.ids.map((clientId) => ({
          id: clientId,
          label: clients?.entities[clientId]?.name,
        }))
      );
    }
    if (organizationsStatus === "fulfilled") {
      organizationDropdown.reset(organizationOptions);
    }
    quantityInput.reset();
    unitDropdown.reset();
    billingDropdown.reset();
    scopeInput.reset();
    hoursInput.reset();
    amountInput.reset();
  };

  useEffect(() => {
    if (clientStatus === "fulfilled") {
      clientDropdown.reset(
        clients?.ids.map((clientId) => ({
          id: clientId,
          label: clients?.entities[clientId]?.name,
        }))
      );
    }
    // eslint-disable-next-line
  }, [clientStatus]);

  useEffect(() => {
    if (organizationsStatus === "fulfilled") {
      organizationDropdown.reset(organizationOptions);
    }
    // eslint-disable-next-line
  }, [organizationsStatus]);

  const onOpen = () => {
    setIsOpen(true);
  };
  const onSubmit = () => {
    let body = {
      name: nameInput?.value,
      organisation_id: organizationDropdown?.value?.id,
      customer_id: clientDropdown?.value?.id,
      billing: billingDropdown?.value?.id,
    };
    if (scopeInput?.value != null) body.planned_scope = scopeInput?.value || 0;
    if (hoursInput?.value != null) body.planned_hourly = hoursInput?.value || 0;
    if (unitDropdown?.value != null) body.uom = unitDropdown?.value?.id;
    if (quantityInput?.value != null) body.qty = quantityInput?.value;
    if (amountInput?.value != null) body.total_amount = amountInput?.value;
    handleAddOffer(null, body);
  };
  const onCancel = () => {
    setIsOpen(false);
    resetInputs();
  };
  const onClose = (keepValues) => {
    setIsOpen(false);
    if (!keepValues) resetInputs();
  };

  const showScopeInput =
    billingDropdown?.value?.id === "SCOPE" ||
    billingDropdown?.value?.id === "MIXED";

  const showHoursInput =
    billingDropdown?.value?.id === "HOURLY" ||
    billingDropdown?.value?.id === "MIXED";

  let submitEnabled =
    nameInput?.value != null &&
    organizationDropdown?.value?.id != null &&
    clientDropdown?.value?.id != null &&
    billingDropdown?.value?.id != null;

  if (showScopeInput && scopeInput?.value == null) submitEnabled = false;
  if (showHoursInput && hoursInput?.value == null) submitEnabled = false;

  return {
    isOpen,
    onOpen,
    onSubmit,
    onCancel,
    onClose,
    nameInput,
    scopeInput,
    hoursInput,
    organizationDropdown,
    billingDropdown,
    detailsInput,
    dateStartInput,
    dateFinishInput,
    clientDropdown,
    quantityInput,
    unitDropdown,
    submitEnabled,
    showScopeInput,
    showHoursInput,
    amountInput,
  };
};

export default useOfferCreator;
