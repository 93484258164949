import classNames from "classnames";

import styles from "./form.module.css";

const Form = ({ children, modal, noGap, narrow, wide, ...rest }) => {
  return (
    <form
      className={classNames(styles.form, {
        [styles.modal]: modal,
        [styles.noGap]: noGap,
        [styles.narrow]: narrow,
        [styles.wide]: wide,
      })}
      {...rest}
    >
      {children}
    </form>
  );
};

export default Form;
