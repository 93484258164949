import { useState, useRef, useEffect } from "react";
import useTimelogs from "./useTimelogs";
import useUnverifiedCount from "./useUnverifiedCount";
import useLang from "../../../hooks/useLang";
import Loader from "../../../components/Loader";
import useVerifyActivity from "./useVerifyActivity";
import useActivityCreator from "./useActivityCreator";
import useSorting from "../../../hooks/useSorting";
import { useCopyTable } from "../../../hooks/useCopyTable";
import useEmployees from "../../employees/hooks/useEmployees";
import useInputFilter from "../../../hooks/useInputFilter";
import useDropdownFilter from "../../../hooks/useDropdownFilter";
import useModifierModal from "../../../hooks/useModifierModal";
import useTimelog from "./useTimelog";
import useDropdownSelector from "../../../hooks/useDropdownSelector";
import usePatchActivity from "./usePatchActivity";
import usePolicyChecker from "../../auth/usePolicyChecker";
import { userActions } from "../../../config/accessConfig";
import Icon from "../../../components/Icon";

const useTimeLogsPage = () => {
  const lang = useLang();
  const timelogs = useTimelogs();
  const [verifyAcvity] = useVerifyActivity();
  const [selectedId, setSelectedId] = useState();
  const [loadingIds, setLoadingIds] = useState([]);
  const activityCreatorAttribs = useActivityCreator({ patching: true });
  const tableRef = useRef();
  const [copy] = useCopyTable(tableRef);
  const { data: unverifiedCount } = useUnverifiedCount();
  const employees = useEmployees();
  const canPatchActivity = usePolicyChecker({
    action: userActions.PATCH_ACTIVITY,
  });

  const billingDropdown = useDropdownSelector({
    options: [],
    defaultValue: { id: null },
  });
  const billingModifierAttribs = useModifierModal({
    inputs: [{ input: billingDropdown, key: "billing" }],
    query: useTimelog,
    mutation: usePatchActivity,
  });

  let employeeOptions = Array.isArray(employees?.data?.ids)
    ? employees?.data?.ids?.map((employeeId) => ({
        id: employeeId,
        label: `${employees?.data?.entities[employeeId]?.given_name} ${employees?.data?.entities[employeeId]?.family_name}`,
      }))
    : [];

  const handleVerification = (projectId) => {
    setLoadingIds((state) => [...state, projectId]);
    verifyAcvity({ id: projectId }, {});
  };

  const handleRowClick = (id, data) => {
    if (!data?.editable) return false;
    setSelectedId(id);
    activityCreatorAttribs.onOpen(id);
  };

  const tabs = [
    { param: "verified", to: null, label: lang("all"), key: "ALL" },
    { param: "verified", to: "true", label: lang("verified"), key: "VERIFIED" },
    {
      param: "verified",
      to: "false",
      label: lang("unverified"),
      key: "UNVERIFIED",
      count: unverifiedCount,
    },
  ];

  useEffect(() => {
    if (timelogs.status === "fulfilled") {
      setLoadingIds((state) => {
        const newState = state?.filter(
          (loadingId) => timelogs?.data?.entities[loadingId]?.verified === false
        );
        return newState;
      });
    }
    // eslint-disable-next-line
  }, [timelogs.status]);

  const { input: fromInput } = useInputFilter("from");
  const { input: toInput } = useInputFilter("to");
  const { dropdown: workerDropdown } = useDropdownFilter(
    "user_id",
    employeeOptions
  );

  const dateParams = useSorting("date");
  const startParams = useSorting("start");
  const finishParams = useSorting("finish");

  const tableRows = timelogs?.data?.ids?.map((id) => ({
    ...timelogs?.data?.entities[id],
    start: timelogs?.data?.entities[id]?.start?.split(" ")[0],
    finish: timelogs?.data?.entities[id]?.finish?.split(" ")[0],
    project_name: `${timelogs?.data?.entities[id]?.project?.full_no} / ${timelogs?.data?.entities[id]?.project?.name}`,
    duration:
      timelogs?.data?.entities[id]?.duration != null
        ? Math.round(parseFloat(timelogs?.data?.entities[id]?.duration) * 100) /
          100
        : "-",
    id,
  }));
  const tableConfig = [
    {
      id: "verified",
      width: 10,
      header: { label: lang("vrf") },
      entityKey: "verified",
      onClick: handleVerification,
      onClickCondition: (value, id, data) =>
        !value && data?.editable && canPatchActivity && data?.finish != null,
      component: (value, id, data) => {
        if (loadingIds.indexOf(id, data) > -1) {
          return <Loader xs />;
        }
        return value ? (
          <>
            <Icon boxChecked color={data?.editable ? "green" : "grey"} />
            <p style={{ display: "none" }}>Yes</p>
          </>
        ) : (
          <>
            <Icon box />
            <p style={{ display: "none" }}>No</p>
          </>
        );
      },
    },
    {
      id: "date",
      width: 24,
      header: dateParams,
      entityKey: "date",
      sort: true,
    },
    {
      id: "name",
      width: 52,
      header: { label: lang("name") },
      entityKey: "worker",
    },
    {
      id: "organization",
      width: 36,
      header: { label: lang("organization") },
      entityKey: "organisation.name",
    },
    {
      id: "start",
      width: 18,
      header: startParams, // { label: lang("start") },
      entityKey: "start",
      sort: true,
    },
    {
      id: "finish",
      width: 18,
      header: finishParams,
      entityKey: "finish",
      sort: true,
    },
    {
      id: "duration",
      width: 16,
      header: { label: lang("dur") },
      entityKey: "duration",
    },
    {
      id: "project_name",
      width: 56,
      header: { label: lang("project") },
      entityKey: "project_name",
    },
    {
      id: "job_type",
      width: 20,
      header: { label: lang("job_type") },
      entityKey: "job_type",
    },
    {
      id: "billing",
      width: 20,
      header: { label: lang("billing") },
      entityKey: "billing",
      onClick: (id, data) => {
        const billingOptions = data?.alternative_billing.map((billing) => ({
          id: billing,
          label: billing,
        }));
        billingDropdown.reset(billingOptions, data?.billing);
        billingModifierAttribs.onOpen(id, data);
      },
      onClickCondition: (value, id, data) =>
        data?.editable &&
        Array.isArray(data?.alternative_billing) &&
        data?.alternative_billing.length > 1 &&
        canPatchActivity,
    },
  ];

  return {
    isLoading: timelogs?.isLoading,
    tableAttribs: {
      config: tableConfig,
      data: tableRows || [],
      onRowClick: handleRowClick,
      onRowClickCondition: (id, data) => data?.editable && canPatchActivity,
      ref: tableRef,
    },
    activityCreatorAttribs: {
      ...activityCreatorAttribs,
      onSubmit: () => {
        setLoadingIds((state) => [...state, selectedId]);
        setSelectedId();
        activityCreatorAttribs.onSubmit();
      },
      title: lang("edit_activity"),
      submitLabel: lang("submit"),
    },
    totalPages: timelogs?.data?.pagination?.totalPages,
    recordCount: timelogs?.data?.pagination,
    tabs,
    copy,
    fromInput,
    toInput,
    workerDropdown,
    billingDropdown,
    billingModifierAttribs,
  };
};

export default useTimeLogsPage;
