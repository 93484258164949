import { useRef, useEffect } from "react";
import useFilterParam from "./useFilterParam";
import useDropdownSelector from "./useDropdownSelector";

const useDropdownFilter = (paramName, options) => {
  const optionsLoaded = useRef(false);
  const paramsRead = useRef(false);

  const dropdown = useDropdownSelector({
    defaultValue: { id: null, label: "-" },
    options,
  });
  const filter = useFilterParam(paramName);

  useEffect(() => {
    if (options?.length > 0) {
      optionsLoaded.current = true;
      dropdown.reset(options, filter?.value);
    }
    // eslint-disable-next-line
  }, [options?.length]);

  useEffect(() => {
    if (
      optionsLoaded.current &&
      paramsRead.current &&
      dropdown?.value?.id !== undefined &&
      dropdown?.value?.id !== "undefined" &&
      dropdown?.value?.id !== filter?.value
    ) {
      filter.setFilterValue(dropdown?.value?.id);
    }
    // eslint-disable-next-line
  }, [dropdown?.value?.id]);

  useEffect(() => {
    paramsRead.current = true;
    if (filter?.value == null) {
      dropdown.reset(options, null);
    } else if (
      filter?.value !== "undefined" &&
      dropdown?.value?.id !== filter?.value
    ) {
      dropdown.reset(options, filter?.value);
    }
    // eslint-disable-next-line
  }, [filter?.value]);

  return { dropdown };
};

export default useDropdownFilter;
