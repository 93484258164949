import useLang from "../../hooks/useLang";
import { PageHeader } from "../../components/Header";
import useTimesheetsPage from "./hooks/useTimesheetsPage";
import DropdownSelector from "../../components/DropdownSelector";
import {
  BundleGroup,
  SplitGroup,
  PageSubheaderGroup,
} from "../../components/Group";
import DynamicTable from "../../components/DynamicTable";
import Label from "../../components/Label";
import Button from "../../components/Button";
import Loader from "../../components/Loader";

const TimesheetsPage = () => {
  const lang = useLang();
  const {
    copyYear,
    copyMonth,
    yearSelector,
    monthSelector,
    monthlyTableAttribs,
    dailyTableAttribs,
    showMonthTable,
    showDailyTable,
    isLoading,
  } = useTimesheetsPage();
  return (
    <>
      <PageHeader>
        <h1>{lang("timesheets")}</h1>
      </PageHeader>
      <PageSubheaderGroup>
        <BundleGroup width={8}>
          <Label>{lang("year")}</Label>
        </BundleGroup>
        <BundleGroup width={24}>
          <DropdownSelector noEmpty {...yearSelector} />
        </BundleGroup>
        <BundleGroup width={10}>
          <Label>{lang("month")}</Label>
        </BundleGroup>
        <BundleGroup width={20}>
          <DropdownSelector {...monthSelector} />
        </BundleGroup>
        <SplitGroup />
        {showMonthTable ? (
          <Button emphasized onClick={copyYear}>
            {lang("copy")}
          </Button>
        ) : showDailyTable ? (
          <Button emphasized onClick={copyMonth}>
            {lang("copy")}
          </Button>
        ) : null}
      </PageSubheaderGroup>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div style={{ display: `${showMonthTable ? "initial" : "none"}` }}>
            <DynamicTable {...monthlyTableAttribs} />
          </div>
          <div style={{ display: `${showDailyTable ? "initial" : "none"}` }}>
            <DynamicTable {...dailyTableAttribs} />
          </div>
        </>
      )}
    </>
  );
};

export default TimesheetsPage;
