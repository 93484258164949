import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/apiSlice";
import { apiSlice as apiAuthenticatedSlice } from "../features/api/apiAuthenticatedSlice";
import authReducer from "../features/auth/authSlice";
import alertsReducer from "../features/alerts/alertsSlice";

import employeeMockReducer from "../features/employees/employeeMockSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [apiAuthenticatedSlice.reducerPath]: apiAuthenticatedSlice.reducer,
    auth: authReducer,
    alerts: alertsReducer,
    employeeMock: employeeMockReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(apiSlice.middleware)
      .concat(apiAuthenticatedSlice.middleware),
  devTools: process.env.NODE_ENV === "development",
});
