import { useLocation } from "react-router-dom";
import useSearchParams from "./useSearchParams";

const useFetchQuery = (queryParams, pathname, props) => {
  const location = useLocation();
  const noParams = location.pathname !== pathname;

  const { getParams } = useSearchParams();

  const paramKeys = queryParams ? Object.keys(queryParams) : [];
  const paramArray = paramKeys.flatMap((paramKey) => {
    if (props != null && props[paramKey] != null) {
      return `${paramKey}=${props[paramKey]}`;
    } else if (props != null) {
      return null;
    }
    let params = getParams(paramKey);
    if (params == null || (Array.isArray(params) && params.length < 1)) {
      if (queryParams[paramKey] == null) return null;
      params = queryParams[paramKey];
    }
    if (Array.isArray(params)) {
      return params.flatMap((param) => {
        if (Array.isArray(param.split(","))) {
          return param
            .split(",")
            .map((paramItem) =>
              paramItem !== "null" && paramItem !== "all"
                ? `${paramKey}=${paramItem}`
                : null
            );
        } else {
          return param !== "null" && param !== "all"
            ? `${paramKey}=${param}`
            : null;
        }
      });
    }
    return `${paramKey}=${params}`;
  });
  const paramString = !noParams
    ? paramArray.filter((param) => param != null).join("&")
    : props != null
    ? Object.keys(props)
        ?.map((propKey) => `${propKey}=${props[propKey]}`)
        .join("&")
    : null;

  return paramString;
};

export default useFetchQuery;
