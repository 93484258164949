import { NavLink } from "react-router-dom";

import style from "./navigation.module.css";

const HomeLink = ({ expanded }) => {
  return (
    <>
      <NavLink to="/" className={style.homeLink}>
        {expanded ? (
          <img src="/osmium_logotype.png" alt="Osmium logotype" height={40} />
        ) : (
          <img src="/osmium_logo.png" alt="Osmium logo" height={40} />
        )}
      </NavLink>
    </>
  );
};

export default HomeLink;
