import useAlerts from "../features/alerts/useAlerts";

const useMutationCall = (mutationHook) => {
  const { onError } = useAlerts();
  const [mutation, mutationMeta] = mutationHook();

  const handleSuccess = (data, successCallback) => {
    if (typeof successCallback === "function") successCallback(data);
  };

  const handleError = (err, errorCallback) => {
    if (typeof errorCallback === "function") errorCallback(err);
    else onError(err);
  };

  const mutationHandler = async (
    params,
    body,
    successCallback,
    errorCallback
  ) => {
    try {
      const response = await mutation({ params, body });
      if (response?.error) {
        handleError(response?.error, errorCallback);
      } else {
        handleSuccess({ params, body, response }, successCallback);
      }
    } catch (err) {
      handleError(err, errorCallback);
    }
  };

  return [mutationHandler, mutationMeta];
};

export default useMutationCall;
