const HR = () => {
  return (
    <hr
      style={{
        border: "0",
        borderBottom: "1px solid var(--clr-neutral-300)",
        width: "100%",
      }}
    />
  );
};

export default HR;
