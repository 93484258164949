import useLang from "../../hooks/useLang";
import { PageHeader } from "../../components/Header";
import Loader from "../../components/Loader";
import { BundleGroup, SplitGroup } from "../../components/Group";
import DynamicTable from "../../components/DynamicTable";
import useTimeLogsPage from "./hooks/useTimeLogsPage";
import ActivityCreator, { ActivityCreatorControlled } from "./ActivityCreator";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Paging from "../../components/Paging";
import { ParamLinkTabs } from "../../components/Tabs";
import RecordCounter from "../../components/RecordCounter";
import Label from "../../components/Label";
import DropdownSelector from "../../components/DropdownSelector";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import BillingModifier from "../projects/BillingModifier";

const TimeLogsPage = () => {
  const lang = useLang();
  const {
    isLoading,
    tableAttribs,
    activityCreatorAttribs,
    totalPages,
    tabs,
    copy,
    recordCount,
    fromInput,
    toInput,
    workerDropdown,
    billingDropdown,
    billingModifierAttribs,
  } = useTimeLogsPage();

  return (
    <>
      <PageHeader>
        <h1>{lang("timelogs")}</h1>
      </PageHeader>
      <BundleGroup>
        <BundleGroup>
          <Label>{lang("from")}</Label>
        </BundleGroup>
        <BundleGroup width={36}>
          <Input type="date" {...fromInput.attributes} />
        </BundleGroup>
        <BundleGroup width={4} />
        <BundleGroup>
          <Label>{lang("to")}</Label>
        </BundleGroup>
        <BundleGroup width={36}>
          <Input type="date" {...toInput.attributes} />
        </BundleGroup>
        <BundleGroup width={4} />
        <AccessWrapper action={userActions.GET_EMPLOYEES}>
          <>
            {" "}
            <BundleGroup>
              <Label>{lang("employee")}</Label>
            </BundleGroup>
            <BundleGroup width={48}>
              <DropdownSelector {...workerDropdown} />
            </BundleGroup>
          </>
        </AccessWrapper>
      </BundleGroup>
      <div style={{ height: "var(--spacing-4)" }} />
      <ParamLinkTabs tabs={tabs}>
        <>
          <SplitGroup />
          <Button emphasized onClick={copy}>
            {lang("copy")}
          </Button>
        </>
      </ParamLinkTabs>
      <Paging totalPages={totalPages} withPageSize>
        <ActivityCreator
          loader={
            <Button important loader>
              {lang("add_activity")}
            </Button>
          }
          renderOpen={(onClick) => (
            <Button important onClick={onClick}>
              {lang("add_activity")}
            </Button>
          )}
        />
      </Paging>
      <RecordCounter recordCount={recordCount} />
      {isLoading ? <Loader /> : <DynamicTable {...tableAttribs} />}
      <ActivityCreatorControlled {...activityCreatorAttribs} />
      <Paging totalPages={totalPages} />
      <BillingModifier
        {...{
          billingModifierAttribs,
          billingDropdown,
        }}
      />
    </>
  );
};

export default TimeLogsPage;
