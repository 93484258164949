import { apiSlice } from "../api/apiAuthenticatedSlice";

export const timesheetsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTimesheets: builder.query({
      query: (arg) => `/timesheets/${arg?.year}`,
      providesTags: (result, error, arg) => [
        { type: "Timesheet", id: arg?.year },
      ],
    }),
  }),
});

export const { useGetTimesheetsQuery } = timesheetsSlice;
