import useOffers from "./useOffers";

const useOffer = (offerId) => {
  const offers = useOffers();

  let dataFromOffers;
  if (offerId && offers?.data?.entities[offerId]) {
    dataFromOffers = offers?.data?.entities[offerId];
  }

  return { ...offers, data: dataFromOffers };
};

export default useOffer;
