import { useEffect } from "react";
import useSearchParams from "./useSearchParams";
import paramConfig from "../config/paramConfig";
import useInput from "./useInput";
import { useLocation } from "react-router-dom";

const usePaging = (totalPages) => {
  const location = useLocation();
  const { getParam, addParam, removeParam } = useSearchParams();

  let pageNumbers = [];
  if (totalPages != null) {
    let i;
    for (i = 0; i < totalPages; i++) {
      pageNumbers.push(i + 1);
    }
  }
  const activePage = getParam(paramConfig.PAGE_NUMBER)
    ? getParam(paramConfig.PAGE_NUMBER)
    : paramConfig.DEFAULT_PAGE_NUMBER;

  const getPageSice = () => {
    return getParam(paramConfig.PAGE_SIZE)
      ? getParam(paramConfig.PAGE_SIZE)
      : paramConfig.DEFAULT_PAGE_SIZE;
  };

  const pageSizeInput = useInput(getPageSice());

  useEffect(() => {
    pageSizeInput.setValue(getPageSice());
    // eslint-disable-next-line
  }, [location?.key]);

  const LENGTH = 3;
  let firstPages = pageNumbers.slice(0, LENGTH);
  let activePages = [];
  let startAt = Math.max(
    activePage - 1 * Math.ceil(LENGTH / 2),
    firstPages.length
  );
  if (
    totalPages >= LENGTH * 2 &&
    activePage > totalPages - 1 * Math.floor(LENGTH / 2)
  ) {
    startAt = totalPages - LENGTH;
  }
  if (firstPages.length < pageNumbers.length) {
    activePages = pageNumbers.slice(startAt, startAt + LENGTH);
  }
  let lastPages = pageNumbers.slice(
    Math.max(startAt + LENGTH, pageNumbers.length - LENGTH)
  );

  let firstSpace = false;
  let lastSpace = false;
  if (firstPages[LENGTH - 1] < activePages[0] - 1) {
    firstSpace = true;
  }
  if (activePages[[LENGTH - 1]] < lastPages[0] - 1) {
    lastSpace = true;
  }

  const handlePageClick = (pageNumber) => {
    addParam(paramConfig.PAGE_NUMBER, pageNumber);
  };

  const handlePageSizeClick = () => {
    addParam(paramConfig.PAGE_SIZE, pageSizeInput?.value);
    removeParam(paramConfig.PAGE_NUMBER);
  };

  const onPageSizeKeyDown = (event) => {
    if (event.key === "Enter") handlePageSizeClick();
  };

  return {
    pageNumbers,
    firstPages,
    activePages,
    firstSpace,
    lastSpace,
    lastPages,
    activePage,
    onClick: handlePageClick,
    onPageSizeKeyDown,
    pageSizeInput,
    onPageSizeClick: handlePageSizeClick,
  };
};

export default usePaging;
