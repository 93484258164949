import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { pathnames } from "../config/routes";

import useOffers from "../features/offers/hooks/useOffers";
import useProjects from "../features/projects/hooks/useProjects";
import useProject from "../features/projects/hooks/useProject";
import useEmployee from "../features/employees/hooks/useEmployee";
import useEmployees from "../features/employees/hooks/useEmployees";
import useClients from "../features/clients/hooks/useClients";
import useTimesheets from "../features/timesheets/hooks/useTimesheets";
import useTimelogs from "../features/timelogs/hooks/useTimelogs";
import useSkills from "../features/skills/hooks/useSkills";
import useOrganizations from "../features/organizations/hooks/useOrganizations";
import useMyActivity from "../features/checkIn/hooks/useMyActivity";
import useMyProfile from "../features/auth/useMyProfile";
import useUnverifiedCount from "../features/timelogs/hooks/useUnverifiedCount";

const useRefresher = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const location = useLocation();
  const [userActive, setUserActive] = useState(true);
  const [refreshCounter, setRefreshCounter] = useState(3);
  const refreshTimerRef = useRef();

  const { projectId, employeeId } = useParams();
  useSkills();
  const { refetch: refetchUnverifiedCount } = useUnverifiedCount();
  const { refetch: refetchOrganizations } = useOrganizations();
  const { refetch: refetchOffers } = useOffers();
  const { refetch: refetchProjects } = useProjects();
  const { refetch: refetchProject } = useProject(projectId);
  const { refetch: refetchEmployees } = useEmployees();
  const { refetch: refetchEmployee } = useEmployee(employeeId);
  const { refetch: refetchClients } = useClients();
  const { refetch: refetchTimesheets } = useTimesheets(currentYear);
  const { refetch: refetchTimelogs } = useTimelogs();
  const { refetch: refetchMyActivity } = useMyActivity();
  const { refetch: refetchMyProfile } = useMyProfile();

  const invalidateData = () => {
    switch (location.pathname) {
      case pathnames.OFFERS: {
        refetchOffers();
        break;
      }
      case pathnames.PROJECTS: {
        refetchProjects();
        break;
      }
      case pathnames.EMPLOYEES: {
        refetchEmployees();
        break;
      }
      case pathnames.CLIENTS: {
        refetchClients();
        break;
      }
      case pathnames.ORGANIZATIONS: {
        refetchOrganizations();
        break;
      }
      case pathnames.TIMESHEETS: {
        refetchTimesheets();
        break;
      }
      case pathnames.TIMELOGS: {
        refetchTimelogs();
        refetchUnverifiedCount();
        break;
      }
      case pathnames.MY_ACTIVITY: {
        refetchMyActivity();
        refetchMyProfile();
        break;
      }
      default:
        if (projectId) {
          refetchProject();
        }
        if (employeeId) {
          refetchEmployee();
        }
        break;
    }
  };

  let inactivityTimer;
  const handleMouseMove = () => {
    setUserActive((state) => state || true);
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(() => {
      setUserActive((state) => (state ? false : state));
    }, 10000);
  };

  const handleWindowBlur = () => {
    setRefreshCounter((state) => Math.max(state - 1, 0));
  };

  const handleWindowFocus = () => {
    setRefreshCounter((state) => (state !== 3 ? 3 : state));
  };

  useEffect(() => {
    clearTimeout(refreshTimerRef.current);
    if (refreshCounter > 0) {
      invalidateData();
      refreshTimerRef.current = setTimeout(() => {
        setRefreshCounter((state) => Math.max(state - 1, 0));
      }, 10000);
    }
    // eslint-disable-next-line
  }, [refreshCounter]);

  useEffect(() => {
    if (userActive) {
      setRefreshCounter((state) => (state !== 3 ? 3 : state));
    } else {
      setRefreshCounter((state) => (state !== 0 ? 0 : state));
    }
    // eslint-disable-next-line
  }, [userActive]);

  useEffect(() => {
    invalidateData();
    setRefreshCounter((state) => (state !== 3 ? 3 : state));
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("blur", handleWindowBlur);
    window.addEventListener("focus", handleWindowFocus);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("blur", handleWindowBlur);
      window.removeEventListener("focus", handleWindowFocus);
      window.removeEventListener("mousemove", handleMouseMove);
    };
    // eslint-disable-next-line
  }, []);

  return false;
};

export default useRefresher;
