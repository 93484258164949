import useLang from "../../hooks/useLang";
import { FormModalControlled } from "../../components/Modal";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import useEmployeeCreator from "./hooks/useEmployeeCreator";
import Form from "../../components/Form";
import Input from "../../components/Input";
import ChipSelector from "../../components/ChipSelector";
import DropdownSelector from "../../components/DropdownSelector";
import HR from "../../components/HR";

const EmployeeCreatorControlled = ({
  isOpen,
  onSubmit,
  onCancel,
  onClose,
  firstNameInput,
  lastNameInput,
  emailInput,
  organizationDropdown,
  skillDropdown,
  editDialog,
  rolesSelector,
  formEnabled,
  showOrganizationDropdown,
  isEditing,
}) => {
  const lang = useLang();
  return (
    <>
      <AccessWrapper action={userActions.CREATE_CLIENT}>
        <FormModalControlled
          isOpen={isOpen}
          title={editDialog ? lang("employee") : lang("new_employee")}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onClose={onClose}
          submitLabel={
            editDialog ? lang("edit_employee") : lang("add_employee")
          }
          disabled={!formEnabled}
        >
          <Form modal wide>
            <Input
              label={lang("first_name")}
              type="text"
              required
              disabled={isEditing}
              {...firstNameInput.attributes}
            />
            <Input
              label={lang("last_name")}
              type="text"
              required
              disabled={isEditing}
              {...lastNameInput.attributes}
            />
            <Input
              label={lang("primary_email")}
              type="email"
              required
              disabled={isEditing}
              {...emailInput.attributes}
            />
            {showOrganizationDropdown ? (
              <DropdownSelector
                label={lang("company")}
                {...organizationDropdown}
              />
            ) : null}
            {/* <DropdownSelector label={lang("skill")} {...skillDropdown} /> */}
            <ChipSelector label={lang("skill")} {...skillDropdown} />
            <HR />
            <ChipSelector label={lang("roles")} {...rolesSelector} />
          </Form>
        </FormModalControlled>
      </AccessWrapper>
    </>
  );
};

const EmployeeCreator = ({ renderOpen, formData }) => {
  const employeeCreatorProps = useEmployeeCreator({ formData });
  return (
    <>
      <AccessWrapper action={userActions.CREATE_CLIENT}>
        {renderOpen(() => {
          employeeCreatorProps.onOpen();
        })}
      </AccessWrapper>
      <EmployeeCreatorControlled
        editDialog={formData != null}
        {...employeeCreatorProps}
      />
    </>
  );
};

export { EmployeeCreatorControlled };
export default EmployeeCreator;
