import useTimelogs from "./useTimelogs";

const useTimelog = (activityId) => {
  const timelogs = useTimelogs();

  let dataFromTimelogs;
  if (activityId && timelogs?.data?.entities[activityId]) {
    dataFromTimelogs = timelogs?.data?.entities[activityId];
  }

  return { ...timelogs, data: dataFromTimelogs };
};

export default useTimelog;
