import { useState } from "react";

const useDropdownSelector = (props = {}) => {
  const { defaultValue, options } = props;
  const initialValue =
    defaultValue != null ? defaultValue : options ? options[0] : null;

  const [availableOptions, setAvailableOptions] = useState(options);
  const [selected, _setSelected] = useState(initialValue);
  const setSelected = (value) => {
    if (typeof value !== "object") {
      _setSelected({ id: String(value) });
    } else {
      _setSelected(value);
    }
  };

  const reset = (options, value) => {
    if (options != null) setAvailableOptions(options);
    let selectedValue;
    if (value) {
      selectedValue = options?.find((option) => option.id === value);
    }
    setSelected(selectedValue != null ? selectedValue : initialValue);
  };

  return {
    value: selected,
    setValue: setSelected,
    initialValue,
    options: availableOptions,
    onChange: (newValue) => {
      setSelected(newValue);
    },
    reset,
  };
};

export default useDropdownSelector;
