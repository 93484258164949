import { FormModalControlled } from "../../components/Modal";
import useLang from "../../hooks/useLang";
import Form from "../../components/Form";
import Input from "../../components/Input";
// import { BundleGroup } from "../../components/Group";

const StatusModifier = ({
  isOpen,
  onClose,
  onCancel,
  onSubmit,
  statusInput,
}) => {
  const lang = useLang();
  return (
    <>
      <FormModalControlled
        title={lang("modify_employee_status")}
        isOpen={isOpen}
        onCancel={onCancel}
        onSubmit={onSubmit}
        onClose={onClose}
      >
        <Form modal>
          <Input
            type="checkbox"
            label={lang("active")}
            {...statusInput.attributes}
          />
        </Form>
      </FormModalControlled>
    </>
  );
};

export default StatusModifier;
