import usePolicyChecker from "./usePolicyChecker";

const AccessWrapper = ({ children, action }) => {
  const checkPassed = usePolicyChecker({
    action,
  });
  if (!checkPassed) return false;
  return <>{children}</>;
};

export default AccessWrapper;
