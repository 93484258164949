import { PageHeader } from "../../components/Header";
import { SplitGroup } from "../../components/Group";
import Button from "../../components/Button";
import DynamicTable from "../../components/DynamicTable";
import OfferCreatorMultimodal from "./OfferCreatorMultimodal";
import { StatusChangerControlled } from "./StatusChanger";
import useLang from "../../hooks/useLang";
import useOffersList from "./hooks/useOffersList";
import Paging from "../../components/Paging";
import ModifierModal from "../../components/ModifierModal";
import Input from "../../components/Input";
import { ParamLinkTabs } from "../../components/Tabs";
import { BundleGroup } from "../../components/Group";
import RecordCounter from "../../components/RecordCounter";
import Label from "../../components/Label";
import DropdownSelector from "../../components/DropdownSelector";
import BillingModifier from "../projects/BillingModifier";

const Offers = () => {
  const lang = useLang();
  const {
    tableAttribs,
    statusChangerAttribs,
    totalPages,
    qtyInput,
    qtyModifierAttribs,
    amountInput,
    amountModifierAttribs,
    scopeInput,
    scopeModifierAttribs,
    hourlyInput,
    hourlyModifierAttribs,
    billingModifierAttribs,
    billingDropdown,
    tabs,
    recordCount,
    organizationDropdown,
    clientDropdown,
    copy,
  } = useOffersList();

  return (
    <>
      <PageHeader>
        <h1>{lang("offers")}</h1>
      </PageHeader>
      <BundleGroup>
        <BundleGroup>
          <Label>{lang("client")}</Label>
        </BundleGroup>
        <BundleGroup width={48}>
          <DropdownSelector {...clientDropdown} />
        </BundleGroup>
        <BundleGroup width={4} />
        <BundleGroup>
          <Label>{lang("organisation")}</Label>
        </BundleGroup>
        <BundleGroup width={48}>
          <DropdownSelector {...organizationDropdown} />
        </BundleGroup>
      </BundleGroup>
      <div style={{ height: "var(--spacing-4)" }} />
      <ParamLinkTabs tabs={tabs}>
        <>
          <SplitGroup />
          <Button emphasized onClick={copy}>
            {lang("copy")}
          </Button>
        </>
      </ParamLinkTabs>
      <Paging totalPages={totalPages} withPageSize>
        <OfferCreatorMultimodal
          loader={
            <Button important loader>
              {lang("add_offer")}
            </Button>
          }
          renderOpen={(onClick) => (
            <Button important onClick={onClick}>
              {lang("add_offer")}
            </Button>
          )}
        />
      </Paging>
      <RecordCounter recordCount={recordCount} />
      <DynamicTable {...tableAttribs} />
      <Paging totalPages={totalPages} />
      <StatusChangerControlled {...statusChangerAttribs} />
      <ModifierModal {...qtyModifierAttribs}>
        <BundleGroup>
          <Input
            type="number"
            label={lang("quantity")}
            {...qtyInput?.attributes}
          />
        </BundleGroup>
      </ModifierModal>
      <ModifierModal {...amountModifierAttribs}>
        <BundleGroup>
          <Input
            type="number"
            label={lang("amount")}
            {...amountInput?.attributes}
          />
        </BundleGroup>
      </ModifierModal>

      <ModifierModal {...scopeModifierAttribs}>
        <BundleGroup>
          <Input
            type="number"
            label={lang("scope")}
            {...scopeInput?.attributes}
          />
        </BundleGroup>
      </ModifierModal>
      <ModifierModal {...hourlyModifierAttribs}>
        <BundleGroup>
          <Input
            type="number"
            label={lang("hourly")}
            {...hourlyInput?.attributes}
          />
        </BundleGroup>
      </ModifierModal>
      <BillingModifier
        {...{
          billingModifierAttribs,
          billingDropdown,
          scopeInput,
          hourlyInput,
        }}
      />
    </>
  );
};

export default Offers;
