import useLang from "../../hooks/useLang";
import Input from "../../components/Input";
import ModifierModal from "../../components/ModifierModal";
import { BundleGroup } from "../../components/Group";

const CompletionModifier = ({
  isOpen,
  onClose,
  onCancel,
  onSubmit,
  completionInput,
}) => {
  const lang = useLang();

  return (
    <ModifierModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onCancel}
      onSubmit={onSubmit}
    >
      <BundleGroup>
        <Input
          label={lang("completion")}
          type="number"
          {...completionInput.attributes}
        />
      </BundleGroup>
    </ModifierModal>
  );
};

export default CompletionModifier;
