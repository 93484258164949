import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiAuthenticatedSlice";

const clientsAdapter = createEntityAdapter();
const initialState = clientsAdapter.getInitialState();

export const clientsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query({
      query: () => `/customers`,
      transformResponse: (response) => {
        return clientsAdapter.setAll(initialState, response);
      },
      providesTags: [{ type: "client" }],
    }),
    addClient: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/customers`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "client" }],
    }),
  }),
});

export const { useGetClientsQuery, useAddClientMutation } = clientsSlice;
