import useClients from "./useClients";
import useLang from "../../../hooks/useLang";
import useClientCreator from "./useClientCreator";

const useClientList = () => {
  const clients = useClients();
  const lang = useLang();
  const clientCreatorAttribs = useClientCreator();

  const dataRows = clients?.data?.ids?.map((entityId) => ({
    ...clients?.data?.entities[entityId],
    name: clients?.data?.entities[entityId].name,
    id: entityId,
  }));

  const tableConfig = [
    {
      id: "NAME",
      width: 64,
      header: { label: lang("name") },
      entityKey: "name",
    },
    {
      id: "ABBR",
      width: 28,
      header: { label: lang("abbreviation") },
      entityKey: "abbr",
    },
  ];

  return {
    tableAttribs: {
      config: tableConfig,
      data: dataRows || [],
    },
    clientCreatorAttribs: {
      ...clientCreatorAttribs,
      title: lang("edit_client"),
      submitLabel: lang("submit"),
    },
  };
};

export default useClientList;
