import { useGetMyCurrentActivityQuery } from "../checkInSlice";
import usePolicyChecker from "../../auth/usePolicyChecker";
import { userActions } from "../../../config/accessConfig";

const useMyActivity = () => {
  const grantAccess = usePolicyChecker({
    action: userActions.GET_MY_ACTIVITIES,
  });
  const myCurrentActivity = useGetMyCurrentActivityQuery(null, {
    skip: !grantAccess,
  });
  return {
    ...myCurrentActivity,
    refetch: grantAccess ? myCurrentActivity.refetch : () => {},
  };
};

export default useMyActivity;
