import useSortParam from "./useSortParam";
import Icon from "../components/Icon";
import useLang from "./useLang";

const useSorting = (key, label) => {
  const lang = useLang();
  const { onSort, getSortOrder } = useSortParam();

  const getIcon = (key) => {
    const sortOrder = getSortOrder(key);
    switch (sortOrder) {
      case "asc":
        return <Icon ascending emphasized />;
      case "desc":
        return <Icon descending emphasized />;
      default:
        return <Icon unsorted />;
    }
  };

  const attributes = {
    key: key,
    onClick: () => {
      onSort(key);
    },
    value: label || lang(key),
    label: label || lang(key),
    icon: getIcon(key),
  };

  return attributes;
};

export default useSorting;
