import styles from "./sortButton.module.css";

const SortButton = ({ value, icon, ...rest }) => {
  return (
    <div
      className={styles.sortButton}
      style={{ position: "relative" }}
      {...rest}
    >
      {value}
      <div
        style={{
          position: "absolute",
          right: "0",
          backgroundColor: "var(--clr-neutral-100)",
        }}
      >
        {icon}
      </div>
    </div>
  );
};

export default SortButton;
