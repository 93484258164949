import useLang from "../../hooks/useLang";
import { PageHeader } from "../../components/Header";
import useEmployeePage from "./hooks/useEmployeePage";
import {
  BundleGroup,
  SplitGroup,
  PageSubheaderGroup,
} from "../../components/Group";
import Paragraph, { SecondaryParagraph } from "../../components/Paragraph";
import EmployeeCreator from "./EmployeeCreator";
import Button from "../../components/Button";
import StatusModifier from "./StatusModifier";

const EmployeePage = () => {
  const lang = useLang();
  const { employee, statusDialogAttribs } = useEmployeePage();

  return (
    <>
      <PageHeader>
        <h1>
          {employee
            ? `${employee?.given_name} ${employee?.family_name}`
            : "..."}
        </h1>
      </PageHeader>
      <PageSubheaderGroup>
        <SplitGroup />
        <EmployeeCreator
          formData={employee}
          loader={
            <Button important loader>
              {lang("edit_employee")}
            </Button>
          }
          renderOpen={(onClick) => (
            <Button important onClick={onClick}>
              {lang("edit_employee")}
            </Button>
          )}
        />
      </PageSubheaderGroup>
      <BundleGroup>
        <BundleGroup width={28}>
          <SecondaryParagraph>{lang("organisation")}</SecondaryParagraph>
        </BundleGroup>
        <BundleGroup>
          <Paragraph>{employee?.organisation?.name}</Paragraph>
        </BundleGroup>
      </BundleGroup>

      <BundleGroup>
        <BundleGroup width={28}>
          <SecondaryParagraph>{lang("skills")}</SecondaryParagraph>
        </BundleGroup>
        <BundleGroup>
          <Paragraph>{employee?.skill_names}</Paragraph>
        </BundleGroup>
      </BundleGroup>
      <BundleGroup>
        <BundleGroup width={28}>
          <SecondaryParagraph>{lang("roles")}</SecondaryParagraph>
        </BundleGroup>
        <BundleGroup>
          <Paragraph>{employee?.roles?.join(", ")}</Paragraph>
        </BundleGroup>
      </BundleGroup>
      {/*
      <BundleGroup>
        <BundleGroup width={28}>
          <SecondaryParagraph>{lang("company")}</SecondaryParagraph>
        </BundleGroup>
        <BundleGroup>
          <Paragraph>{employee?.companyLabel}</Paragraph>
        </BundleGroup>
      </BundleGroup>
      <BundleGroup>
        <BundleGroup width={28}>
          <SecondaryParagraph>{lang("status")}</SecondaryParagraph>
        </BundleGroup>
        <BundleGroup>
          <Paragraph>
            {employee?.status ? lang(employee?.status) : ""}
          </Paragraph>
          <Button inline emphasized icon={"pencil"} onClick={onEditStatus} />
        </BundleGroup>
      </BundleGroup>
          */}
      <StatusModifier {...statusDialogAttribs} />
    </>
  );
};

export default EmployeePage;
