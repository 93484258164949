import { useState, useEffect } from "react";
import useProject from "./useProject";
import useProjectStatusPost from "./useProjectStatusPost";
import useDropdownSelector from "../../../hooks/useDropdownSelector";

//const PENDING = "PENDING";
const ACTIVE = "ACTIVE";
const SUSPENDED = "SUSPENDED";
const FINISHED = "FINISHED";
const WARRANTY = "WARRANTY";

const statusOptions = {
  SUSPENDED: [ACTIVE],
  PENDING: [ACTIVE],
  ACTIVE: [SUSPENDED, FINISHED],
  FINISHED: [WARRANTY],
  WARRANTY: [FINISHED],
};

const useProjectStatusChanger = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [projectId, setProjectId] = useState();
  const [handleStatusPost] = useProjectStatusPost();
  const { data: project, status: projectStatus } = useProject(projectId, {
    skip: projectId == null,
  });
  const statusSelector = useDropdownSelector({ options: [] });

  const resetInputs = () => {
    if (projectStatus === "fulfilled") {
      statusSelector.reset(
        statusOptions[project?.status]?.map((option) => ({
          id: option,
          label: option,
        }))
      );
    } else {
      statusSelector.reset([]);
    }
  };

  useEffect(() => {
    if (projectStatus === "fulfilled") {
      statusSelector.reset(
        statusOptions[project?.status]?.map((option) => ({
          id: option,
          label: option,
        }))
      );
    }
    // eslint-disable-next-line
  }, [projectId]);

  useEffect(() => {
    if (projectStatus === "fulfilled") {
      statusSelector.reset(
        statusOptions[project?.status]?.map((option) => ({
          id: option,
          label: option,
        }))
      );
    }
    // eslint-disable-next-line
  }, [projectStatus]);

  const onOpen = (data) => {
    setProjectId(data);
    setIsOpen(true);
    props?.onOpen();
  };
  const onSubmit = () => {
    handleStatusPost({ id: projectId }, { status: statusSelector?.value?.id });
  };
  const onCancel = () => {
    setIsOpen(false);
    resetInputs();
    props?.onClose();
  };
  const onClose = () => {
    setIsOpen(false);
    props?.onClose();
    resetInputs();
  };

  return {
    isOpen,
    onOpen,
    onSubmit,
    onCancel,
    onClose,
    statusSelector,
  };
};

export default useProjectStatusChanger;
