import useLang from "../../hooks/useLang";
import { FormModalControlled } from "../../components/Modal";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import useInvoiceCreator from "./hooks/useInvoiceCreator";
import Form from "../../components/Form";
import Input from "../../components/Input";

const InvoiceCreatorControlled = ({
  isOpen,
  onSubmit,
  onCancel,
  onClose,
  title,
  submitLabel,
  invoiceInput,
  amountInput,
  enabled,
}) => {
  const lang = useLang();
  return (
    <>
      <AccessWrapper action={userActions.CREATE_INVOICE}>
        <FormModalControlled
          isOpen={isOpen}
          title={title || lang("add_invoice")}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onClose={onClose}
          submitLabel={submitLabel || lang("add")}
          disabled={!enabled}
        >
          <Form modal>
            <Input
              label={lang("invoice_num")}
              type="text"
              {...invoiceInput.attributes}
            />
            <Input
              label={lang("amount")}
              type="number"
              {...amountInput.attributes}
            />
          </Form>
        </FormModalControlled>
      </AccessWrapper>
    </>
  );
};

const InvoiceCreator = ({ renderOpen }) => {
  const invoiceCreatorProps = useInvoiceCreator();
  return (
    <>
      <AccessWrapper action={userActions.CREATE_INVOICE}>
        {renderOpen(() => {
          invoiceCreatorProps.onOpen();
        })}
      </AccessWrapper>
      <InvoiceCreatorControlled {...invoiceCreatorProps} />
    </>
  );
};

export { InvoiceCreatorControlled };
export default InvoiceCreator;
