import { useState, useEffect } from "react";

const useModifierModal = ({
  inputs,
  query = () => {},
  mutation = () => [undefined, {}],
  postMutation = () => [undefined, {}],
}) => {
  const [isOpen, setIsOpen] = useState();
  const [id, setId] = useState();
  const { data, status } = query(id);
  const [handleMutation, { status: mutationStatus }] = mutation();
  const [handlePostMutation] = postMutation();

  const resetInputs = () => {
    if (Array.isArray(inputs)) {
      inputs?.map((input) => {
        if (
          id &&
          data?.id === id &&
          data &&
          !isNaN(parseFloat(data[input?.key]))
        ) {
          if (!input?.input?.value) {
            input?.input.setValue(
              Math.round(parseFloat(data[input?.key]) * 100) / 100
            );
          } else {
          }
        } else {
          input?.input.reset();
        }
        return false;
      });
    }
  };

  useEffect(() => {
    if (status === "fulfilled") {
      if (id != null) {
        resetInputs();
      }
    }
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    if (mutationStatus === "fulfilled") {
      if (id != null) {
        setId();
      }
    }
    // eslint-disable-next-line
  }, [mutationStatus]);

  useEffect(() => {
    resetInputs();
    // eslint-disable-next-line
  }, [id]);

  const onOpen = (id) => {
    setId(id);
    setIsOpen(true);
  };
  const onSubmit = () => {
    const params = { id };
    let body = {};
    if (Array.isArray(inputs)) {
      inputs?.map((input) => {
        if (input.input?.value != null && input.key) {
          body[input.key] =
            typeof input.input?.value !== "object"
              ? input.input?.value
              : input.input?.value?.id;
        }
        return false;
      });
    }
    if (id != null) {
      handleMutation(params, body);
    } else {
      handlePostMutation(null, body);
    }
  };
  const onCancel = () => {
    setIsOpen(false);
    setId();
  };
  const onClose = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    onOpen,
    onSubmit,
    onCancel,
    onClose,
    id,
  };
};

export default useModifierModal;
