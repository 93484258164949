import useLang from "../../hooks/useLang";
import { FormModalControlled } from "../../components/Modal";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import useClientCreator from "./hooks/useClientCreator";
import Form from "../../components/Form";
import Input from "../../components/Input";

const ClientCreatorControlled = ({
  isOpen,
  onSubmit,
  onCancel,
  onClose,
  nameInput,
  abbreviationInput,
  title,
  submitLabel,
}) => {
  const lang = useLang();
  return (
    <>
      <AccessWrapper action={userActions.CREATE_CLIENT}>
        <FormModalControlled
          isOpen={isOpen}
          title={title || lang("new_client")}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onClose={onClose}
          submitLabel={submitLabel || lang("add_client")}
          disabled={!nameInput?.value || !abbreviationInput?.value}
        >
          <Form modal>
            <Input
              label={lang("name")}
              type="text"
              required
              {...nameInput.attributes}
            />
            <Input
              label={lang("abbreviation")}
              type="text"
              required
              {...abbreviationInput.attributes}
            />
          </Form>
        </FormModalControlled>
      </AccessWrapper>
    </>
  );
};

const ClientCreator = ({ renderOpen }) => {
  const clientCreatorProps = useClientCreator();
  return (
    <>
      <AccessWrapper action={userActions.CREATE_CLIENT}>
        {renderOpen(() => {
          clientCreatorProps.onOpen();
        })}
      </AccessWrapper>
      <ClientCreatorControlled {...clientCreatorProps} />
    </>
  );
};

export { ClientCreatorControlled };
export default ClientCreator;
