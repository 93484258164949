import useLang from "../../hooks/useLang";
import { FormModalControlled } from "../../components/Modal";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import useOrganizationCreator from "./hooks/useOrganizationCreator";
import Form from "../../components/Form";
import Input from "../../components/Input";

const OrganizationCreatorControlled = ({
  isOpen,
  onSubmit,
  onCancel,
  onClose,
  nameInput,
  title,
  submitLabel,
  subcontractorInput,
}) => {
  const lang = useLang();
  return (
    <>
      <AccessWrapper action={userActions.CREATE_ORGANIZATION}>
        <FormModalControlled
          isOpen={isOpen}
          title={title || lang("new_organization")}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onClose={onClose}
          submitLabel={submitLabel || lang("add_organization")}
          disabled={!nameInput?.value}
        >
          <Form modal>
            <Input
              label={lang("name")}
              type="text"
              required
              {...nameInput.attributes}
            />
            <Input
              label={lang("subcontractor")}
              type="checkbox"
              {...subcontractorInput.attributes}
            />
          </Form>
        </FormModalControlled>
      </AccessWrapper>
    </>
  );
};

const OrganizationCreator = ({ renderOpen }) => {
  const organizationCreatorProps = useOrganizationCreator();
  return (
    <>
      <AccessWrapper action={userActions.CREATE_ORGANIZATION}>
        {renderOpen(() => {
          organizationCreatorProps.onOpen();
        })}
      </AccessWrapper>
      <OrganizationCreatorControlled {...organizationCreatorProps} />
    </>
  );
};

export { OrganizationCreatorControlled };
export default OrganizationCreator;
