import { useParams } from "react-router-dom";
import useEmployee from "./useEmployee";
import useStatusModifier from "./useStatusModifier";

const useEmployeePage = () => {
  const { employeeId } = useParams();
  const { data } = useEmployee(employeeId);
  const statusDialogAttribs = useStatusModifier();

  const onEditStatus = () => {
    statusDialogAttribs.onOpen(employeeId);
  };

  return { employee: data, statusDialogAttribs, onEditStatus };
};

export default useEmployeePage;
