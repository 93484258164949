import useLang from "../../hooks/useLang";
import { FormModalControlled } from "../../components/Modal";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import { BundleGroup } from "../../components/Group";
import Form from "../../components/Form";
import { ToggleChip } from "../../components/Chip";
import Input from "../../components/Input";
import Label from "../../components/Label";

const StatusChangerControlled = ({
  isOpen,
  onSubmit,
  onCancel,
  onClose,
  title,
  submitLabel,
  acceptOffer,
  declineOffer,
  enabled,
  showDateInputs,
  startInput,
  finishInput,
}) => {
  const lang = useLang();

  return (
    <>
      <AccessWrapper action={userActions.PATCH_OFFER}>
        <FormModalControlled
          isOpen={isOpen}
          title={title || lang("accept_decline_offer")}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onClose={onClose}
          submitLabel={submitLabel || lang("submit")}
          disabled={!enabled}
        >
          <Form modal>
            <BundleGroup>
              <ToggleChip {...acceptOffer}>{lang("accept_offer")}</ToggleChip>
              <ToggleChip {...declineOffer}>{lang("decline_offer")}</ToggleChip>
            </BundleGroup>
            {showDateInputs ? (
              <>
                <BundleGroup>
                  <BundleGroup width={32}>
                    <Label>{lang("project_start_date")}</Label>
                  </BundleGroup>
                  <BundleGroup>
                    <Input type="date" {...startInput.attributes} />
                  </BundleGroup>
                </BundleGroup>
                <BundleGroup>
                  <BundleGroup width={32}>
                    <Label>{lang("project_finish_date")}</Label>
                  </BundleGroup>
                  <BundleGroup>
                    <Input type="date" {...finishInput.attributes} />
                  </BundleGroup>
                </BundleGroup>
              </>
            ) : null}
          </Form>
        </FormModalControlled>
      </AccessWrapper>
    </>
  );
};

export { StatusChangerControlled };
